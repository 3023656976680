import { Box, chakra, Flex, FlexProps } from "@chakra-ui/react";

const KeyValueDisplay = (props: { object: Record<string, string>; flexProps?: FlexProps }) => {
	return (
		<>
			{Object.entries(props.object).map(([key, value]) => (
				<Box key={key} fontWeight={"bold"} color="gray.700" {...props?.flexProps}>
					{key}: <chakra.span color="gray.500">{value}</chakra.span>{" "}
				</Box>
			))}
		</>
	);
};

export default KeyValueDisplay;
