import { Baloo_Bhai_2 } from "next/font/google";

import { css } from "@emotion/react";

export const baloo = Baloo_Bhai_2({ subsets: ["latin"] });

export const CAT_PAGE_SIZE = 5;

export const SUBCAT_PAGE_SIZE = 40;

export const PATH_NOT_FOUND = "Path not found";

export const PAGRX = /([a-z0-9\-\/]+[_$])/;

export const PAGE_SIZE = SUBCAT_PAGE_SIZE;

export const STAGE = process.env.STAGE;

export const urlPaginationEval = (url: string) => {
  const match = url.match(PAGRX);
  return match ? match[1] : url;
};

// This Timezone  and jurisdiction should come from the DB
export const TIMEZONE = "Australia/Brisbane";
export const JURISDICTION = "QLD";

export const COOKIE_INFO_PREFIX = "__info-";

export const INFO_BANNER_CLOSE_BTN_ID = "close-info-banner";

export const INFO_BANNER_CLASS_LOCATION_NAME = "__info_banner";

export const MAIN_MARQUEE_BTN_COLOR_ATTR = "data-close-btn-color";

export const MODE_BANNER_CLASS_NAME = "__mode_banner";

export const NOFOLLOW = !(process.env.NEXT_PUBLIC_STAGE === "production");

export const ERECEIPTS_PRERENDER = process.env.STAGE === "production" ? 50 : 1;

export const NOINDEX = !(process.env.NEXT_PUBLIC_STAGE === "production");

export const PATHMODERX = /^(\/web|\/_p\d+)(\/[a-zA-Z0-9\_\-\/]+)$/;

export const SHOWSEARCHRX =
  /^(\/web|\/_p\d+)\/((category\/[a-z0-9_\-\/]+)|(search(\?term=([a-z\s0-9]+)?)))/;

export const MODE_BANNER_ALLOWED_PATHS = [
  "/[wcode]/work/cat/[cat_name]/[page]",
  "/[wcode]/work/sub_cat/[sub_cat_name]/[page]",
  "/",
  "/[wcode]/work/prod/[id_slug]",
  "/[wcode]/search",
  "/cart",
  "/search-internal",
];

export const LOCALES = {
  en: [
    { label: "🇦🇺 English", value: "en" },
    { label: "🇪🇸 Spanish", value: "es" },
    { label: "🇵🇹 Portuguese", value: "pt" },
  ],
  es: [
    { label: "🇦🇺 Ingles", value: "en" },
    { label: "🇪🇸 Español", value: "es" },
    { label: "🇵🇹 Portugues", value: "pt" },
  ],
  pt: [
    { label: "🇦🇺 Inglês", value: "en" },
    { label: "🇪🇸 Espanhol", value: "es" },
    { label: "🇵🇹 Português", value: "pt" },
  ],
};

// Default weight for packaging added to shipping quotes weight
export const PACKAGING_WEIGHT = 0.3;

export const MODE_BANNER_HEIGHT = 32;

export const BREADCRUMBS_PATHS = [
  "/[wcode]/work/sub_cat/[sub_cat_name]/[page]",
  "/[wcode]/work/prod/[id_slug]",
];

export const pageLoadedGridProps = {
  gridGap: { base: 1.5, sm: 2 },
  pt: 4,
  px: { base: 1, sm: 2 },
  bg: "mainbg",
  w: "full",
  gridTemplateColumns: {
    base: "repeat(1,minmax(0,1fr))",
    xs: "repeat(2,minmax(0,1fr))",
    smm: "repeat(3,minmax(0,1fr))",
    md: "repeat(auto-fill,minmax(200px,1fr))",
  },
  gridAutoRows: {
    base: "calc(9.6rem * 1.33)",
    md: "calc(9.6rem * 1.33 * 1.5)",
  },
};

export const GlobalStyles = css`
  /*
This will hide the focus indicator if the element receives focus    via the mouse,
but it will still show up on keyboard focus.
*/
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

export const CACHE_POLICIES = [
  {
    days: [1, 2, 3, 4, 5],
    hours: [11, 12, 13, 14, 15, 16, 17, 18, 19],
    revalidate: 300,
  },
  {
    days: [1, 2, 3, 4, 5],
    hours: [20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    revalidate: 1800,
  },
  {
    days: [6, 0],
    hours: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ],
    revalidate: 3600,
  },
];

export const STOP_WORDS = [
  "a",
  "is",
  "the",
  "an",
  "and",
  "are",
  "as",
  "at",
  "be",
  "but",
  "by",
  "for",
  "if",
  "in",
  "into",
  "it",
  "no",
  "not",
  "of",
  "on",
  "or",
  "such",
  "that",
  "their",
  "then",
  "there",
  "these",
  "they",
  "this",
  "to",
  "was",
  "will",
  "with",
  "del",
  "de",
  "en",
] as const;
