import { AppRouter } from "@/lib/api/trpc/routers/_app";
import { reactQueryClient } from "@/lib/react-query-client";
import { logger } from "@/utils/helpers";
import { httpBatchLink, httpLink, splitLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";

function getBaseUrl() {
  if (process.env.VERCEL_URL)
    // reference for vercel.com
    return `https://${process.env.VERCEL_URL}`;

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

export const trpc = createTRPCNext<AppRouter>({
  config({ ctx }) {
    if (typeof window !== "undefined") {
      // during client requests
      return {
        links: [
          splitLink({
            condition(op) {
              return (
                (op.context?.skipBatch === undefined
                  ? false
                  : op.context?.skipBatch) === true
              );
            },
            true: httpLink({
              url: "/api/trpc",
            }),

            false: httpBatchLink({
              url: `/api/trpc`,
            }),
          }),
        ],

        queryClient: reactQueryClient,
      };
    }
    // The server needs to know your app's full url

    return {
      links: [
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
          /**
           * Set custom request headers on every request from tRPC
           * @link https://trpc.io/docs/v10/header
           */
          headers() {
            if (ctx?.req) {
              // To use SSR properly, you need to forward the client's headers to the server
              // This is so you can pass through things like cookies when we're server-side rendering
              // If you're using Node 18, omit the "connection" header
              const {
                // connection: _connection,
                ...headers
              } = ctx.req.headers;
              return {
                ...headers,
                // Optional: inform server that it's an SSR request
                "x-ssr": "1",
              };
            }
            return {};
          },
        }),
      ],
    };
  },
  ssr: false,
});

// splitLink({
// 				condition(op) {
// 					return (op.context?.skipBatch === undefined ? false : op.context?.skipBatch) === true;
// 				},
// 				true: httpLink({
// 					url: `${getBaseUrl()}/api/trpc`,
// 				}),

// 				false: httpBatchLink({
// 					url: `${getBaseUrl()}/api/trpc`,
// 				}),
// 			}),

// trpc.useQueries([""])

// trpc.user.
