// import crypto from "crypto";
import { lastDayOfYear, startOfYear } from "date-fns";
import { NextRouter } from "next/router";
import { MutableRefObject, ReactNode } from "react";
import { Sendle } from "sendle-node";
import slugify from "slugify";
import util from "util";

import { Cart } from "@/types/cart-state";
import { EInternalErrors, IPostcodesResponse } from "@/types/shared";

import type { CustomTypeOptions } from "react-i18next";
import type { Stripe } from "stripe";
import { trpcClient } from "@/lib/api/trpc/trpcClient";
const bucket_url = process.env.S3_BUCKET_URL
  ? process.env.S3_BUCKET_URL
  : "https://s3amazon.com";
const bucket_name = process.env.NEXT_PUBLIC_S3_BUCKET_NAME as string;
const resizer_service_url = process.env.NEXT_PUBLIC_IMAGE_RESIZER_URL;

interface CartItemProduct extends Cart.IProduct {
  weight: number;
}

type CartItemWithWeight = {};

/**
 *
 * @param time
 * @returns void
 */
export const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

/**
 * Add or remove element from array if it exists
 * @param {[string|number]} arr an array
 * @param {string|number} element
 * @returns {string|int[]} an array with or without the element arg
 */
export const popOrInsert = (
  arr: (string | number)[],
  element: string | number
) => {
  let res: (string | number)[] = [];
  if (arr.includes(element)) {
    res = arr.filter((el) => el !== element);
  } else {
    res = [...arr, element];
  }
  return res;
};

/**
 * Return the start and end of the year given a date
 * @param year the year in date, string ("2021") or number (2021) format
 * @returns {[Date,Date]} an array where the first element is the start of the year the second one is the end
 */
export const getStartEndOfYear = (
  year: Date | string | number,
  startOrEnd: "start" | "end"
): Date => {
  const currentDate = new Date(year);
  return startOrEnd === "start"
    ? startOfYear(currentDate)
    : lastDayOfYear(currentDate);
};

export const getCurrentBaseUrl = () => {
  if (typeof window !== "undefined") {
    return window.location.protocol + "//" + window.location.host;
  }
  return "http://localhost:3000";
};

export const getImageUrl = (imageAddress: string, size: string = "100") => {
  if (imageAddress.includes(bucket_name)) {
    const [_, __, ___, key] = imageAddress.split("/");
    const retUrl = `${resizer_service_url}/${size}/${key}`;

    return retUrl;
  }

  return imageAddress;
};

export const generateSrcset = (url: string, sizes: number[]) => {
  if (url?.includes(bucket_name)) {
    const rxStore = /(\/original\/\d+-[a-z0-9\-]+\/)/g;
    const storeArr = rxStore.exec(url);
    // logger.log("storeArr: ", storeArr);
    const storeName = storeArr?.[1]?.split("/")[2];
    const newPrefix = (s: number) => `/resized/${storeName}/${s}/`;
    const srcSet = sizes
      .map((size) => `${url.replace(rxStore, newPrefix(size))} ${size}w`)
      .join(",");
    return srcSet;
  }

  return url;
};

export const clog = (obj: any) => {
  logger.log(util.inspect(obj, false, null, true));
};

export const roundAccurately = (number: number, decimalPlaces: number) =>
  Number(
    Math.round((number + "e" + decimalPlaces) as unknown as number) +
      "e-" +
      decimalPlaces
  );

const StatePostcodeMatchers = {
  SA: /^5([0-9]){3}/,
  ACT: /2(?:9(?:1[1-4]|0[0-6])|6(?:1[0-24-8]|0[0-9])|(?:62|54)0)|0200/,
  // ACT: /^[2](?:[6](?:[0][0-9]|[1][0-8])|[9](?:[0-1][0-9]|20))/,
  VIC: /^3([0-9]){3}/,
  QLD: /^4([0-9]){3}/,
  NT: /^08(?:[0-9]){2}/,
  TAS: /^7[0-7](?:[0-9]){2}/,
  WA: /^6(?:[0-6](?:[0-9]){2}|[7](?:[0-8][0-9]|[9][0-7]))/,
  NSW: /^2(?:[0-6](?:[0-9]){2}|[6](?:[1][9]|[2-7][0-9])|[7](?:[0-9]{2})|[8](?:[0-8](?:[0-9]){1}|[9][0-8])|[9](?:[2][1-9]|[3-9][0-9]))/,
};

const AusStates = {
  SA: "South Australia",
  ACT: "Australian Capital Territory",
  VIC: "Victoria",
  QLD: "Queensland",
  NT: "Northern Territory",
  TAS: "Tasmania",
  WA: "Western Australia",
  NSW: "New South Wales",
};

/**
 *
 * @param {string} postcode as pickup postcode
 * @param {string} suburb as pickup suburb
 * @returns {boolean|EInternalErrors} suburb and postcode match
 */
export const validatePostcodeSuburb = async (
  postcode: string,
  suburb: string,
  state: string
) => {
  const url = "https://services.dev.latinshop.com/api/address";
  try {
    const upperSuburb = suburb.toUpperCase().trim();
    const upperState = state.toUpperCase() as
      | "SA"
      | "QLD"
      | "TAS"
      | "VIC"
      | "NSW"
      | "NT"
      | "WA"
      | "ACT";
    let searchResults: IPostcodesResponse = { response: [] };
    try {
      await trpcClient.checkout.verifyAddress
        .mutate({ postcode })
        .then((res) => {
          // @ts-ignore
          if (res) searchResults = res;
        });
    } catch (err) {
      throw EInternalErrors.API_POSTCODE_SUBURB_VALIDATION_ERROR;
    }
    const fullMatch = searchResults.response.find(
      (el) =>
        el.state === state &&
        el.suburb === upperSuburb &&
        el.postcode === postcode
    );
    if (fullMatch) return true;

    const stateSuburbTest = StatePostcodeMatchers[upperState].test(postcode);

    if (!stateSuburbTest)
      // This needs to be handle by the client specifically for the translation
      throw `${EInternalErrors.POSTCODE_NOTVALID_FOR_STATE}::${postcode}::${AusStates[upperState]}`;

    const stateSuburbMatch = searchResults.response.find(
      (el) => el.state === upperState && el.suburb === upperSuburb
    );
    logger.log("stateSuburbMatch: ", stateSuburbMatch);
    if (stateSuburbMatch) throw EInternalErrors.STATE_SUBURB_MATCH_ONLY;

    const postCodeSuburbMatch = searchResults.response.find(
      (el) => el.suburb === upperSuburb && el.postcode === postcode
    );
    logger.log("postCodeSuburbMatch: ", postCodeSuburbMatch);
    if (postCodeSuburbMatch) throw EInternalErrors.POSTCODE_SUBURB_MATCH_ONLY;

    const postCodeStateMatch = searchResults.response.find(
      (el) => el.state === upperState && el.postcode === postcode
    );
    logger.log("postCodeStateMatch: ", postCodeStateMatch);
    if (postCodeStateMatch) throw EInternalErrors.POSTCODE_STATE_MATCH_ONLY;
    throw "No suburb or postcode matches the selected state";
  } catch (err) {
    throw new Error(err as string);
  }
};

/**
 *
 * @param {string} val as postcode
 * @returns {string} only 4 digit postcode
 */
export const parsePostcode = (val: string) => {
  if (!val) return "";
  let digits = val.replace(/\D$/g, "");
  return digits.slice(0, 4);
};

/**
 *
 * @param {string} val any input number
 * @param {number} numberOfDigits the number of digits returned
 * @returns {number} only 4 digits
 */
export const parseDigitsOnly = (val: string, numberOfDigits: number) => {
  if (!val) return "";
  let digits = val.replace(/\D$/g, "");
  return digits.slice(0, numberOfDigits);
};

/**
 *
 * @param {Sendle.Address} val as pickup
 * @returns string
 */

export const parseSendleAddress = (val: Sendle.Address) => {
  const {
    address_line1,
    address_line2,
    suburb,
    postcode,
    state_name,
    country,
  } = val;
  let address = "";
  address += address_line1 + ", ";
  if (address_line2) address += address_line2 + ", ";
  address += suburb + ", " + postcode + ", " + state_name;
  if (country) address += ", " + country;
  return address;
};

/**
 *
 * @param {string} val any input number
 * @param {number} numberOfDigits the number of digits returned
 * @returns {number} only 4 digits
 */
export const parseFloat = (val: string, numberOfDigits: number) => {
  if (!val) return "";
  let digits = val.replace(/\D$/g, "");
  return digits.slice(0, numberOfDigits);
};

/**
 *
 * @param {Sendle.Address | Stripe.Address} val as pickup
 * @returns string
 */

export const parseGenericAddress = (val: Sendle.Address & Stripe.Address) => {
  if (val === undefined) return "";
  const {
    address_line1,
    address_line2,
    line1,
    line2,
    city,
    state,
    suburb,
    postcode,
    postal_code,
    state_name,
    country,
  } = val;

  let address = "";
  address += `${address_line1 ?? line1}, `;
  address += `${address_line2 ?? line2}, `;
  address += `${suburb ?? city}, `;
  address += `${postcode ?? postal_code}, `;
  address += `${state_name ?? state}, `;
  address += `${country ?? ""}`;
  const doubleCommaRX = /(\, \,)/;
  return address.replace(doubleCommaRX, ",").replace(/(, )$/, "");
};

/**
 *
 * @param {string} value as string
 * @returns {string} clean string without non-url chars
 */
export const customSlugify = (value: string) =>
  slugify(value, { lower: true, remove: /[*+~.()'"!:@]/g });

export const getComponentName = (component: ReactNode) => {
  // @ts-ignore
  return component.displayName || component.name || "Component";
};

/**
 *
 * @param {number} length length of the return string
 * @returns {string}
//  */

export const generateRandomString = (length: number): string => {
  return (Math.random() + 1).toString(length).substring(2);
};

export const getBaseName = (path: string) => {
  const filename = path?.split("/")[path?.split("/").length - 1];
  return filename;
};

export const getBasePath = (path: string) => {
  const basePath = path
    ?.split("/")
    ?.slice(0, path.split("/")?.length - 1)
    ?.join("/");
  return basePath;
};

export const disableReactDevTools = (): void => {
  const noop = (): void => undefined;
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === "object") {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === "function" ? noop : null;
    }
  }
};

export const generateUrl = (path: string) => {
  if (typeof window !== "undefined") {
    const origin = location.origin;
    return `${origin}/${path}`;
  }
  return path;
};

export const getCognitoErrorTransKey = (
  value: string
): keyof CustomTypeOptions["resources"]["common"] | string => {
  switch (value) {
    case "Attempt limit exceeded, please try after some time.":
      return "auth_cognito_invalid_limit_exceeded";
    case "Invalid code provided, please request a code again.":
      return "auth_cognito_invalid_code";
    case "The reset link is invalid.":
      return "auth_invalid_reset_key";
    case "Password does not conform to policy: Password must have lowercase characters":
      return "auth_invalid_password_must_have_lowercase";
    case "Password does not conform to policy: Password must have symbol characters":
      return "auth_invalid_password_must_have_symbol";
    case "Password does not conform to policy: Password must have numeric characters":
      return "auth_invalid_password_must_have_numeric";
    case "Password does not conform to policy: Password must have uppercase characters":
      return "auth_invalid_password_must_have_uppercase";
    case "Invalid verification code provided, please try again.":
      return "auth_cognito_invalid_code";
    case "An account with the given email already exists.":
      return "auth_cognito_account_exists";
    default:
      return value;
  }
};

export const convertSid = (val: string) => {
  const RX = /^((\_p)(\d+))/;
  if (val === "web") return 0;
  const thesid = val.match(RX);
  if (thesid?.[3] && !isNaN(parseInt(thesid[3]))) return parseInt(thesid[3]);
  return 0;
};

export const underscoreDashToSpace = (val: string) => {
  return val.replace(/_-/g, " ");
};

export const convertAnchorTagsToClientSideNavigation = (
  ref: MutableRefObject<HTMLDivElement | null>,
  router: NextRouter
) => {
  if (ref.current) {
    const rootEl = ref.current.firstElementChild;
    const links = rootEl?.getElementsByTagName("a") ?? [];
    for (let i = 0; i < links.length; i++) {
      const link = links?.[i] as HTMLAnchorElement;
      const href = link.getAttribute("href");
      const target = link.getAttribute("target");
      const rel = link.getAttribute("rel");
      let isClientNavigation = false;
      if (href && href.startsWith("/") && !target && !rel) {
        isClientNavigation = true;
      }

      link.onclick = (e) => {
        if (isClientNavigation) {
          e.preventDefault();
          e.stopPropagation();
          router.push(href ?? "/");
        }
      };
    }
  }
};

const isServer = () => typeof window === "undefined";

const shouldLog = () => {
  if (
    process.env.NEXT_PUBLIC_STAGE === "production" &&
    process.env.NODE_ENV === "production"
  )
    return false;
  return true;
};

export class logger {
  constructor() {}

  static shouldLog() {
    if (
      process.env.NEXT_PUBLIC_STAGE === "production" &&
      process.env.NODE_ENV === "production"
    )
      return false;
    return true;
  }

  static isServer() {
    return typeof window === "undefined";
  }

  static log(...args: any[]) {
    if (this.isServer()) return console.log(...args);

    if (this.shouldLog()) {
      console.log(...args);
    }
  }
  static deep(...args: any[]) {
    if (isServer())
      return console.log(
        ...args.map((arg) =>
          typeof arg === "object" ? util.inspect(arg, false, null, true) : arg
        )
      );
  }
  static warn(...args: any[]) {
    if (shouldLog()) {
      console.warn(...args);
    }
  }
  static error(...args: any[]) {
    if (shouldLog()) {
      console.error(...args);
    }
  }
  static table(...args: any[]) {
    if (shouldLog()) {
      console.table(...args);
    }
  }
}

export const domain =
  process.env.NODE_ENV === "production"
    ? process.env.NEXT_PUBLIC_STAGE === "production"
      ? "www.latinshop.com.au"
      : "commerce.stage.latinshop.com"
    : "localhost:3001";

export function deleteCognitoCookies() {
  const cookieList = ["LastAuthUser", "refreshToken"];
  if (document.cookie) {
    const cookies = document.cookie.split("; ");
    const cognitoCookies = cookies.filter((cookie) =>
      cookie.startsWith("CognitoIdentityServiceProvider")
    );
    eraseCookie("user_id");
    cognitoCookies.forEach((cookie) => {
      const key = cookie.split("=")[0];
      document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; Domain=${domain};`;
    });
    document.cookie = `user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; Domain=${domain};`;
  }
}

function eraseCookie(name: string) {
  document.cookie = name + "=; Max-Age=-99999999;";
}

export const isEven = (n: number) => n % 2 === 0;

//@ts-ignore
export const gmapsLink = (address: Stripe.Address & Sendle.Address) =>
  "https://www.google.com/maps/place/" +
  encodeURIComponent(
    `${address?.line1},${address?.line2 ? address.line2 : ""},${
      address?.city
    },${address?.postal_code},${address?.state},${address?.country}`
  );

export const gmapsLinkSendle = (address: Sendle.Address) =>
  "https://www.google.com/maps/place/" +
  encodeURIComponent(
    `${address?.address_line1},${
      address?.address_line2 ? address.address_line2 : ""
    },${address?.suburb},${address?.postcode},${address?.state_name},${
      address?.country ? address.country : "Australia"
    }`
  );

export const getCookies = () => {
  if (typeof document === "undefined") return {};
  const cookies = document.cookie.split("; ");
  const cookieObj: Partial<Record<string, string>> = {};
  cookies.forEach((cookie) => {
    const [key, value] = cookie.split("=");
    cookieObj[key] = value;
  });
  return cookieObj;
};

export const getCookie = (cookieName: string) => {
  if (typeof document === "undefined") return "";
  const cookies = getCookies();
  return (cookies?.[cookieName] ?? "") as string;
};

function getCssStyle(element: HTMLElement, prop: string) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

export function getCanvasFont(el = document.body) {
  const fontWeight = getCssStyle(el, "font-weight") || "normal";
  const fontSize = getCssStyle(el, "font-size") || "16px";
  const fontFamily = getCssStyle(el, "font-family") || "Times New Roman";

  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

export function getTextWidth(text: string, font: string) {
  // re-use canvas object for better performance
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const ctx = context!;
  ctx.font = font;
  const metrics = ctx.measureText(text);
  return metrics.width;
}
