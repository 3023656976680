import { useEffect, useRef } from "react";

const useEffectAfterInit = (effect: () => void, deps?: any[]) => {
	const mount = useRef(false);
	useEffect(() => {
		mount.current = true;
	}, []);

	useEffect(() => {
		if (mount.current) {
			effect();
		}
	}, deps);
};

export default useEffectAfterInit;
