import { ReactNode, useEffect, useRef, useState } from "react";

import useAppStore from "@/store/use-app";
import { ChevronLeftIcon, Icon } from "@chakra-ui/icons";
import { Center, Flex, Spacer, VStack } from "@chakra-ui/react";

import dynamic from "next/dynamic";
import { ChakraNextLink } from "../chakra-links";

const DevelopBanner = dynamic(() => import("@/components/develop-banner"), {
  ssr: false,
  loading: () => null,
});

export const AuthLayout = ({ children }: { children: ReactNode }) => {
  const stackRef = useRef<HTMLDivElement | null>(null);
  const flexRef = useRef<HTMLDivElement | null>(null);

  const [paddingTop, setPaddingTop] = useState<number | null>(null);

  const showDevelopBanner = useAppStore((state) => state.showDevelopBanner);

  useEffect(() => {
    const bgImage = new Image();
    bgImage.onload = function () {
      if (stackRef.current) {
        stackRef.current.style.backgroundImage = "url(/images/fodologin.svg)";
      }
    };
    bgImage.src = "/images/fodologin.svg";
  }, []);

  useEffect(() => {
    setPaddingTop(
      showDevelopBanner
        ? flexRef.current?.getBoundingClientRect()?.height ?? null
        : null
    );
  }, [showDevelopBanner]);

  return (
    <VStack
      w="full"
      flexDir={"column"}
      h="full"
      id="auth-layout"
      align={"center"}
      minH="100vh"
      justify="center"
      ref={stackRef}
      pos="relative"
    >
      <Flex w="full" ref={flexRef}>
        <DevelopBanner />
      </Flex>
      <ChakraNextLink
        href="/"
        top={paddingTop ? `${paddingTop}px` : "0"}
        left={2}
        pos="absolute"
      >
        <Center rounded={"full"} bg="gray.500" cursor={"pointer"} p={2}>
          <Icon as={ChevronLeftIcon} color="white" />
        </Center>
      </ChakraNextLink>

      <Flex
        w="full"
        align={"center"}
        justify="center"
        mt="14"
        id="auth-lauyout"
      >
        {children}
      </Flex>
      <Spacer id="spacer" />
    </VStack>
  );
};
