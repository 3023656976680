import { AnimateSharedLayout, motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useEffect, useState } from "react";
import { FiMinus, FiPlus, FiShoppingCart, FiTrash } from "react-icons/fi";

import { BaseProductFragment } from "@/graphql/__generated__/client-side";
import { Product } from "@/lib/product.class";
import useAppStore from "@/store/use-app";
import useCartGlobalStore from "@/store/use-cart";
import { Cart } from "@/types/cart-state";
import { AppComp } from "@/types/components";
import {
	Box,
	Button,
	Center,
	Circle,
	Divider,
	Flex,
	Grid,
	GridItem,
	HStack,
	Icon,
	IconButton,
	Image as ChakraImage,
	Stack,
	Text,
	useMediaQuery,
	useQuery,
	VStack,
} from "@chakra-ui/react";

import { ChakraNextLink } from "./chakra-links";
import { InStoreOnlyBadge } from "./InventoryBadge";
import KeyValueDisplay from "./key-value-display.component";

type CartItemProps = {
	data: Cart.Item;
	[x: string]: any;
};

const shippingRate = 12;

const ItemPriceDisplay = (props: AppComp.GenericProps) => {
	const { text, ...rest } = props;
	const small = useQuery({ below: "md" });
	const [isSmallDevice] = useMediaQuery(small);
	return (
		<Text textAlign="right" whiteSpace="nowrap" {...rest}>
			{!isSmallDevice && "AU"} {"$"}
			{text}
		</Text>
	);
};

export const CartContent = () => {
	const small = useQuery({ below: "md" });
	const small2 = useQuery({ below: "sm" });
	const [isSmallDevice] = useMediaQuery(small);
	const [isSmaller] = useMediaQuery(small2);
	const [deleting, setDeleting] = useState(false);
	const { items, removeItem } = useCartGlobalStore();

	return (
		<Flex direction="column">
			<AnimateSharedLayout>
				<motion.ul layout>
					{items.map((item) => (
						<CartItem key={item.inventory_id} data={item} />
					))}
				</motion.ul>
			</AnimateSharedLayout>
		</Flex>
	);
};

export const CartHeader = () => {
	const { t } = useTranslation("common");
	return (
		<Flex pb={4}>
			<Flex flexGrow={14} justifyContent="center">
				ITEM
			</Flex>
			<Flex flexGrow={1} justifyContent="flex-end">
				{t("common_price", { defaultValue: "Price" })}
			</Flex>
		</Flex>
	);
};

export const CartMenu = ({}) => {
	const { totalCartItems } = useCartGlobalStore();
	const sid = useAppStore((s) => s.purchase.sid);
	return (
		<Flex paddingX={0}>
			<Link href={`/${!sid ? "web" : sid}/cart`} passHref>
				<Flex position="relative" as="button">
					<Icon color="brandOrange" w={8} h={8} as={FiShoppingCart} />
					{totalCartItems >= 1 ? (
						<Circle bg="gray.200" size="18px" position="absolute" top={-2} left={5}>
							<Text fontSize="14px" color="gray.500">
								{totalCartItems}
							</Text>
						</Circle>
					) : null}
				</Flex>
			</Link>
		</Flex>
	);
};

export const CartQtyControls = (props: CartItemProps) => {
	const addItem = useCartGlobalStore((state) => state.addItem);
	const decreaseItemQty = useCartGlobalStore((state) => state.decreaseItemQty);
	const items = useCartGlobalStore((state) => state.items);
	const liveItem = items.find((el) => el.inventory_id === props.data.inventory_id) as Cart.Item;
	const product = new Product(props.data.product as unknown as BaseProductFragment | BaseProductFragment[]);
	const payload = {
		...product.getCartPayload(),
		url: liveItem?.product?.url,
		inventory_id: liveItem?.inventory_id,
		variant: liveItem?.product?.variant,
	} as Cart.Product;

	return (
		<Flex
			rounded="lg"
			direction="row"
			alignContent="center"
			alignItems="center"
			borderStyle="solid"
			borderWidth="1px"
			height="24px"
			width="80px"
			borderColor="gray.300"
			boxShadow="md"
			{...props}
			justifyContent="center"
		>
			<IconButton
				aria-label="remove"
				mx={1}
				bg="white"
				size="xss"
				fontSize="12px"
				color="gray.600"
				icon={liveItem?.quantity === 1 ? <FiTrash /> : <FiMinus />}
				onClick={() => decreaseItemQty(props.data.product)}
			/>
			<Text px={2} textAlign="center" fontSize="xs" color="gray.700">
				{liveItem?.quantity}
			</Text>
			<IconButton
				aria-label="add"
				fontSize="12px"
				bg="white"
				size="xss"
				mx={1}
				icon={<FiPlus />}
				color="gray.600"
				onClick={() => addItem(payload, false)}
			/>
		</Flex>
	);
};

export const CartImage = (props: CartItemProps) => {
	const { data, ...rest } = props;
	return (
		<Flex border="1px solid" p={1} borderColor="gray.200" borderRadius={5} {...rest}>
			<ChakraImage alt="" src={data.product?.image ? data.product?.image : ""}></ChakraImage>
		</Flex>
	);
};

export const CartFooter2 = (props: AppComp.GenericProps) => {
	const { subTotalPrice } = useCartGlobalStore();
	return (
		<>
			<Grid
				templateRows="repeat(1, 1fr)"
				templateColumns="repeat(12, 1fr)"
				pt={2}
				pb={2}
				display={["none", "none", "none", "none", "none", "grid"]}
				gap={4}
			>
				<GridItem colSpan={9}>
					<Text
						fontWeight="bold" // textAlign="start"
						width="100%"
						pl={8}
					>
						SUBTOTAL
					</Text>
				</GridItem>
				<GridItem colSpan={3} justifyContent="">
					<Text pr={1} fontWeight="bold" textAlign="center" width="100%">
						AUD ${subTotalPrice.toFixed(2)}
					</Text>
				</GridItem>
			</Grid>
			<Box width="100%" display={["block", "block", "block", "block", "block", "none"]}>
				<Flex justifyContent="space-between" w="100%" color="gray.600" pl={4}>
					<Text
						fontWeight="bold" // textAlign="start"
						width="100%"
					>
						SUBTOTAL
					</Text>
					<Text pr={1} fontWeight="bold" textAlign="end" width="100%">
						AUD ${subTotalPrice.toFixed(2)}
					</Text>
				</Flex>
			</Box>
		</>
	);
};
export const PriceDisplay = (props: AppComp.GenericProps) => {
	return (
		<HStack spacing={1} justifyContent="space-between">
			<Text
				fontSize={props.fontSize && props.fontSize}
				fontWeight={props.fontWeight && props.fontWeight}
				textDecoration={props.textDecoration && props.textDecoration}
				color={props.color && props.color}
			>
				{props.currency}
			</Text>
			<Text
				fontSize={props.fontSize && props.fontSize}
				fontWeight={props.fontWeight && props.fontWeight}
				textDecoration={props.textDecoration && props.textDecoration}
				color={props.color && props.color}
			>
				{props.prefix}
				{props.children}
			</Text>
		</HStack>
	);
};

export const CartFooter = (props: AppComp.GenericProps) => {
	const { subTotalPrice, totalCartItems } = useCartGlobalStore();

	return (
		<>
			<Flex>
				<Text
					fontWeight="bold"
					// textAlign=""
					width="100%"
					color="gray.500"
					pl={3}
				>
					{totalCartItems} ITEMS
				</Text>
				<PriceDisplay fontWeight="bold" textAlign="right" currency="AU" prefix="$">
					{subTotalPrice.toFixed(2)}
				</PriceDisplay>
			</Flex>
			<Flex>
				<Text
					fontWeight="bold" // textAlign="start"
					width="100%"
					pl={3}
				>
					SHIPPING
				</Text>
				<PriceDisplay currency="AU" prefix="$" width="100%">
					{shippingRate.toFixed(2)}
				</PriceDisplay>
			</Flex>
			<Flex>
				<Text
					fontWeight="bold" // textAlign="start"
					width="100%"
					pl={3}
				>
					TOTAL
				</Text>
				<PriceDisplay currency="AU" prefix="$">
					{(subTotalPrice + shippingRate).toFixed(2)}
				</PriceDisplay>
			</Flex>
		</>
	);
};

//  2 - 4 - 3 - 3

export const CartItem = (props: AppComp.CartItemProps) => {
	const { t: tcommon } = useTranslation("common");
	const { t: tcheckout } = useTranslation("checkout");

	const small = useQuery({ below: "md" });
	const small2 = useQuery({ below: "sm" });
	const [isSmallDevice] = useMediaQuery(small);
	const [isSmaller] = useMediaQuery(small2);
	const [open, setOpen] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const { removeItem } = useCartGlobalStore();
	const { data: item } = props;

	useEffect(() => {
		setOpen(true);
		return () => setOpen(false);
	}, []);

	return (
		<motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
			{open ? (
				<VStack alignContent="flex-start" justifyItems="flex-start" alignItems="flex-start">
					<HStack justifyContent="space-between" width="100%">
						<Center py={2}>
							<CartImage display={{ base: "none", xs: "block" }} w={24} data={item as Cart.Item} />
						</Center>
						<VStack
							py={0}
							justifyContent="space-around"
							width="100%"
							// flexGrow={2}
							height="100%"
							alignItems="flex-start"
							justifyItems="flex-start"
							alignContent="flex-start"
						>
							<Center py={2}>
								<CartImage display={{ base: "block", xs: "none" }} w={12} data={item as Cart.Item} />
							</Center>
							<ChakraNextLink href={item.product.url} fontSize={["xs", "xs", "md", "lg"]}>
								{item.product.name}
							</ChakraNextLink>
							<Stack
								direction={{ base: "column", md: "row" }}
								w="full"
								align={{ base: "flex-start", md: "center" }}
								justify="flex-start"
							>
								<HStack>
									<Box fontSize="xs" fontWeight="bold">
										by {item.product.store.name}
									</Box>
									<Flex gap={2}>
										<KeyValueDisplay object={item.product.variant} />{" "}
									</Flex>
								</HStack>
								{item.product.in_store_only && <InStoreOnlyBadge />}
							</Stack>
							<Stack
								justifyContent="flex-start"
								width="100%"
								direction={["column", "column", "column", "column", "row"]}
								spacing={4}
							>
								<Flex justifyContent="space-between">
									<CartQtyControls data={item} />
									{isSmaller && (
										<ItemPriceDisplay
											// textDecor={item.product.in_store_only ? "line-through" : "unset"}
											text={(item.quantity * item.product.price).toFixed(2)}
										/>
									)}
								</Flex>
								<HStack>
									<Button
										variant="soft"
										size="xs"
										disabled={deleting}
										isLoading={deleting}
										onClick={async () => {
											removeItem(item);
										}}
									>
										{tcommon("common_cart_delete", { defaultValue: "Delete", ns: "common" })}
									</Button>
									<Text color="error">
										{tcheckout(`${item.adjust}` as "cart_mod_LIMITED_STOCK", {
											defaultValue: "",
											ns: "checkout",
										})}
									</Text>
								</HStack>
							</Stack>
						</VStack>
						{!isSmaller && (
							<Flex alignContent="baseline" height="100%" py={0}>
								<ItemPriceDisplay
									// textDecor={item.product.in_store_only ? "line-through" : "unset"}
									text={(item.quantity * item.product.price).toFixed(2)}
								/>
							</Flex>
						)}
					</HStack>
					<Divider bg="gray.200" />
				</VStack>
			) : null}
		</motion.div>
	);
};
