import {
	CloseButtonProps,
	Modal,
	ModalBody,
	ModalBodyProps,
	ModalCloseButton,
	ModalContent,
	ModalContentProps,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ModalProps,
} from "@chakra-ui/react";
import { forwardRef, MutableRefObject, ReactNode } from "react";
import { RenderIf } from "./render-if";

// import Tos from "./Tos";

export const GenericModal = forwardRef<
	HTMLElement,
	{
		onOpen?: () => void;
		onClose: () => void;
		modalProps?: Partial<ModalProps>;
		modalContentProps?: Partial<ModalContentProps>;
		modalBodyProps?: Partial<ModalBodyProps>;
		modalCloseButtonProps?: CloseButtonProps;
		isOpen: boolean;
		title: string | ReactNode;
		data?: any;
		footer?: JSX.Element;
		body: JSX.Element;
		header?: JSX.Element;
	}
>((props, ref) => {
	const {
		isOpen,
		onOpen,
		onClose,
		data,
		footer,
		title,
		body,
		header,
		modalProps,
		modalContentProps,
		modalCloseButtonProps,
		...rest
	} = props;

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="3xl" {...modalProps}>
				<ModalOverlay />
				<ModalContent
					{...modalContentProps}
					ref={(node) => {
						if ((ref as MutableRefObject<HTMLElement>)?.current && node) {
							(ref as MutableRefObject<HTMLElement>).current = node;
						}
					}}
					rounded="xl"
				>
					<ModalHeader>{header}</ModalHeader>
					<RenderIf condition={!!title}>
						<ModalHeader>{title}</ModalHeader>
					</RenderIf>
					<ModalCloseButton zIndex={3000} {...modalCloseButtonProps} />
					<ModalBody {...props.modalBodyProps}>{body}</ModalBody>
					<ModalFooter>{footer}</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
});

GenericModal.displayName = "GenericModal";
