import { useTranslation } from "next-i18next";

import { Box, Divider, Grid, StackProps, Text, VStack } from "@chakra-ui/react";
import { ShortDayMap } from "common";

import type { IBusinessHours } from "common";
const OpenHoursDisplay = (props: { hours: IBusinessHours; stackProps?: StackProps }) => {
	const { t } = useTranslation("checkout");
	const columnThree = props.hours
		?.map((hour, index) => {
			if (hour.day_period.length === 1)
				return [
					<Text key={"hollly" + index}>
						{t(`pickup_day_${ShortDayMap[hour.day_period[0]]}` as "pickup_day_Sun", {
							defaultValue: ShortDayMap[hour.day_period[0]],
						})}
					</Text>,
					hour.hour_periods.length === 0 ? (
						<Text key={"holly" + index}>{t("pickup_closed", { defaultValue: "Closed" })}</Text>
					) : (
						<Box key={hour.day_period[hour.day_period.length - 1] + index}>
							{hour.hour_periods.map((period, index) => {
								return (
									<Text key={index} whiteSpace="nowrap">
										<time>{period.open}</time>
										{" - "}
										<time> {period.close}</time>
									</Text>
								);
							})}
						</Box>
					),
				];
			return [
				<Text key={hour.day_period[0] + index}>
					{t(`pickup_day_${ShortDayMap[hour.day_period[0]]}` as "pickup_day_Sun", {
						defaultValue: ShortDayMap[hour.day_period[0]],
					})}
					{" - "}
					{t(`pickup_day_${ShortDayMap[hour.day_period[hour.day_period.length - 1]]}` as "pickup_day_Sun", {
						defaultValue: ShortDayMap[hour.day_period[hour.day_period.length - 1]],
					})}
				</Text>,
				hour.hour_periods.length === 0 ? (
					<Text key={"holly" + index}>{t("pickup_closed", { defaultValue: "Closed" })}</Text>
				) : (
					<Box key={hour.day_period[hour.day_period.length - 1] + index}>
						{hour.hour_periods.map((period, index) => {
							return (
								<Text key={index} whiteSpace="nowrap">
									<time>{period.open}</time>
									{" - "}
									<time> {period.close}</time>
								</Text>
							);
						})}
					</Box>
				),
			];
		})
		.flat();

	return (
		<VStack w="full" align={"start"} {...props.stackProps}>
			<Divider />
			<Grid gridTemplateColumns={"1.5fr 1fr"}>{columnThree?.map((el) => el)}</Grid>
		</VStack>
	);
};

export default OpenHoursDisplay;
