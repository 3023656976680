const path = require("path");

// Migrate to tolgee, investigate how to use tolgee with next-i18next
// https://github.com/i18next/next-i18next/blob/master/src/config/defaultConfig.ts

/** @type {import('next-i18next').UserConfig} */
module.exports = {
	localePath: path.resolve("./public/locales"),
	ns: ["services_translation", "common", "checkout", "account", "product", "checkin", "error"],
	supportedLngs: ["en", "es", "pt", "cimode"],
	appendNamespaceToCIMode: true,
	fallbackLng: "en",
	localeStructure: "{{ns}}/{{lng}}",
	lng: "en",
	i18n: {
		defaultLocale: "en",
		locales: ["en", "pt", "es"],
	},
};
