import { NextRouter } from "next/router";
import { createContext, useCallback } from "react";

import { useLscm_UpdateShoppingCartMutation } from "@/graphql/__generated__/client-side";
import useAuth from "@/hooks/use-auth";
import useCartGlobalStore from "@/store/use-cart";

import { useDebounce } from "./use-debounce";
import useEffectAfterInit from "./use-effect-after-init";

type CtxVals = { mountCount: number };
export const RootContext = createContext<CtxVals>({ mountCount: 0 });

const useSyncCart = (props: { router: NextRouter }) => {
	const {
		state: { loading, isLoggedIn, userInfo },
	} = useAuth();
	const items = useCartGlobalStore((state) => state.items);

	const { mutate } = useLscm_UpdateShoppingCartMutation();
	const debouncedItems = useDebounce(items, 4000);

	const updateCart = useCallback(() => {
		if (isLoggedIn) mutate({ items: debouncedItems });
	}, [debouncedItems, isLoggedIn]);

	useEffectAfterInit(updateCart, [debouncedItems]);

	return null;
};

export default useSyncCart;
