import { useRouter } from "next/router";
import React, { createContext, ReactNode, useEffect } from "react";

export const RouteChangingCtx = createContext<
  { isChanging: boolean; route: string | null } | undefined
>(undefined);

const RouteChangingProvider = (props: { children: ReactNode }) => {
  const [loading, setLoading] = React.useState(false);
  const router = useRouter();

  function routeStart(url: string, { shallow }: { shallow: boolean }) {
    setLoading(true);
  }

  function routeComplete(url: string, { shallow }: { shallow: boolean }) {
    setLoading(false);
  }

  function routeError(
    err: Error,
    url: string,
    { shallow }: { shallow: boolean }
  ) {
    setLoading(false);
  }

  useEffect(() => {
    router.events.on("routeChangeStart", routeStart);
    router.events.on("routeChangeComplete", routeComplete);
    router.events.on("routeChangeError", routeError);
    return () => {
      router.events.off("routeChangeStart", routeStart);
      router.events.off("routeChangeComplete", routeComplete);
      router.events.off("routeChangeError", routeError);
    };
  }, []);

  return (
    <RouteChangingCtx.Provider
      value={{ isChanging: loading, route: router.asPath }}
    >
      {props.children}
    </RouteChangingCtx.Provider>
  );
};

export default RouteChangingProvider;
