import { extendTheme } from '@chakra-ui/react';

import breakpoints from './breakpoints';
import { colors } from './colors';

const fonts = { mono: `'Menlo', monospace` };
// import { StepsStyleConfig as Steps } from "../modules/steps/";

const theme = extendTheme({
	semanticTokens: {
		colors: {
			blueishbg: { default: "#f8f7f7" },
			link: { default: "#3374ee" },
			redeem: { default: "#38bdf8" },
			marketplace: { default: "#662d91" },
			topseller: { default: "#FFBF28" },
			markeplate: { default: "#4E4B72" },
			latinpoints: { default: "#38BDF8" },
			qrcode: { default: "#66757f" },
			mainbg: { default: "white" },
			error: { default: "#e53e3e" },
			br: { default: "brandOrange2.500" },
			checkoutBg: { default: "rgba(244,247,255,0.9)" },
			brandGrad: { default: "linear-gradient(to right, #fc4a1a, #ff6d00);" },
			soft_heading: { default: "gray.500" },
		},
	},
	colors,
	fonts: {
		heading: "Amazon Ember",
		body: "Amazon Ember",
		text: "Amazon Ember",
	},
	components: {
		Popover: {
			variants: {
				responsive: {
					content: {
						w: "unset",
						minW: "unset",
					},
					popper: {
						minW: "unset",
						w: "unset",
					},
				},
			},
		},
		Flex: {
			variants: {
				gp: {
					boxShadow: "0 0 0 1px rgba(63,63,68,0.05),0 1px 3px 0 rgba(63,63,68,0.15)",
				},
			},
		},

		IconButton: {
			variants: {
				"add-fav": {
					bg: "red",
					border: "1px",

					rounded: "full",
				},
				"add-cart-icon-button": {
					bg: "addCartGreen2",

					color: "white",
					fontWeight: "bold",
					_disabled: {
						bg: "addCartGreen2",
						opacity: 0.55,
					},
					_hover: {
						opacity: 0.89,
						bg: "addCartGreen2",
						ring: "3px",
						ringColor: "cyan.300",
						ringOffsetColor: "cyan.500",
						ringOffset: "2px",
						_disabled: {
							bg: "addCartGreen2",
							opacity: 0.55,
						},
					},
				},
			},
		},
		Text: {
			variants: {
				"as-link": {
					_hover: { cursor: "pointer", color: "#007bff", textDecor: "underline" },
				},
				"as-description": {
					color: "#233D4D",
					bg: "#233D4D",
				},
				"form-error": {
					color: "#f25e60",
					fontWeight: "bold",
				},
			},
		},
		Badge: {
			variants: {
				brand: {},
			},
		},
		Button: {
			variants: {
				primary: {
					_hover: {
						cursor: "pointer",
					},

					py: "2px",
					color: "white",
					bg: "brandOrange",
					fontWeight: "bold",
				},
				secondary: {
					_hover: {
						cursor: "pointer",
					},

					size: "sm",
					py: "2px",
					color: "white",
					bg: "addCartGreen",
					fontWeight: "bold",
				},
				"type-submit": {
					_hover: {
						bgGradient: "linear(to-l, #fe6d00, #FF0080)",
						opacity: 0.85,
						_disabled: {
							bgGradient: "linear(to-l, #fe6d00, #FF0080)",
							opacity: 0.85,
						},
					},
					_disabled: {
						bgGradient: "linear(to-l, #fe6d00, #FF0080)",
						opacity: 0.8,
					},

					bgGradient: "linear(to-l, #fe6d00, #FF0080)",
					color: "white",
					fontWeight: "bold",
					width: "100%",
				},
				soft: {
					// mx: 5,
					// colorScheme:"red",
					size: "2xl",
					color: "gray.700",
					rounded: "lg",
					colorScheme: "whiteAlpha",
					boxShadow: "md",
					borderStyle: "solid",
					borderWidth: "1px",
					borderColor: "gray.300",
				},
				"add-cart": {
					bg: "addCartGreen2",
					// minWidth: "10em",
					// opacity: 0.85,
					width: 48,
					color: "white",
					// rounded: "xl",
					fontWeight: "bold",
					_disabled: {
						bg: "addCartGreen2",
						opacity: 0.55,
					},
					_hover: {
						opacity: 0.89,
						bg: "addCartGreen2",
						ring: "3px",
						ringColor: "cyan.300",
						ringOffsetColor: "cyan.500",
						ringOffset: "2px",
						_disabled: {
							bg: "addCartGreen2",
							opacity: 0.55,
						},
					},
					// _active: {
					// 	bg: "addCartGreen",
					// },
				},
				checkout: {
					my: 2,
					fontWeight: "bold",
					w: "full",
				},
			},
		},
	},
	breakpoints,
	layerStyles: {
		base: {
			bg: "gray.50",
			border: "2px solid",
			borderColor: "gray.500",
		},
		selected: {
			bg: "teal.500",
			color: "gray.700",
			borderColor: "orange.500",
		},
	},
});

export default theme;
