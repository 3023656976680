import { Box, Divider } from "@chakra-ui/react";

function SectionDivider({}) {
	return (
		<Box my={3}>
			<Divider />
		</Box>
	);
}

export default SectionDivider;
