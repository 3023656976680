import useRouteChanging from "@/hooks/use-route-changing";
import { Center, chakra } from "@chakra-ui/react";

const RouteLoadingOverlay = ({
  route,
  exceptions,
}: {
  route?: string | RegExp | undefined;
  exceptions?: (string | RegExp)[];
}) => {
  const isLoading = useRouteChanging(route, exceptions);

  const _opacity = isLoading ? 0.48 : 0;

  return (
    <Center
      w="100vw"
      h="100vh"
      id="route-overlay"
      bg={`rgba(0, 0, 0, ${_opacity})`}
      pos="fixed"
      top="0"
      left={0}
      zIndex={"1000"}
      pointerEvents={isLoading ? "all" : "none"}
    >
      {isLoading ? (
        <chakra.span
          opacity={1}
          zIndex={"1200"}
          className="loader"
        ></chakra.span>
      ) : null}
    </Center>
  );
};

export default RouteLoadingOverlay;
