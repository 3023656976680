import produce from "immer";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";

import { trpc } from "@/lib/api/trpc/utils/trpc";
import { Box } from "@chakra-ui/react";

import type { TBaseProductExtended } from "@/lib/static-props-data/types";
import { logger } from "@/utils/helpers";
import useAppStore from "@/store/use-app";
type TLocalCtxValue = {
  state: {
    data: TBaseProductExtended & {
      inventory_status: "no stock" | "low stock" | "available" | "";
    };
    variant: { id: string | null; selection: Record<string, string> | {} };
  };
  actions: { setVariant: (arg: Record<string, string>) => void };
  loading: boolean;
};

export const LocalCtx = createContext<TLocalCtxValue | undefined>(undefined);

type IState = TLocalCtxValue["state"];

type TStockLevel = "low stock" | "no stock" | "available";

type TActions =
  | {
      type: "SET_VAR_SELECTION";
      payload: IState["variant"]["selection"];
    }
  | {
      type: "SET_VAR_STOCK";
      payload: { status: TStockLevel; inventory_id: string | null };
    }
  | {
      type: "SET_DATA";
      payload: TLocalCtxValue["state"]["data"];
    };

const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case "SET_VAR_SELECTION":
      return produce(state, (d) => {
        d.variant.selection = { ...state.variant.selection, ...action.payload };
      });
    case "SET_VAR_STOCK":
      return produce(state, (d) => {
        d.variant.id = action.payload.inventory_id;
        d.data.inventory_status = action.payload.status;
      });
    case "SET_DATA":
      return produce(state, (d) => {
        d.data = { ...action.payload } as any;
        // logger.log("returnproduce ~ action.payload", action.payload)
      });
    default:
      throw Error("Unhandled action");
    // break;
  }
};

const curriedReducerFunction = produce(reducer);

export const LocalCtxProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: TLocalCtxValue["state"]["data"];
}) => {
  const [state, d] = useReducer(curriedReducerFunction, {
    data: { ...value },
    variant: { id: null, selection: {} },
  });
  const sid = useAppStore((state) => state.purchase.sid) ?? "web";
  const mount = useRef(0);

  const { data: variationData, isInitialLoading: isLoading } =
    trpc.inventory.getStock.useQuery(
      { product_id: state.data?.id, variation: state?.variant?.selection, sid },
      { enabled: state.data.has_variants }
    );

  const setVariant = (arg: Record<string, string>) => {
    d({ type: "SET_VAR_SELECTION", payload: arg });
  };

  useEffect(() => {
    if (variationData?.inventory_id && state.data.has_variants) {
      d({
        type: "SET_VAR_STOCK",
        payload: {
          status: variationData.status,
          inventory_id: variationData.inventory_id,
        },
      });
    }
  }, [variationData]);

  useEffect(() => {
    if (!state.data.has_variants) {
      d({
        type: "SET_VAR_STOCK",
        payload: {
          status:
            (state.data.inventories?.[0]?.cf_status as TStockLevel) ??
            "no stock",
          inventory_id: state.data.inventories?.[0]?.id,
        },
      });
    }
    mount.current = 1;
  }, []);

  useEffect(() => {
    if (mount.current >= 1) {
      d({
        type: "SET_DATA",
        payload: {
          ...value,
          inventory_status:
            (value.inventories?.[0]?.cf_status as TStockLevel) ?? "no stock",
        },
      });
    }
  }, [value]);

  const actions = { setVariant };

  return (
    <LocalCtx.Provider value={{ state, actions, loading: isLoading }}>
      {children}
    </LocalCtx.Provider>
  );
};

export const useLocalCtx = () => {
  const ctx = useContext(LocalCtx);
  if (ctx === undefined) throw Error("There is no context provider");

  return ctx as TLocalCtxValue;
};

export const Test = () => {
  const { state } = useLocalCtx();
  return <Box>{state.data.inventory_status}</Box>;
};
