import { ReactNode } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { Sendle } from "sendle-node";

import { Store } from "@/graphql/__generated__/server-side";
import { InputProps, SelectProps, StackProps } from "@chakra-ui/react";

export enum EnumCheckoutOrderType {
  pickup = "PICKUP",
  delivery = "DELIVERY",
  noSelection = "NO_SELECTION",
}

export type TPurchaseMode = "PICKUP" | "DELIVERY";

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type PostCode = {
  Zip: string;
  Suburb: string;
  State: string;
};

export type Postcodes = PostCode[];

export type TAddress = {
  line1: string;
  line2: string;
  city: string;
  postal_code: string;
  state: string;
};

export interface IAddress extends TAddress {}

export interface QuoteForStore extends Sendle.Quote {
  storeId: number;
}

export type ShippingCost = {
  store: Partial<Store>;
  amount: number;
  cost: number;
  shipping_id: number;
  weight: number;
  delivery_suburb: string;
  delivery_state: string;
  eta: number[];
};

export enum ECognitoError {
  NOT_AUTHENTICATED = "The user is not authenticated",
}

export enum EStripeCheckoutSessionVerifyApiErrors {
  UNAUTHORIZED = "The session Id does not belong to the user requesting it",
  UNRELATED_SESSION = "The session does not corresponds to this platform",
  WRONG_PREFIX = "Wrong checkout session string",
  UNPAID_STATUS = "The checkout session indicates unpaid status",
}

export enum EInternalErrors {
  INTERAL_SERVER_ERR = "Internal server error",
  ORDER_SAVE_ERROR = "Something went wrong trying to change the order status",
  API_POSTCODE_SUBURB_VALIDATION_ERROR = "error_async_address_validation_address",
  POSTCODE_SUBURB_MATCH_ONLY = "state_nomatch_postcode_suburb",
  POSTCODE_STATE_MATCH_ONLY = "error_suburb_nomatch_postcode",
  STATE_SUBURB_MATCH_ONLY = "error_postcode_nomatch_state_suburb",
  POSTCODE_INVALID_TO_THE_STATE = "error_postcode_notmatch_state",
  POSTCODE_VAL_NO_SUBURB_MATCH = "error_postcode_nomatch_suburb",
  POSTCODE_VAL_NO_STATE_MATCH = "error_state_nomatch_suburb_or_postcode",
  POSTCODE_EXISTS_NOSUBURB_STATE = "error_postcode_valid_nomatch_suburb_andor_state",
  POSTCODE_NOTVALID_FOR_STATE = "error_postcode_notvalid_for_state",
}

export type TSort<T> = {
  key: keyof T;
  asc: boolean;
};

export interface IPostcode {
  suburb: string;
  postcode: string;
  state: string;
  id: string;
}
export interface IPostcodes extends Array<IPostcode> {}
export interface IPostcodesResponse {
  response: IPostcodes;
}

export interface IPostcodeSelection {
  label: string;
  value: string;
  object: IPostcode;
}

export const AusStates = [
  "unselected",
  "QLD",
  "TAS",
  "NSW",
  "VIC",
  "SA",
  "NT",
  "ACT",
  "WA",
] as const;

export interface ISelectOpt {
  label: string;
  value: string;
}

export interface ISelectOpts extends Array<ISelectOpt> {}

export type IControlCommonProps<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  invalid?: boolean;
  pattern?: string;
  rules?: UseControllerProps["rules"];
  label: string;
  stackProps?: StackProps;
};

export type IControlSelectProps = {
  type: "select";
  selectOpts: ISelectOpts;
  selectProps?: SelectProps;
  [x: string]: any;
};

export type IControlInputProps = {
  type: "text" | "textarea" | "checkbox";
  format?: (v: string) => string;
  parse?: (v: string) => string;
  showPlaceholder?: boolean;
  showCounter?: boolean;
  errorHandler?: (e: any) => string | JSX.Element;
  [x: string]: any;
};

export type IControllerPlus<T extends FieldValues> =
  | (IControlCommonProps<T> & IControlSelectProps)
  | (IControlCommonProps<T> &
      IControlInputProps & {
        inputProps?: Omit<InputProps, "type" | "ref" | "value">;
      });

const productCardPrefix = "product_card";
const productDescriptionPrefix = "product_description";

export const ProductCard_Elements_Id = {
  DiscountRibbon: `${productCardPrefix}_discount_ribbon`,
  TopSeller: `${productCardPrefix}_top_seller`,
  Favorite: `${productCardPrefix}_favorite`,
  Stock: `${productCardPrefix}_stock`,
  Name: `${productCardPrefix}_name`,
  Image: `${productCardPrefix}_image`,
  Price: `${productCardPrefix}_price`,
  NotifyMe: `${productCardPrefix}_notifyme`,
  NewBadge: `${productCardPrefix}_newbadge`,
  AddToCart: `${productCardPrefix}_add_to_cart`,
  WhatsApp: `${productCardPrefix}_whatsapp`,
  RedeemBadge: `${productCardPrefix}_redeem_badge`,
  MarketPlaceBadge: `${productCardPrefix}_marketplace_badge`,
  GotoOptions: `${productCardPrefix}_go_to_options`,
  PickupOnly: `${productCardPrefix}_pickup_only`,
};

export const ProductPage_Elements_Id = {
  DiscountRibbon: `${productDescriptionPrefix}_discount_ribbon`,
  TopSeller: `${productDescriptionPrefix}_top_seller`,
  Favorite: `${productDescriptionPrefix}_favorite`,
  Stock: `${productDescriptionPrefix}_stock`,
  Name: `${productDescriptionPrefix}_name`,
  Image: `${productDescriptionPrefix}_image`,
  Price: `${productDescriptionPrefix}_price`,
  NotifyMe: `${productDescriptionPrefix}_notifyme`,
  NewBadge: `${productDescriptionPrefix}_newbadge`,
  AddToCart: `${productDescriptionPrefix}_add_to_cart`,
  WhatsApp: `${productDescriptionPrefix}_whatsapp`,
  RedeemBadge: `${productDescriptionPrefix}_redeem_badge`,
  MarketPlaceBadge: `${productDescriptionPrefix}_marketplace_badge`,
  GotoOptions: `${productDescriptionPrefix}_go_to_options`,
  CheckAvailabitly: `${productDescriptionPrefix}_check_store_availability`,
  Categories: `${productDescriptionPrefix}_categories`,
  StoreName: `${productDescriptionPrefix}_store_name`,
  SkuIdFavElem: `${productDescriptionPrefix}_skuidfav_elem`,
};

export enum EApiUserControllers {
  ADDRESSES = "addresses",
  PRODUCTS = "products",
  PREVIEW = "preview",
}

export enum EApiUserControllersErrors {
  MISSING_PARAMETERS = "The ids parameters are missing",
}

export enum Enum_APIUserServices {
  PRODUCTS = "products",
}

export enum EAPIPreviewServices {
  PRODUCT = "product",
  SLIDE = "slides",
}

export enum Enum_TranslationApi_Route_Error {
  NO_COOKIE = "The session is expired please refresh your browser and try again",
  //** The decrypted cookie is the id of the translation order, if they don't match don't reveal the real reason. just throw error */
  COOKIE_UNMATCH = "Something went wrong",
  NON_EXISTANT_ORDER = "This order does not exists",
}

export enum Enum_Upload_Status {
  IDLE = "document_upload_upload_status_idle",
  UPLOAD = "document_upload_upload_status_upload",
  UPLOADING = "document_upload_upload_status_uploading",
  SENDING = "document_upload_upload_status_sending",
  FINISHED = "document_upload_upload_status_finished",
  FAILED = "document_upload_upload_status_failed",
}

export interface SendleAddress {
  address_line1: string;
  address_line2?: string;
  suburb: string;
  postcode: string;
  state_name?: string;
  country?: "Australia";
}

export interface CaptchaResponse {
  success: boolean;
  challenge_ts: string;
  "error-codes"?: ["missing-input-secret" | "invalid-input_response"];
  hostname: string;
}

export interface IHasuraClaims {
  "x-hasura-allowed-roles": string[];
  "x-hasura-default-role": string;
  "x-hasura-store-id": string;
}

export interface CognitoUser {
  username: string;
  pool: Pool;
  Session: null;
  client: Client;
  signInUserSession: SignInUserSession;
  authenticationFlowType: string;
  storage: Storage;
  keyPrefix: string;
  userDataKey: string;
  deviceKey: null;
  attributes: Attributes;
  preferredMFA: string;
}

export interface Attributes {
  sub: string;
  email_verified: boolean;
  name: string;
  phone_number_verified: boolean;
  phone_number: string;
  family_name: string;
  email: string;
}

export interface Client {
  endpoint: string;
  // fetchOptions: FetchOptions;
}

// export interface FetchOptions {}

export interface Pool {
  userPoolId: string;
  clientId: string;
  client: Client;
  advancedSecurityDataCollectionFlag: boolean;
  storage: Storage;
}

export interface Storage {
  cookies: Cookies;
  store: { [key: string]: string };
}

export interface Cookies {
  changeListeners: any[];
  HAS_DOCUMENT_COOKIE: boolean;
  cookies: { [key: string]: string };
}

export interface SignInUserSession {
  idToken: IDToken;
  refreshToken: RefreshToken;
  accessToken: AccessToken;
  clockDrift: number;
}

export interface AccessToken {
  jwtToken: string;
  payload: AccessTokenPayload;
}

export interface AccessTokenPayload {
  sub: string;
  "cognito:groups": string[];
  iss: string;
  client_id: string;
  origin_jti: string;
  event_id: string;
  token_use: string;
  scope: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  username: string;
}

export interface IDToken {
  jwtToken: string;
  payload: IDTokenPayload;
}

export interface IDTokenPayload {
  sub: string;
  "cognito:groups": string[];
  email_verified: boolean;
  "https://hasura.io/jwt/claims": string;
  iss: string;
  phone_number_verified: boolean;
  "cognito:username": string;
  origin_jti: string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: number;
  name: string;
  phone_number: string;
  exp: number;
  iat: number;
  family_name: string;
  jti: string;
  email: string;
}

export interface RefreshToken {
  token: string;
}
// Generated by https://quicktype.io

export interface CognitoSession {
  idToken: IDToken;
  refreshToken: RefreshToken;
  accessToken: AccessToken;
  clockDrift: number;
}

export interface AccessToken {
  jwtToken: string;
  payload: AccessTokenPayload;
}

export interface AccessTokenPayload {
  sub: string;
  "cognito:groups": string[];
  iss: string;
  client_id: string;
  event_id: string;
  token_use: string;
  scope: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  username: string;
}

export interface IDToken {
  jwtToken: string;
  payload: IDTokenPayload;
}

export interface IDTokenPayload {
  "custom:country": string;
  sub: string;
  "cognito:groups": string[];
  email_verified: boolean;
  "https://hasura.io/jwt/claims": string;
  iss: string;
  phone_number_verified: boolean;
  "cognito:username": string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: number;
  name: string;
  phone_number: string;
  exp: number;
  iat: number;
  family_name: string;
  email: string;
}

export interface RefreshToken {
  token: string;
}

export type THtmlBlock = {
  html: string;
  css: string;
  className: string;
};

export type IPageWithLayout = {
  getLayout?: (page: ReactNode) => JSX.Element;
};
