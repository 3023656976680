import { useTranslation } from "next-i18next";

import { AppComp } from "@/types/components";
import { chakra, FormControl, FormLabel, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

import type { CustomTypeOptions } from "react-i18next";
const TextInput = (props: AppComp.TextInputProps) => {
	const {
		leftElement,
		label,
		placeholder = "",
		type,
		formRegister,
		error,
		defaultValue = "",
		readOnly = false,
		tabIndex,
		disabled = false,
		autoFocus = false,
		id,
		setFocus,
		inputProps,
		...rest
	} = props;
	const LeftElement = leftElement;
	const { t } = useTranslation("common");
	return (
		<FormControl>
			<FormLabel
				mb={0}
				_hover={{ cursor: "pointer" }}
				fontWeight="bold"
				color="gray.600"
				fontSize="16px"
				w="full"
				htmlFor={id}
			>
				{label}
				<chakra.span
					fontSize={12}
					fontWeight="bold"
					color="tomato"
					lineHeight={1}
					display={error?.message ? "inline-block" : "none"}
				>
					&nbsp;{"* "}
					{t(error?.message as keyof CustomTypeOptions["resources"]["common"], {
						defaultValue: error?.message ?? "",
					})}
				</chakra.span>
			</FormLabel>
			<InputGroup>
				{LeftElement ? (
					<InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
						<LeftElement color="gray" />
					</InputLeftElement>
				) : null}
				<Input
					placeholder={placeholder}
					readOnly={readOnly}
					border="1px solid"
					borderColor="gray.400"
					fontSize="18px"
					bg="white"
					isInvalid={!!error}
					id={id}
					autoFocus={autoFocus}
					opacity={readOnly ? 0.4 : 1}
					isDisabled={disabled}
					variant="outline"
					defaultValue={defaultValue}
					type={type}
					layerStyle="selected"
					{...formRegister}
					{...rest}
					{...inputProps}
				/>
			</InputGroup>
		</FormControl>
	);
};

export default TextInput;
