import { RouteChangingCtx } from "@/providers/route-changing-provider";
import { logger } from "@/utils/helpers";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

// * If no route is provided, the loading state will trigger on every route change
// * If a route is provided, the loading state will only trigger on route changes to that route. Exiting that route will not trigger the loading state
export default function useRouteChanging(
  route?: string | RegExp,
  exceptions: (string | RegExp)[] = []
) {
  const ctx = React.useContext(RouteChangingCtx);
  if (!ctx)
    throw new Error(
      "useRouteChanging must be used within a RouteChangingProvider"
    );

  const { isChanging, route: currentRoute } = ctx;

  if (exceptions.length > 0) {
    const isException = exceptions.some((exception) => {
      if (exception instanceof RegExp) {
        return exception.test(currentRoute || "");
      }
      return currentRoute === exception;
    });
    if (isException) {
      return false;
    }
  }

  if (!route) {
    return isChanging;
  }
  if (route) {
    if (route instanceof RegExp) {
      return isChanging && route.test(currentRoute || "");
    }
    return isChanging && currentRoute === route;
  }
}
