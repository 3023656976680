import { useTranslation } from "react-i18next";

import useAppStore from "@/store/use-app";
import { Badge, BadgeProps } from "@chakra-ui/react";

export type InventoryStatus = "available" | "low stock" | "no stock";

export const InventoryBadge = (props: {
  badgeProps?: BadgeProps;
  in_store_only: boolean;
  status: InventoryStatus;
  [x: string]: any;
}) => {
  const { status, badgeProps, in_store_only = false, ...rest } = props;
  const { t } = useTranslation("common");
  const colors = {
    available: "addCartGreen2",
    "low stock": "yellow",
    "no stock": "red",
  };
  // const combinedStatus =
  if (in_store_only) return <InStoreOnlyBadge badgeProps={badgeProps} />;
  // if (status?.length)
  return (
    <Badge
      // rounded="/full"
      // px="2"
      // {...badgeProps}
      colorScheme={colors?.[status]}
      // {...rest}
    >
      {t(`inventory_status_${status}`, { defaultValue: status })}
    </Badge>
  );
  // return null;
};

export const InStoreOnlyBadge = (props: { badgeProps?: BadgeProps }) => {
  const mode = useAppStore((s) => s.purchase.mode);
  const { t } = useTranslation("common");
  if (mode === "PICKUP") return null;
  return (
    <Badge
      rounded="full"
      px="2"
      // fontSize={constants.badgeSize}
      colorScheme="teal"
      // w="auto"
      {...props.badgeProps}
    >
      {t("common_instoreonly", { defaultValue: "PICK-UP ONLY" })}
    </Badge>
  );
};
