import { createContext, ReactNode, useEffect, useState } from 'react';

import useAuth from '@/hooks/use-auth';
import useFetch from '@/hooks/use-fetch';

import flags from './flags.json';

type TFlags = Partial<Record<keyof typeof flags, boolean>> & Partial<Record<string, boolean>>;

export const FlagContext = createContext<TFlags>({});

const FlagFeatureProvider = ({ children }: { children: ReactNode }) => {
	const {
		state: { isLoggedIn },
	} = useAuth();
	const { value, loading } = useFetch<TFlags>("/api/features", {}, [isLoggedIn]);

	return <FlagContext.Provider value={value ?? {}}>{children}</FlagContext.Provider>;
};

export default FlagFeatureProvider;
