import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { IconType } from "react-icons/lib";
import { TiBatteryLow } from "react-icons/ti";
import { Sendle } from "sendle-node";

import { TBaseProductExtended } from "@/lib/static-props-data/types";
import { Icon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  chakra,
  Flex,
  GridItem,
  Heading,
  HStack,
  Image as ChakraImage,
  Spinner,
  StackProps,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  TextProps,
  Tr,
  useMediaQuery,
  useQuery,
  VStack,
} from "@chakra-ui/react";
import { parseWeight } from "common";

import { InventoryBadge, InventoryStatus } from "../InventoryBadge";
import { useLocalCtx } from "./context";
import { IProductDesc } from "./types";

export const SmallSpecId = (props: {
  name: string;
  keyTextProps?: TextProps;
  stackProps?: StackProps;
  value: string | number | ReactNode | JSX.Element;
  separator?: string;
}) => {
  const { name, value, separator = ":", keyTextProps, stackProps } = props;
  const fontSize = "12px";
  return (
    <HStack color="gray.500" {...props.stackProps}>
      <chakra.strong
        fontSize={fontSize}
        whiteSpace="nowrap"
        fontWeight="bold"
        color="gray.600"
        {...keyTextProps}
      >
        {name}
        {separator}
      </chakra.strong>
      {!React.isValidElement(value) ? (
        <Text
          fontSize={fontSize}
          fontWeight="bold"
          color="gray.600"
          fontFamily="Amazon Ember Lt"
        >
          {value}
        </Text>
      ) : (
        <div>{value}</div>
      )}
    </HStack>
  );
};

export const PaymentOptionsDisplay = () => {
  const { t } = useTranslation("product");
  return (
    <Box pos="relative" pt={6}>
      <Text
        left={2.5}
        bg="white"
        px={1}
        top={3}
        pos="absolute"
        fontSize="sm"
        fontWeight="bold"
        fontFamily="Amazon Ember Lt"
      >
        {t("product_payment_options", { defaultValue: "Payment options" })}
      </Text>
      <chakra.a
        href={"https://stripe.com/en-au"}
        target="_blank"
        rel="noreferrer"
        pos="absolute"
        right={3}
        top={3}
      >
        <Box bg="white" px={1}>
          <Center
            bg="brandStripe"
            p={1}
            rounded="md"
            boxSizing="content-box"
            px={2}
          >
            <ChakraImage
              src={`/static/payments/powered-by-stripe-white.svg`}
              h="20px"
            />
          </Center>
        </Box>
      </chakra.a>
      <HStack
        spacing={1}
        py={2}
        color="gray.200"
        border="1px"
        px={2.5}
        h="64px"
        overflow={"hidden"}
        sx={{ ">div": { w: "60px", borderWidth: "1px", rounded: "md" } }}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <Center>
          <ChakraImage src={`/static/payments/mastercard.svg`} h="40px" />
        </Center>
        <Center>
          <ChakraImage src={`/static/payments/visa.svg`} h="40px" />
        </Center>
        <Center>
          <ChakraImage src={`/static/payments/google-pay.svg`} h="40px" />
        </Center>
        <Center h="42px">
          <ChakraImage src={`/static/payments/apple-pay.svg`} h="22px" />
        </Center>
      </HStack>
    </Box>
  );
};

export const SummaryDescSpecs = (props: { product: TBaseProductExtended }) => {
  const { product, ...rest } = props;
  const { t } = useTranslation("product");
  const smallQuery = useQuery({ below: "md" });
  const [isSmall] = useMediaQuery([smallQuery]);

  const createMarkup = useMemo(() => {
    return { __html: product?.description ? product.description : "" };
  }, []);
  return (
    <Tabs size="sm" defaultIndex={!isSmall ? 2 : 0}>
      <TabList
        flexDirection={{ base: "column", xs: "row" }}
        sx={{ button: { _focus: { boxShadow: "none" } } }}
      >
        <Tab>{t("product_summary", { defaultValue: "Summary" })}</Tab>
        <Tab display={{ base: "block", md: "none" }}>
          {t("product_description", { defaultValue: "Description" })}
        </Tab>
        <Tab>
          {t("product_specification", { defaultValue: "Specification" })}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel as="p">{product.short_description}</TabPanel>
        <TabPanel display={{ base: "block", md: "none" }}>
          <div dangerouslySetInnerHTML={createMarkup}></div>
        </TabPanel>
        <TabPanel>
          <SpecTable
            specs={{
              ...product?.specs,
              weight: parseWeight(product?.weight ?? 0),
            }}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const SpecTable = (props: { specs: Record<string, string> }) => {
  return (
    <Table variant="striped" size={"sm"}>
      <Tbody>
        {Object.entries(props?.specs).map((entry) => {
          const [k, v] = entry;
          return (
            <Tr key={k}>
              <Td textTransform={"uppercase"}>{k}</Td>
              <Td>{v}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export const LongDescriptionBlock = (props: { [x: string]: any }) => {
  const { ...rest } = props;
  const {
    state: { data: product },
  } = useLocalCtx();
  const createMarkup = useMemo(() => {
    return { __html: product?.description ? product?.description : "" };
  }, [product]);

  return (
    <GridItem rowSpan={1} colSpan={12}>
      <Box pt={2} justifyContent="center" {...rest} mx={4}>
        <Heading
          as="h3"
          fontWeight="bold"
          color="gray.600"
          fontSize="lg"
          textDecor="underline"
          mb={2}
        >
          Description
        </Heading>
        <div dangerouslySetInnerHTML={createMarkup} />
      </Box>
    </GridItem>
  );
};

export const ModalTableAvailability2 = (props: {
  product: TBaseProductExtended;
}) => {
  const { product } = props;
  const { store } = product;
  return (
    <Flex flexWrap="wrap" gap={2} className="__">
      {product.phys_inventories.map((inv) => (
        <StoreAddressDisplay2
          key={inv.warehouse.id}
          inventory={{
            cf_status: inv?.cf_status ?? "low stock",
            warehouse: { ...inv.warehouse },
          }}
        />
      ))}
    </Flex>
  );
};

export const StoreAddressDisplay2 = (props: {
  inventory?: IProductDesc["phys_inventories"][0] | null;
  stackProps?: StackProps;
  footer?: JSX.Element;
  loading?: boolean;
  href?: string;
}) => {
  const { inventory, stackProps, loading = false } = props;
  const [height, setHeight] = useState("auto");
  // as { address: SendleAddress & { name: string } };
  const boxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setHeight(`${boxRef.current?.getBoundingClientRect().height}px` ?? "auto");
  }, [inventory]);

  return (
    <VStack
      color="gray.300"
      rounded="sm"
      border="1px"
      align="flex-start"
      p={2}
      m={1.5}
      spacing={0}
      w="15em"
      pos="relative"
      ref={boxRef}
      {...stackProps}
    >
      <Text fontWeight="bold" color="blackAlpha.900" textAlign="center">
        {inventory?.warehouse?.address?.name}
      </Text>
      <Text color="blackAlpha.900">
        {inventory?.warehouse.address?.address_line1}
      </Text>
      {inventory?.warehouse.address! && (
        <Text color="blackAlpha.900" textTransform="uppercase">
          {inventory?.warehouse.address?.suburb}{" "}
          {inventory.warehouse?.address?.state_name}{" "}
          {inventory?.warehouse?.address?.postcode}
        </Text>
      )}
      <InventoryBadge
        in_store_only={false}
        status={inventory?.cf_status as InventoryStatus}
      />
      {props?.footer}
      <Flex
        align="start"
        top={0}
        left={0}
        display={loading ? "flex" : "none"}
        h={height}
        bg="gray.50"
        pos="absolute"
        w={"15em"}
        pointerEvents="none"
        opacity={0.57}
      >
        <Spinner zIndex={10} alignSelf="center" mx="auto" color="gray.600" />
      </Flex>
    </VStack>
  );
};

export const ModalTableAvailability = (props: { product: IProductDesc }) => {
  const { product } = props;
  const { store } = product;
  return (
    <Flex flexWrap="wrap">
      {product.store.collect_locations.map((cl) => (
        <StoreAddressDisplay
          in_store_sell={product.store.in_store_sell}
          key={cl.name}
          location={cl}
          name={product.store.name}
        />
      ))}
    </Flex>
  );
};

type TStockStatus = "low stock" | "no stock" | "available";

export const AvailableCell = (props: { stock: TStockStatus }) => {
  const { stock } = props;
  let icon: IconType;
  let color: string = "green";
  let msg: string = "";
  switch (stock) {
    case "available":
      icon = BsFillCheckCircleFill;
      msg = "In stock";
      color = "green";
      break;
    case "low stock":
      color = "coral";
      msg = "Very low stock";
      icon = TiBatteryLow;
      break;
    case "no stock":
      color = "red";
      msg = "Not in stock";
      icon = AiFillCloseCircle;
      break;
    default:
      icon = AiFillCloseCircle;
  }
  return (
    <HStack align="center">
      <Icon as={icon} fontSize="md" color={color} />

      <Text color={color}>{msg}</Text>
    </HStack>
  );
};

export const StoreAddressDisplay = (props: {
  location?: IProductDesc["store"]["collect_locations"][0];
  name: string;
  in_store_sell: boolean;
}) => {
  const { location, name, in_store_sell } = props;
  const address = location?.address as Sendle.Address;
  return (
    <VStack
      color="gray.300"
      rounded="sm"
      border="1px"
      align="flex-start"
      p={2}
      m={1.5}
      spacing={0}
      w="15em"
    >
      <Text fontWeight="bold" color="blackAlpha.900" textAlign="center">
        {name} {location?.name}
      </Text>
      <Text color="blackAlpha.900">{address?.address_line1}</Text>
      {location?.address && (
        <Text color="blackAlpha.900" textTransform="uppercase">
          {address?.suburb} {address?.state_name} {address?.postcode}
        </Text>
      )}
      {/* <AvailableCell available={in_store_sell} /> */}
    </VStack>
  );
};
