import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { BsHandbag } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";

import { RenderIf } from "@/components/render-if";
import { MODE_BANNER_CLASS_NAME, MODE_BANNER_HEIGHT } from "@/config/constants";
import useAppStore from "@/store/use-app";
import {
  chakra,
  Flex,
  FlexProps,
  Heading,
  HStack,
  Icon,
} from "@chakra-ui/react";

import { ClientOnly } from "../client-only";

const ModeBanner = (props: { flexProps?: FlexProps }) => {
  const { t } = useTranslation("common");

  const { mode, sid, warehouse, name } = useAppStore((s) => s.purchase);
  const purchaseModeModal = useAppStore((s) => s.purchaseModeModal);
  const router = useRouter();
  const onClickHandler = () => {
    purchaseModeModal("open");
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("purchase_modal_up", () => {
        purchaseModeModal("open");
      });
    }
    return () => {
      window.removeEventListener("purchase_modal_up", () => {
        purchaseModeModal("open");
      });
    };
  }, []);

  return (
    <Flex
      w="full"
      className={MODE_BANNER_CLASS_NAME}
      h={`${MODE_BANNER_HEIGHT}px`}
      onClick={() => {
        useAppStore.getState().purchaseModeModal;
      }}
      bgGradient="linear(to-t, gray.200, white)"
      {...props.flexProps}
    >
      <HStack
        align={"center"}
        pr={4}
        pl={2}
        pos="relative"
        sx={{ "h1, div": { fontSize: { base: "xs", md: "md" } } }}
      >
        <RenderIf clientOnly>
          <Icon
            color="gray.700"
            as={mode === "DELIVERY" ? TbTruckDelivery : BsHandbag}
          />
        </RenderIf>
        <Heading as="h1" color="gray.500" textTransform={"uppercase"}>
          {t("purchase_mode_select_option", { defaultValue: "Select Option" })}
          <RenderIf clientOnly>
            <chakra.span
              ml={1}
              color="brandOrange2.500"
              textDecor="underline"
              cursor="pointer"
              role="button"
              as="button"
              id="purchase-mode-select-option"
              onClick={onClickHandler}
              lineHeight={1}
            >
              {t(`purchase_mode_${mode}` as "purchase_mode_PICKUP", {
                defaultValue: "",
              })}
            </chakra.span>
          </RenderIf>
        </Heading>
        <ClientOnly>
          <Heading
            color="gray.700"
            fontWeight="semibold"
            as="h1"
            display={mode === "PICKUP" ? "block" : "none"}
          >
            {name}
          </Heading>
        </ClientOnly>
      </HStack>
    </Flex>
  );
  return null;
  // return <Flex w="full" h={`${MODE_BANNER_HEIGHT}px`} visibility="hidden"></Flex>;
};

export default ModeBanner;
