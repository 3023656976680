import { Trans, useTranslation } from "next-i18next";
import { NextRouter, useRouter } from "next/router";
import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";

import OpenHoursDisplay from "@/components/open-hours-display";
import { SHOWSEARCHRX } from "@/config/constants";
import useAuth from "@/hooks/use-auth";
import { trpc } from "@/lib/api/trpc/utils/trpc";
import useAppStore from "@/store/use-app";
import useCartGlobalStore from "@/store/use-cart";
import { Cart } from "@/types/cart-state";
import { logger } from "@/utils/helpers";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  chakra,
  Collapse,
  Heading,
  Skeleton,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { urlSlugify } from "common";

import { ChakraNextLink, ChakraNextLinkButton } from "../chakra-links";
import { GenericModal } from "../generic-modal";
import { StoreAddressDisplay2 } from "../product-description-page.component/partials";
import { RenderIf } from "../render-if";

const checkIconButton = { rightIcon: <CheckIcon color="green" /> };

const PurchaseModeModal = () => {
  const buttonSize = useBreakpointValue({ base: "sm", md: "lg" });
  const [selectionInProgress, setSelectionInProgress] = useState<string | null>(
    null
  );
  const mountCount = useRef(0);
  const sid = useAppStore((s) => s.purchase.sid) ?? "";
  const {
    state: { isLoggedIn },
    logout,
  } = useAuth();
  const {
    PURCHASE_MODE_MODAL: { open: isOpen, callback, message },
    purchaseModeModal,
    purchase,
    setProxyState,
  } = useAppStore((state) => state);
  const { t } = useTranslation("common");

  const { items, clearCart, setCartItems } = useCartGlobalStore(
    (state) => state
  );
  const router = useRouter();

  const [_, __, afterPath] = router.asPath.match(SHOWSEARCHRX) ?? [];

  const { data, isLoading } = trpc.user.getWarehouses.useQuery();
  const { mutate: swapCartMutate, isLoading: swapCartLoading } =
    trpc.checkout.swapCart.useMutation({
      onSuccess(data, variables, context) {
        setCartItems(data.items as Cart.Item[]);
      },
      onSettled: () => {
        setSelectionInProgress(null);
      },
    });
  const { mutate, isLoading: purchaseModeLoading } =
    trpc.user.setPurchaseMode.useMutation({
      onSuccess: (data) => {
        cleanModalSuccess(
          data.mode as "DELIVERY" | "PICKUP",
          data?.warehouse_id ?? "",
          data.sid,
          router
        );
        const cartItems = useCartGlobalStore.getState().items;
        if (cartItems.length)
          swapCartMutate({
            items: cartItems,
            sid: data.mode === "DELIVERY" ? "web" : `_p${data.sid}`,
          });
      },
      onError: (error) => {
        logger.log("PurchaseModeModal ~ error", error);
        // * Pending this does not work as expected. SSR throws an unauthenticated error for no reason even when the cookie is set
        // if (error.message === ECognitoError.NOT_AUTHENTICATED) {
        // 	logout();
        // }
      },
      onSettled: () => {
        setSelectionInProgress(null);
      },
    });
  const [warehouseChoiceOpen, setWarehouseChoiceOpen] = useState(false);
  const [warehouseChoice, setWarehouseChoice] = useState(purchase.warehouse);

  const cleanModalSuccess = useCallback(
    (
      mode: "PICKUP" | "DELIVERY",
      warehouse_id: string | null,
      sid: number,
      nextRouter: NextRouter
    ) => {
      setWarehouseChoiceOpen(false);

      purchaseModeModal("close");
    },
    []
  );

  const onWarehouseChoiceClick = useCallback(
    (id: string, sid: number, name: string) =>
      (e: MouseEvent<HTMLDivElement>) => {
        setWarehouseChoice(id);
        setProxyState((state) => {
          state.purchase.mode = "PICKUP";
          if (id) {
            state.purchase.warehouse = id;
            state.purchase.sid = `_p${sid}`;
            state.purchase.name = name;
          }
        }, `PURCHASE_MODE_PICKUP`);
        setSelectionInProgress(id);
        mutate({
          mode: "PICKUP",
          warehouse_id: id,
          anonymous: !isLoggedIn,
          sid,
        });
      },
    [isLoggedIn]
  );

  const onDeliveryChoiceClick = useCallback(
    (id: string) => () => {
      setSelectionInProgress(id);
      setProxyState((state) => {
        state.purchase.mode = "DELIVERY";
        state.purchase.warehouse = "web";
        state.purchase.sid = "web";
        state.purchase.name = "DELIVERY";
        // if (warehouse) state.purchase.warehouse = warehouse;
      }, `PURCHASE_MODE_DELIVERY`);
      mutate({
        mode: "DELIVERY",
        warehouse_id: id,
        anonymous: !isLoggedIn,
        sid: 0,
      });
    },
    [isLoggedIn]
  );

  useEffect(() => {
    return () => {
      setWarehouseChoiceOpen(false);
    };
  }, []);

  useEffect(() => {
    mountCount.current++;
  }, []);

  useEffect(() => {
    if (mountCount.current === 1) {
      setWarehouseChoice(purchase.warehouse);
    }
  }, [purchase]);

  const closeHandler = useCallback(() => {
    if (purchase.mode) {
      purchaseModeModal("close");
    }
    if (!purchase.mode) {
      router.push("/");
      setTimeout(() => {
        purchaseModeModal("close");
      }, 1000);
    }
  }, [purchase]);

  useEffect(() => {
    if (!!message) {
      setWarehouseChoiceOpen(true);
    }
  }, [message]);

  return (
    <GenericModal
      title={
        <Heading color="gray.800" textAlign={"center"}>
          <Trans
            i18nKey={"purchase_select_mode"}
            defaultValue={"Select mode"}
            ns="common"
          >
            <chakra.span color="gray.500"></chakra.span>
          </Trans>
        </Heading>
      }
      isOpen={isOpen}
      modalProps={{ size: "lg", closeOnOverlayClick: false }}
      body={
        <VStack sx={{ ".chakra-button": { width: "248px" } }}>
          <Button
            size={buttonSize}
            colorScheme="brandOrange2"
            onClick={(e) => {
              setWarehouseChoiceOpen(true);
            }}
            {...(purchase.mode === "PICKUP" && checkIconButton)}
          >
            {t(`purchase_mode_PICKUP`, { defaultValue: "PICKUP" })}
          </Button>
          <Collapse in={warehouseChoiceOpen} unmountOnExit>
            <RenderIf
              condition={!isLoading}
              other={
                <VStack spacing={2}>
                  {[0, 1].map((el) => (
                    <Skeleton key={el} height="100px" w="248px" />
                  ))}
                </VStack>
              }
            >
              <Heading
                color="gray.600"
                textAlign={"left"}
                fontSize="xs"
                w="240px"
                mx={3}
              >
                {message ? ` * ${message}` : null}
              </Heading>
              <Box textAlign={"center"}>
                {!data?.warehouses.length
                  ? "Not available at the moment"
                  : t("purchase_mode_select_store", {
                      defaultValue: "Select the store",
                    })}
              </Box>
              {data?.warehouses.map((ware) => {
                return (
                  <ChakraNextLink
                    key={ware.id}
                    href={getRoute(router, `_p${ware.sid}`, "PICKUP")[0]}
                    textDecor="none"
                    scroll={false}
                    id={`select_warehouse_${urlSlugify(ware?.name)}`}
                    shallow={
                      router.pathname === `/[wcode]/search` ? true : undefined
                    }
                  >
                    <StoreAddressDisplay2
                      inventory={{
                        cf_status: "low stock",
                        warehouse: { ...ware, name: ware.address.name },
                      }}
                      stackProps={{
                        sx: { span: { display: "none" } },
                        m: "4",
                        _hover: { bg: "gray.50" },
                        cursor: "pointer",
                        onClick: onWarehouseChoiceClick(
                          ware.id,
                          ware.sid,
                          ware.address.name
                        ),
                        ...(warehouseChoice === ware.id &&
                        purchase.mode === "PICKUP"
                          ? {
                              ring: "3px",
                              ringColor: "blue.300",
                              ringOffset: "1px",
                              ringOffsetColor: "blue.200",
                            }
                          : {}),
                      }}
                      footer={
                        <OpenHoursDisplay
                          stackProps={{ color: "gray.600", fontSize: "xs" }}
                          hours={ware.opening_hours}
                        />
                      }
                      loading={selectionInProgress === ware.id}
                    />
                  </ChakraNextLink>
                );
              })}
            </RenderIf>
          </Collapse>
          <ChakraNextLinkButton
            size={buttonSize}
            shallow={router.pathname === `/[wcode]/search` ? true : undefined}
            isLoading={selectionInProgress === "web"}
            href={getRoute(router, "web", "DELIVERY")[0]}
            scroll={false}
            colorScheme="brandOrange2"
            onClick={onDeliveryChoiceClick("web")}
            {...(purchase.mode === "DELIVERY" && checkIconButton)}
          >
            {t("purchase_mode_DELIVERY", { defaultValue: "DELIVERY" })}
          </ChakraNextLinkButton>

          {/* <chakra.small fontStyle={"italic"}>
						{t("purchase_mode_clear_cart", {
							defaultValue: "* Changing your selection will clear your cart",
						})}
					</chakra.small> */}
        </VStack>
      }
      onClose={closeHandler}
    />
  );
};

export default PurchaseModeModal;

const getRoute = (
  router: NextRouter,
  sid: number | string,
  mode: "PICKUP" | "DELIVERY"
) => {
  const { pathname, query, asPath } = router;
  const [_, __, afterPath] = asPath.match(SHOWSEARCHRX) ?? [];

  const data = `getRoute ~ SHOWSEARCHRX" ${afterPath}, asPath ${asPath} `;
  if (pathname === "/" || pathname === "/search-internal")
    return [pathname, data];
  // if (mode === "DELIVERY" && pathname === "/") return "/";
  if (mode === "DELIVERY")
    return [`/web/${asPath.replace(/^(\/_p\d{1,}\/|\/web\/)/, "")}`, data];
  return [`/${sid}/${asPath.replace(/^(\/\_p\d{1,}\/|\/web\/)/, "")}`, data];
};
