import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { FiMinus, FiPlus, FiShoppingCart, FiTrash } from "react-icons/fi";

import useAppStore from "@/store/use-app";
import useCartGlobalStore from "@/store/use-cart";
import { Cart } from "@/types/cart-state";
import { AppComp } from "@/types/components";
import {
  Box,
  Button,
  Center,
  Circle,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image as ChakraImage,
  Stack,
  Text,
  useMediaQuery,
  useQuery,
  VStack,
} from "@chakra-ui/react";

import { ChakraNextLink } from "../chakra-links";
import { InStoreOnlyBadge } from "../InventoryBadge";
import KeyValueDisplay from "../key-value-display.component";
import useCartCtx from "./context";

type CartItemProps = {
  data: Cart.Item;
  disableControls?: boolean;
  [x: string]: any;
};

const shippingRate = 12;

const CartContent = () => {
  const small = useQuery({ below: "md" });
  const small2 = useQuery({ below: "sm" });
  const [isSmallDevice] = useMediaQuery(small);
  const [isSmaller] = useMediaQuery(small2);
  const [deleting, setDeleting] = useState(false);
  const { items, removeItem } = useCartGlobalStore();

  return (
    <Flex direction="column">
      {items.map((item) => (
        <CartItem key={item.inventory_id} data={item} />
      ))}
    </Flex>
  );
};

const ItemPriceDisplay = (props: AppComp.GenericProps) => {
  const { text, ...rest } = props;
  const small = useQuery({ below: "md" });
  const [isSmallDevice] = useMediaQuery(small);
  return (
    <Text textAlign="right" whiteSpace="nowrap" {...rest}>
      {!isSmallDevice && "AU"} {"$"}
      {text}
    </Text>
  );
};

export const CartHeader = () => {
  const { t } = useTranslation("common");
  return (
    <Flex pb={4}>
      <Flex flexGrow={14} justifyContent="center">
        ITEM
      </Flex>
      <Flex flexGrow={1} justifyContent="flex-end">
        {t("common_price", { defaultValue: "Price" })}
      </Flex>
    </Flex>
  );
};

export const CartMenu = ({}) => {
  const { totalCartItems } = useCartGlobalStore();
  const sid = useAppStore((s) => s.purchase.sid);
  const router = useRouter();
  return (
    <Flex paddingX={0}>
      <Link
        href={{
          pathname: `/${!sid ? "web" : sid}/cart`,
          query: { from: router.asPath },
        }}
        passHref
      >
        <Flex position="relative" as="button">
          <Icon color="brandOrange" w={8} h={8} as={FiShoppingCart} />
          {totalCartItems >= 1 ? (
            <Circle
              bg="gray.200"
              size="18px"
              position="absolute"
              top={-2}
              left={5}
            >
              <Text fontSize="14px" color="gray.500">
                {totalCartItems}
              </Text>
            </Circle>
          ) : null}
        </Flex>
      </Link>
    </Flex>
  );
};

export const CartQtyControls = (props: CartItemProps) => {
  const { data, disableControls = false, ...rest } = props;
  const { items, addItem, decreaseItemQty } = useCartGlobalStore(
    (state) => state
  );

  const liveItem = items.find(
    (el) => el.inventory_id === props.data.inventory_id
  ) as Cart.Item;

  const areBntsDisabled = !props.data.inventory_id.length || disableControls;

  return (
    <Flex
      rounded="lg"
      direction="row"
      alignContent="center"
      alignItems="center"
      borderStyle="solid"
      borderWidth="1px"
      height="24px"
      width="80px"
      borderColor="gray.300"
      boxShadow="md"
      {...rest}
      justifyContent="center"
    >
      <IconButton
        aria-label="remove"
        mx={1}
        bg="white"
        size="xss"
        fontSize="12px"
        color="gray.600"
        isDisabled={areBntsDisabled}
        icon={liveItem?.quantity === 1 ? <FiTrash /> : <FiMinus />}
        onClick={() => decreaseItemQty(props.data.product)}
      />
      <Text px={2} textAlign="center" fontSize="xs" color="gray.700">
        {liveItem?.quantity}
      </Text>
      <IconButton
        aria-label="add"
        fontSize="12px"
        bg="white"
        size="xss"
        mx={1}
        // If product doesnt have inventory entry for purchase mode this can happen
        // isDisabled={!payload.product_id}
        isDisabled={areBntsDisabled}
        icon={<FiPlus />}
        color="gray.600"
        onClick={() => addItem(props.data.product, false)}
      />
    </Flex>
  );
};

export const CartImage = (props: CartItemProps) => {
  const { data, ...rest } = props;
  return (
    <Flex
      border="1px solid"
      p={1}
      borderColor="gray.200"
      borderRadius={5}
      {...rest}
    >
      <ChakraImage
        alt=""
        src={data.product?.image ? data.product?.image : ""}
      ></ChakraImage>
    </Flex>
  );
};
export const PriceDisplay = (props: AppComp.GenericProps) => {
  return (
    <HStack spacing={1} justifyContent="space-between">
      <Text
        fontSize={props.fontSize && props.fontSize}
        fontWeight={props.fontWeight && props.fontWeight}
        textDecoration={props.textDecoration && props.textDecoration}
        color={props.color && props.color}
      >
        {props.currency}
      </Text>
      <Text
        fontSize={props.fontSize && props.fontSize}
        fontWeight={props.fontWeight && props.fontWeight}
        textDecoration={props.textDecoration && props.textDecoration}
        color={props.color && props.color}
      >
        {props.prefix}
        {props.children}
      </Text>
    </HStack>
  );
};

//  2 - 4 - 3 - 3

export const CartItem = (props: AppComp.CartItemProps) => {
  const { data: item } = props;

  const { t } = useTranslation(["common", "checkout"]);

  const { removeItem } = useCartGlobalStore();

  const {
    state: { loading },
  } = useCartCtx();

  return (
    <VStack
      alignContent="flex-start"
      justifyItems="flex-start"
      alignItems="flex-start"
      fontSize={{ base: "md", smm2: "lg" }}
    >
      <HStack justify="space-between" width="100%" align="stretch">
        <Center py={2}>
          <CartImage
            display={{ base: "none", xs: "block" }}
            w={24}
            data={item as Cart.Item}
          />
        </Center>
        <VStack
          py={0}
          justifyContent="space-around"
          width="100%"
          height="100%"
          spacing={{ base: "0.5", md: "unset" }}
          alignItems="flex-start"
          justifyItems="flex-start"
          alignContent="flex-start"
        >
          <Center py={2}>
            <CartImage
              display={{ base: "block", xs: "none" }}
              w={12}
              data={item as Cart.Item}
            />
          </Center>
          <ChakraNextLink href={item.product.url}>
            {item.product.name}
          </ChakraNextLink>
          <Stack
            direction={{ base: "column", md: "row" }}
            w="full"
            align={{ base: "flex-start", md: "center" }}
            justify="flex-start"
          >
            <HStack>
              <Box fontSize="xs" color="gray.700" fontWeight={"semibold"}>
                by {item.product.store.name}
              </Box>
              <Flex gap={2}>
                <KeyValueDisplay object={item.product.variant} />{" "}
              </Flex>
            </HStack>
            {item.product.in_store_only ? <InStoreOnlyBadge /> : null}
          </Stack>
          <Flex
            justifyContent="flex-start"
            width="100%"
            direction={"row"}
            align="center"
            flexWrap={"wrap"}
            gap={4}
          >
            <Flex justifyContent="space-between">
              <CartQtyControls
                data={item}
                disableControls={["verifying", "initial"].includes(loading)}
              />
            </Flex>

            <Button
              variant="soft"
              size="xs"
              flexShrink={0}
              isDisabled={["verifying", "initial"].includes(loading)}
              onClick={async () => {
                removeItem(item);
              }}
            >
              {t("common:common_cart_delete", {
                defaultValue: "Delete",
                ns: "common",
              })}
            </Button>
            <Text color="error" flex={`0 1 ${item.adjust ? "70%" : "auto"}`}>
              {t(`${item.adjust}` as "checkout:button_continue", {
                defaultValue: "",
                ns: "checkout",
              })}
            </Text>

            <Text
              whiteSpace={"nowrap"}
              // w="full"
              textAlign={"end"}
              color="gray.700"
              opacity={0.95}
              fontWeight={"bold"}
              flex={"1 0 60px"}
            >
              ${(item.quantity * item.product.price).toFixed(2)}
            </Text>
          </Flex>
        </VStack>
      </HStack>
      <Divider bg="gray.200" />
    </VStack>
  );
};

export default CartContent;
