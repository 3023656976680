import { ChakraNextLink } from "@/components/chakra-links";
import { RenderIf } from "@/components/render-if";
import { definedRoutes } from "@/config/routes";
import useApp from "@/store/use-app";
import { Flex, HStack, Image as ChakraImage, Slide, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

const SubtleNotifications = () => {
	const [documentState, setDocumentState] = useState<Document | null>(null);
	const notification = useApp((state) => state.subtleNotification);
	const currentFavAdded = useRef<string | null>(null);

	useEffect(() => {
		if (document) setDocumentState(document);
	}, []);
	let notificationTimeout: NodeJS.Timeout;

	useEffect(() => {
		if (notification) {
			if (currentFavAdded.current !== notification.image) {
				clearTimeout(notificationTimeout);
			}
			notificationTimeout = setTimeout(() => notification.clearCb(), 6000);
			currentFavAdded.current = notification.image;
		}
		return () => {
			if (notificationTimeout) clearTimeout(notificationTimeout);
		};
	}, [notification]);

	if (documentState) {
		return ReactDOM.createPortal(
			<div id="fav-notification-portal">
				<Slide direction="bottom" in={!!notification} style={{ zIndex: 2000 }}>
					<Flex w="full" justify={"end"} pr={3} pb={6}>
						<HStack
							rounded="md"
							w="300px"
							// zIndex={100}
							h="80px"
							justifySelf={"end"}
							borderWidth="1px"
							p={1.5}
							align="center"
							bg="white"
						>
							<ChakraImage src={notification?.image} maxH="3.5rem" borderWidth={"1px"} />
							<VStack align={"start"}>
								<Text fontSize={"sm"} color="gray.600">
									{notification?.title}
								</Text>
								<RenderIf condition={notification?.type === "stock"}>
									<ChakraNextLink href={`${definedRoutes.notifications}`}>
										{notification?.caption}
									</ChakraNextLink>
								</RenderIf>
								<RenderIf condition={notification?.type === "favorite"}>
									<ChakraNextLink href={`${definedRoutes.favorites}`}>
										{notification?.caption}
									</ChakraNextLink>
								</RenderIf>
							</VStack>
						</HStack>
					</Flex>
				</Slide>
			</div>,

			documentState.body
		);
	}

	return null;
};

export default SubtleNotifications;

const MotionStack = motion(VStack);
