import useApp from "@/store/use-app";
import { convertSid } from "@/utils/helpers";

import useEffectOnce from "./use-effect-once";
import { trpc } from "@/lib/api/trpc/utils/trpc";

const useSetPurchaseMode = () => {
  const { mode, sid, warehouse } = useApp((s) => s.purchase);
  // const { mutate } = trpc.useMutation("user.setPurchaseMode");
  const { mutate } = trpc.user.setPurchaseMode.useMutation();

  convertSid(sid ?? "");
  useEffectOnce(() =>
    mutate({
      warehouse_id: warehouse ?? "web",
      sid: convertSid(sid ?? ""),
      mode: mode ?? "DELIVERY",
    })
  );
};

export default useSetPurchaseMode;
// Language: typescript
