import produce, { Draft } from "immer";
// import { WritableDraft } from 'immer/dist/internal';
import lodashSet from "lodash/set";
import { GetState, SetState } from "zustand";

import { AppState, ICartNotification, NestedKeyLeaves } from "@/types/app-state";
import { generateRandomString, logger } from "@/utils/helpers";

import type { WritableDraft } from "immer/dist/internal.js";

export const productCardModal = (
	set: SetState<AppState.Store>,
	get: GetState<AppState.Store>,
	action: "open" | "close" | "toggle",
	callback?: (() => void) | null,
	data?: any
) => {
	const current = get().LOGIN_FORM_MODAL.open;
	const newState = action === "toggle" ? !current : action === "open" ? true : false;

	set(
		(state) =>
			produce(state, (draft) => {
				draft.PRODUCT_CARD_MODAL.open = newState;
				if (false === newState) {
					draft.PRODUCT_CARD_MODAL.callback = null;
				} else {
					draft.PRODUCT_CARD_MODAL.callback = callback;
					draft.PRODUCT_CARD_MODAL.data = data;
				}
			}),
		false,
		//@ts-ignore
		`productCardModalControl-${action}`
	);
};

export type TImmerSet = (
	partial: AppState.Store | ((draft: WritableDraft<AppState.Store>) => void),
	replace: boolean,
	prefix: string
) => void;

export const setProxyState = (set: TImmerSet, fn: (draft: Draft<AppState.State>) => void, prefix?: string) => {
	set(
		(state) => fn(state),

		false,
		// @ts-ignore
		`_PROXY_SET${prefix ? "_" + prefix : "_DEFAULT"}`
	);
};

export const loginFormModal = (
	set: SetState<AppState.Store>,
	get: GetState<AppState.Store>,
	action: "open" | "close" | "toggle",
	callback: (() => void) | null
) => {
	const current = get().LOGIN_FORM_MODAL.open;
	const newState = action === "toggle" ? !current : action === "open" ? true : false;

	set(
		(state) =>
			produce(state, (draft) => {
				draft.LOGIN_FORM_MODAL.open = newState;
				if (false === newState) {
					draft.LOGIN_FORM_MODAL.callback = null;
				} else {
					draft.LOGIN_FORM_MODAL.callback = callback;
				}
			}),
		false,
		//@ts-ignore
		`loginFormModalControl-${action}`
	);
};

export const addOrRemoveStockNotify = (
	set: SetState<AppState.Store>,
	get: GetState<AppState.Store>,
	inventory_id: string
) => {
	logger.log("inventory_id", inventory_id);
	const oldItems = get().stockNotify.items;
	const items = oldItems.includes(inventory_id)
		? oldItems.filter((item) => item !== inventory_id)
		: [...oldItems, inventory_id];

	set(
		(state) => ({ ...state, stockNotify: { items, loaded: true } }),
		false,
		// @ts-ignore
		`addOrRemoveStockNotify`
	);
};

export const purchaseModeModal = (
	set: SetState<AppState.Store>,
	get: GetState<AppState.Store>,
	action: "open" | "close" | "toggle",
	callback: (() => void) | null,
	message: string | null
) => {
	const current = get().LOGIN_FORM_MODAL.open;
	const newState = action === "toggle" ? !current : action === "open" ? true : false;

	set(
		(state) =>
			produce(state, (draft) => {
				draft.PURCHASE_MODE_MODAL.open = newState;
				if (false === newState) {
					draft.PURCHASE_MODE_MODAL.callback = null;
					draft.PURCHASE_MODE_MODAL.message = null;
				} else {
					draft.PURCHASE_MODE_MODAL.callback = callback;
					draft.PURCHASE_MODE_MODAL.message = message;
				}
			}),
		false,
		//@ts-ignore
		`purchaseModeModalControl-${action}`
	);
};

export const addNotification = (set: SetState<AppState.Store>, notification: ICartNotification) => {
	const id = generateRandomString(7);
	set(
		(state) =>
			produce(state, (draft) => {
				// @ts-ignore
				draft.notifications[id] = { ...notification, id };
			}),
		false,
		//@ts-ignore
		"addNotification"
	);
};

export const deleteNotification = (set: SetState<AppState.Store>, id: string) => {
	set(
		(state) =>
			produce(state, (draft) => {
				// @ts-ignore
				if (draft.notifications[id]) delete draft.notifications[id];
			}),
		false,
		//@ts-ignore
		"deleteNotification"
	);
};

export const cleanAllNotifications = (set: SetState<AppState.Store>) => {
	set(
		(state) =>
			produce(state, (draft) => {
				// @ts-ignore
				draft.notifications = {};
			}),
		false,
		//@ts-ignore
		"clearAllNotifications"
	);
};

export const toggleDevelopBanner = (set: SetState<AppState.Store>) => {
	set(
		(state) =>
			produce(state, (draft) => {
				draft.showDevelopBanner = !state.showDevelopBanner;
			}),
		false,
		//@ts-ignore
		"setAnyKey"
	);
};

export const setAnyKey = (set: SetState<AppState.Store>, key: NestedKeyLeaves, value: any) => {
	const levels = key.split(".");
	set(
		(state) =>
			produce(state, (draft) => {
				lodashSet(draft, key, value);
			}),
		false,
		//@ts-ignore
		"setAnyKey"
	);
};

export const setSelectedCategory = (set: SetState<AppState.Store>, val: string | null) => {
	set(
		(state) => ({ selectedCategory: val }),
		false,
		//@ts-ignore
		"setCategory"
	);
};

export const setSearching = (set: SetState<AppState.Store>, val: boolean) => {
	set(
		(state) => ({ searching: val }),
		false,
		//@ts-ignore
		"setSearching"
	);
};

export const sendSubtleNotification = (
	set: SetState<AppState.Store>,
	{ image, title, caption, type }: { image: string; title?: string; caption?: string; type: "stock" | "favorite" }
) => {
	const clearCb = () =>
		set(
			(state) => ({ ...state, subtleNotification: null }),
			false,
			// @ts-ignore
			"clearNotification"
		);

	set(
		(state) => ({ ...state, subtleNotification: { image, title, caption, clearCb, type } }),
		false,
		//@ts-ignore
		"sendSubtleNotification"
	);
};

export const setFavorites = (set: SetState<AppState.Store>, favs: number[]) => {
	set(
		(state) => ({
			favorites: favs,
		}),
		false,
		//@ts-ignore
		"setFavorites"
	);
};
