import { NextRouter } from "next/router";
import { ReactNode } from "react";

import AppBar from "@/components/appbar";
import { Footer } from "@/components/footer";
import useSyncCart from "@/hooks/use-sync-cart";
import { Flex } from "@chakra-ui/react";

import RouteLoadingOverlay from "../route-change-overlay";

export const MainLayout = (props: {
  router: NextRouter;
  children: ReactNode;
  showFooter?: boolean;
  last_update?: string;
  next_update?: string;
}) => {
  const { router, showFooter = false } = props;

  useSyncCart({ router });

  return (
    <>
      <AppBar />
      <RouteLoadingOverlay exceptions={[/\/my-account/]} />
      <Flex
        h="full"
        w="full"
        align={"center"}
        mx="auto"
        direction="column"
        maxW="1440px"
        bg="mainbg"
        overflow={"auto"}
        minH="100vh"
        px={2}
        as="main"
      >
        {props.children}
      </Flex>
      <Footer last_update={props.last_update} next_update={props.next_update} />
    </>
  );
};
