// import { GraphQLResolveInfo } from "graphql";

import { EnumCheckoutOrderType } from "./shared";

// import {
// 	Pickup_Location,
// 	Pickup_Location_Enum,
// } from "@/graphql/__generated__/client-side";

export declare namespace Checkout {
  type OrderType = EnumCheckoutOrderType;

  interface CheckoutSessionReqParams {
    order_type: OrderType;
    pickup_location: string;
  }
}

// There strings represent translation keys
export enum ShippingModification_Enum {
  LIMITED_STOCK = "cart_mod_LIMITED_STOCK",
  NO_STOCK = "cart_mod_NO_STOCK",
  MOD_DUE_STOCK = "cart_mod_MOD_DUE_STOCK",
  DELIVERY_QUOTE_SERVICE = "cart_mod_DELIVERY_QUOTE_SERVICE",
  NO_STOCK_QUOTE = "cart_mod_NO_STOCK_QUOTE",
  NOT_QUOTABLE = "cart_mod_NOT_QUOTABLE",
  NOT_PURCHASABLE = "cart_mod_NOT_PURCHASABLE",
  UNKOWN_WEIGHT = "cart_mod_UNKOWN_WEIGHT",
  NOT_SERVICEABLE = "cart_mod_NOT_SERVICEABLE",
  IN_STORE_ONLY = "cart_mod_IN_STORE_ONLY",
  NOT_PICKUP_ = "cart_mod_NOT_PICKUP",
  NOT_AVAILABLE_WAREHOUSE = "cart_mod_NOT_AVAILABLE_WAREHOUSE",
}
