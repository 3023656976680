import { parse } from 'graphql';

import { Auth } from '@aws-amplify/auth';

export function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
	const doc = parse(query);
	let operationName = "Unknown";
	if (doc.definitions.length > 0 && doc.definitions[0].kind === "OperationDefinition")
		operationName = doc.definitions[0].name?.value || "Unknown";
	return async (): Promise<TData> => {
		const res = await fetch(<string>process.env.NEXT_PUBLIC_HASURA_URL, {
			method: "POST",
			body: JSON.stringify({ query, variables, operationName }),
			headers: {
				Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
			},
		});

		const json = await res.json();

		if (json.errors) {
			const { message } = json.errors[0];

			throw new Error(message);
		}

		return json.data;
	};
}
