import { ChakraNextLink } from "@/components/chakra-links";
import { Flex, FlexProps, Image } from "@chakra-ui/react";

const CorpLogo = (props: { size?: string; flexProps?: FlexProps }) => {
	const { size = "2.5rem", flexProps } = props;
	return (
		<Flex {...flexProps}>
			<ChakraNextLink href="/" passHref pos="relative" _focus={{ boxShadow: "none" }}>
				{/* Is svg */}
				{/* eslint-disable-next-line @next/next/no-img-element   */}
				<img
					height={size}
					width={size}
					src="/LOGO.svg"
					alt="logo of latinshop.com.au"
					onLoad={(e) => console.log("e", e)}
				/>
			</ChakraNextLink>
		</Flex>
	);
};

export default CorpLogo;
