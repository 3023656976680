import { useRouter } from "next/router";
import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";

import useAuth from "@/hooks/use-auth";
import { trpc } from "@/lib/api/trpc/utils/trpc";
import useAppStore from "@/store/use-app";
import useCartGlobalStore from "@/store/use-cart";
import { Cart } from "@/types/cart-state";
import { ShippingModification_Enum } from "@/types/checkout";
import useRouteChanging from "@/hooks/use-route-changing";

type TLoadingState = "idle" | "initial" | "verifying" | "route-changing";

export interface ICartCheckoutState {
  items: Cart.Item[];
  errors: string[];
  loading: TLoadingState;
  can_proceed: boolean;
  linkRef: MutableRefObject<HTMLAnchorElement | null>["current"];
}

export const UnprocedableErrorMessages = [
  ShippingModification_Enum.NOT_QUOTABLE,
  ShippingModification_Enum.NOT_SERVICEABLE,
  ShippingModification_Enum.UNKOWN_WEIGHT,
  ShippingModification_Enum.NOT_AVAILABLE_WAREHOUSE,
  ShippingModification_Enum.NOT_PURCHASABLE,
];

export enum ActionTypes {
  SetGeneral = "__SET_GENERAL__",
  AppendError = "__APPEND_ERROR__",
  RemoveError = "__REMOVE_ERROR__",
  RemoveWarningErrors = "__REMOVE_WARNING_ERRORS__",
  SetLoading = "__SET_LOADING__",
}

export type ICartCheckooutAction =
  | {
      type: ActionTypes.SetGeneral;
      payload: Partial<ICartCheckoutState>;
    }
  | {
      type: ActionTypes.AppendError | ActionTypes.RemoveError;
      payload: string[];
    }
  | { type: ActionTypes.RemoveWarningErrors; payload: string }
  | {
      type: ActionTypes.SetLoading;
      payload: TLoadingState;
    };

const defaultState: ICartCheckoutState = {
  items: [],
  errors: [],
  loading: "idle",
  can_proceed: false,
  linkRef: null,
};

const reducer = (
  state: ICartCheckoutState,
  action: ICartCheckooutAction
): ICartCheckoutState => {
  switch (action.type) {
    case ActionTypes.SetGeneral:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.AppendError: {
      const errors = [...new Set([...state.errors, ...action.payload])];
      return {
        ...state,
        errors,
      };
    }
    case ActionTypes.RemoveWarningErrors: {
      const newErrors = state.errors.filter(
        (el) => !el.startsWith(action.payload)
      );
      return {
        ...state,
        errors: newErrors,
      };
    }
    case ActionTypes.RemoveError:
      return {
        ...state,
        errors: state.errors.filter((err) => !action.payload.includes(err)),
      };
    case ActionTypes.SetLoading:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      throw new Error("Invalid action type");
  }
};

const CartContext = createContext<
  | {
      state: ICartCheckoutState;
      d: Dispatch<ICartCheckooutAction>;
      actions: { toCheckout: (first_request: boolean, href?: string) => void };
    }
  | undefined
>(undefined);

interface ICartChekcoutProviderProps {
  children: ReactNode;
  // linkRef: MutableRefObject<HTMLAnchorElement | null>;
}

const CartCheckoutContextProvider = ({
  children,
}: ICartChekcoutProviderProps) => {
  const [state, d] = useReducer(reducer, { ...defaultState });

  const {
    state: { isLoggedIn },
  } = useAuth();

  const firstMountRequest = useRef(false);

  const { setCartItems, totalWeightPerStore, items } = useCartGlobalStore(
    (state) => state
  );

  const {
    loginFormModal,
    purchase: { mode },
  } = useAppStore((s) => s);

  const isRouteChanging = useRouteChanging(
    /^(\/checkout)|((en|es|pt)\/checkout)/
  );

  const router = useRouter();

  const { mutate, isLoading } = trpc.checkout.verifyCart.useMutation({
    onSuccess: ({ errors, items, redirect }, variables, ctx) => {
      setCartItems(items as Cart.Item[]);
      // Don't push to checkout if the backend has zeroed all quantity elements due to verification
      if (
        redirect &&
        firstMountRequest.current &&
        items.every((el) => el.quantity > 0)
      ) {
        setCartItems(items.filter((el) => el.quantity > 0) as Cart.Item[]);
        router.push({
          pathname: variables?.href ?? "/checkout2",
          ...(router?.query?.from
            ? { query: { from: router.query.from } }
            : {}),
        });
      }
    },
    onMutate(vars) {
      if (vars.first_request) {
        d({
          type: ActionTypes.SetLoading,
          payload: "initial",
        });
      } else d({ type: ActionTypes.SetLoading, payload: "verifying" });
    },
    onSettled() {
      d({ type: ActionTypes.SetLoading, payload: "idle" });
    },
  });

  const toCheckout = useCallback(
    (first_request: boolean = false, href: string = "/checkout") => {
      if (!isLoggedIn && !first_request) {
        loginFormModal("open");
        return;
      }
      mutate({ items, first_request, href });
    },
    [items, isLoggedIn]
  );

  useEffect(() => {
    if (
      !firstMountRequest.current &&
      items.every(
        (item) =>
          !UnprocedableErrorMessages.includes(
            item.adjust as ShippingModification_Enum
          )
      )
    ) {
      toCheckout(true);
      firstMountRequest.current = true;
    }
  }, [items]);

  const can_proceed = useMemo(
    () => state.errors.length === 0 && items.length === 0,
    [state.errors, items]
  );

  useEffect(() => {
    if (
      items.some((el) =>
        UnprocedableErrorMessages.includes(
          (el?.adjust as ShippingModification_Enum) ?? ""
        )
      )
    ) {
      d({
        type: ActionTypes.AppendError,
        payload: [CartErrors.UNPROCEED_ERROR],
      });
    } else
      d({
        type: ActionTypes.RemoveError,
        payload: [CartErrors.UNPROCEED_ERROR],
      });
  }, [items]);

  useEffect(() => {
    if (totalWeightPerStore.length) {
      const weightExecess = totalWeightPerStore.filter(
        (st) => st.totalWeight > 25
      );
      if (weightExecess.length && mode === "DELIVERY") {
        d({
          type: ActionTypes.AppendError,
          payload: weightExecess.map(
            (we) => `${CartErrors.WEIGHT_WARNING}:::${we.storeName}`
          ),
        });
      } else
        d({
          type: ActionTypes.RemoveWarningErrors,
          payload: CartErrors.WEIGHT_WARNING,
        });
    }
  }, [totalWeightPerStore, mode]);

  useEffect(() => {
    d({
      type: ActionTypes.SetLoading,
      payload: isRouteChanging ? "route-changing" : "idle",
    });
  }, [isRouteChanging]);

  const vals = useMemo(
    () => ({
      state: { ...state, items, can_proceed },
      d,
      actions: { toCheckout },
    }),
    [state, toCheckout, can_proceed, items]
  );

  return <CartContext.Provider value={vals}>{children}</CartContext.Provider>;
};

const useCartCtx = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error(
      "useCartCtx must be used within a CartCheckoutContextProvider"
    );
  }
  return context;
};

enum CartErrors {
  WEIGHT_WARNING = "warning:::common_max_weight_warning",
  UNPROCEED_ERROR = "error:::cart_error_unpurchasable",
}

export { CartCheckoutContextProvider };

export default useCartCtx;
