import { NextRouter } from "next/router";
import { ReactNode } from "react";

import { useSyncStockNotify } from "@/hooks/use-sync-favs";

import { AuthLayout } from "./auth.layout";
import CartCheckoutLayout from "./cart-checkout.layout";
import { MainLayout } from "./main.layout";

interface IRenderLayoutProps extends Record<string, unknown> {
  router: NextRouter;
  children: ReactNode;
}

export const RenderLayout = ({
  router,
  children,
  ...rest
}: IRenderLayoutProps) => {
  useSyncStockNotify();
  switch (router.pathname) {
    case "/auth/login":
    case "/auth/sign-up":
    case "/new-auth/sign-up":
    case "/new-auth/sign-up-success":
    case "/new-auth/login":
    case "/auth/confirm-signup":
    case "/auth/forgot-password":
    case "/auth/reset-password":
    case "/auth/action":
      return <AuthLayout>{children}</AuthLayout>;
    case "/cart":
    case "/checkout":
    case "/checkout2":
      return <CartCheckoutLayout>{children}</CartCheckoutLayout>;
    case "/contact-us":
    case "/404":
    case "/500":
    case "/_maintenance":
    case "/new-contact-form":
    case "/selling-contact":
    case "/_start/_world":
    case "/oguid/[id]":
    case "/_start/_countdown":
    case "/qr-code-information":
    case "/terms-and-conditions":
    case "/error-test":
    case "/test":
    case "/e-receipt/[slug]":
    case "/pu/[slug_id]":
    case "/[...catchall]":
    case "/auth/close-session":
    case "/result":
    case "/_start/_no-transactions":
    case "/rcpt/[slug]":
    case "/yourcpt/[slug]":
      return <>{children}</>;
    case "/result":
      return <MainLayout router={router}>{children}</MainLayout>;
    default:
      return (
        <MainLayout
          showFooter
          router={router}
          last_update={rest?.last_update! as string}
          next_update={rest?.next_update! as string}
        >
          {children}
        </MainLayout>
      );
  }
};
