import isEmpty from "lodash/isEmpty";

import { BaseProductFragment } from "@/graphql/__generated__/client-side";
import { Cart } from "@/types/cart-state";
import { logger } from "@/utils/helpers";

import { constants } from "./constants";

export class Product {
  product: BaseProductFragment | BaseProductFragment[];
  requiredProps: string[];

  constructor(product: BaseProductFragment | BaseProductFragment[]) {
    this.product = product;
    this.requiredProps = [
      "id",
      "price",
      "name",
      "slug",
      "weight",
      "store",
      "charge_tax",
      "product_images",
    ];
  }

  canBePurchased(isLoggedIn: boolean) {
    if (!Array.isArray(this.product)) {
      return (
        !this.product.in_store_only &&
        this.product.inventories[0].cf_status !== "no stock" &&
        isLoggedIn
      );
    }
    return false;
  }

  get hasVariants() {
    if (!Array.isArray(this.product) && isEmpty(this.product.variants))
      return false;
    return true;
  }

  getProductUrl() {
    if (!Array.isArray(this.product)) {
      return `/product/${this.product.id}-${this.product.slug ?? ""}`;
    }
    return "";
  }

  getCategoryProductUrl(basePath: string) {
    if (!Array.isArray(this.product)) {
      return `${basePath}/${this.product?.id}-${this.product?.slug ?? ""}`;
    }
    return "";
  }

  getCartPayload(
    reqId?: number
  ): Omit<Cart.Item["product"], "variant" | "inventory_id"> | null {
    if (Array.isArray(this.product) && reqId) {
      const reqProduct = this.product.find(
        (el) => el.id === reqId
      ) as Required<BaseProductFragment>;
      if (reqProduct) {
        const productKeys = Object.keys(reqProduct);
        const areAllRequiredKeys = this.requiredProps.every((reqProp) => {
          logger.log(reqProp, productKeys.includes(reqProp));
          return productKeys.includes(reqProp);
        });
        if (!areAllRequiredKeys)
          throw new Error(`Incorrect payload: ${JSON.stringify(reqProduct)}`);
        // const { id, weight = 0, store, slug, name, in_store_only, charge_tax } = reqProduct;
        return {
          product_id: reqProduct.id,
          image:
            reqProduct?.product_images?.find((el) => el.is_default)?.image
              .url ??
            reqProduct?.product_images?.[0]?.image.url ??
            constants.imageFallbackPlaceholder,
          name: reqProduct.name,
          slug: reqProduct?.slug!,
          points:
            (reqProduct?.points_multiplier_purchase ?? 0) *
            (reqProduct?.price ?? 0),
          url: "",
          in_store_only: reqProduct?.in_store_only!,
          charge_tax: reqProduct?.charge_tax!,
          price: reqProduct.price ?? 1000,
          store: reqProduct.store,
          weight: reqProduct?.weight!,
        };
      }
    }
    if (!Array.isArray(this.product)) {
      const areAllRequiredKeys = Object?.keys(this.product).some((el) =>
        this.requiredProps.includes(el)
      );
      if (!areAllRequiredKeys) throw new Error("Incorrect payload");

      return {
        product_id: this.product.id,
        image:
          this.product?.product_images?.find((el) => el.is_default)?.image
            .url ??
          this.product?.product_images?.[0]?.image.url ??
          constants.imageFallbackPlaceholder,
        name: this.product.name,
        slug: this.product?.slug!,

        points:
          (this.product.points_multiplier_purchase ?? 0) *
          (this.product?.price ?? 0),
        in_store_only: this.product?.in_store_only!,
        url: "",
        charge_tax: this.product?.charge_tax!,
        price: this.product.price ?? 1000,
        store: this.product.store,
        weight: this.product?.weight!,
      };
    }
    return null;
  }
}
