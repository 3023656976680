import { ReactNode, useEffect, useState } from "react";

export const ClientOnly = (props: { children: ReactNode }) => {
	const [mount, setMount] = useState(false);

	useEffect(() => {
		setMount(true);
	}, []);

	if (mount) return <>{props.children}</>;
	return null;
};
