const breakpoints = {
	xs: "20em",
	sm: "23.4375em",
	smm: "26.5625em",
	smm2: "31.25em",
	mdi: "37.5rem",
	md: "48em",
	lg: "64em",
	xl: "90em",
	// sm: "40em",
	// md: "52em",
	// lg: "64em",
	// xl: "80em",
};

export default breakpoints;

// const breakpoints = createBreakpoints({
// 	xs: "320px",
// 	sm: "375px",
// 	smm: "425px",
// 	md: "768px",
// 	lg: "1024px",
// 	xl: "1440px",

// });
