import "@/styles/carousel.css";
import "@/styles/chakra-slide.css";
import "@/styles/fonts/styles.css";
import "@/styles/general.css";
import "@/styles/globals.css";
import "@/styles/loader.css";
import "@/styles/markdown-mdxremote.css";
import "@/styles/markdown.css";
import "@/styles/mode-banner.css";
import "@/styles/render-if-visible.css";
import "@/styles/ribbon.css";
import "@/styles/scroll-text.css";
import "@/styles/stripe.css";

import { NextPage } from "next";
import { appWithTranslation } from "next-i18next";
import { NextSeo, NextSeoProps } from "next-seo";
import { AppProps } from "next/app";
import Head from "next/head";
import React, { ReactElement, ReactNode } from "react";

import awsExports from "@/aws-exports";
import LoginModal from "@/components/auth/login.modal";
import { RenderLayout } from "@/components/layouts/render-layout";
import NotificationPortal from "@/components/notification-portal";
import PurchaseModeModal from "@/components/purchase-mode.modal";
import SubtleNotifications from "@/components/subtle-notifications.portal";
import { NOFOLLOW, NOINDEX } from "@/config/constants";
import { useDisableConsoleLog } from "@/hooks/use-disable-console-log";
import { useGoogleAnalytics } from "@/hooks/use-gooogle-analytics";
import useSetPurchaseMode from "@/hooks/use-set-purchase-mode";
import { trpc } from "@/lib/api/trpc/utils/trpc";
import Providers from "@/providers";
import { Amplify } from "@aws-amplify/core";
import { Analytics } from "@vercel/analytics/react";

import { ChakraNextLink } from "@/components/chakra-links";
import nextI18NextConfig from "../next-i18next.config.js";
import AnyChatWidget from "@/components/chat-widget";
// import AnyChatWidget from "@/components/chat-widget.jsx";

Amplify.configure({
  ...awsExports,
  ssr: true,
});

const STAGE = process.env.NEXT_PUBLIC_STAGE;

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

const App = ({
  Component,
  pageProps,
  router,
  err,
}: AppProps & { Component: NextPageWithLayout } & { err: Error } & {
  pageProps: AppProps["pageProps"] & { _css: string; seo: NextSeoProps };
}) => {
  const getLayout = Component.getLayout;

  useDisableConsoleLog();

  useGoogleAnalytics();

  useSetPurchaseMode();

  return (
    <>
      {STAGE === "production" ? <Analytics /> : null}
      <NextSeo
        noindex={NOINDEX}
        nofollow={NOFOLLOW}
        title={"LATINSHOP.com.au"}
        {...pageProps.seo}
      />
      <Head>
        <style
          data-source="custom"
          dangerouslySetInnerHTML={{ __html: pageProps._css }}
        ></style>
      </Head>
      <AnyChatWidget />
      <Providers router={router}>
        <NotificationPortal />
        <SubtleNotifications />
        <LoginModal />
        <PurchaseModeModal />
        {getLayout ? (
          getLayout(<Component {...pageProps} router={router} err={err} />)
        ) : (
          <RenderLayout router={router} {...pageProps}>
            <Component {...pageProps} router={router} err={err} />
          </RenderLayout>
        )}
      </Providers>
    </>
  );
};

const AppWithI18Next = appWithTranslation(
  App as unknown as React.ElementType<AppProps<{}>>,
  nextI18NextConfig
);

const AppWithTRPC = trpc.withTRPC(AppWithI18Next);

export default AppWithTRPC;
