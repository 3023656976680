export const constants = {
	navBarSections: ["all-products", "category", "product", "test"],
	commonSimpleGridProps: {
		paddingTop: 3,
		bg: "softGrayBg",
		minChildWidth: { base: "9em", md: "17em" },
		alignContent: "center",
		justifyContent: "center",
		alignItems: "center",
		// px: { base: "8px", xl: "20%" },
		justifyItems: "center",
		// display="flex"
		direction: "column",
		spacing: 2,
		// pb: 3,
		rounded: "md",
	},
	badgeSize: { base: "0.6em", md: "0.8em" },
	imageFallbackPlaceholder: "/placeholder-image.png",
};
