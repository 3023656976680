import { Cart } from "@/types/cart-state";
import create, { GetState, SetState, StoreApi } from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
	addItem,
	calcSubtotal,
	clearCart,
	clearLatestItem,
	decreaseItemQty,
	removeItem,
	setCartItems,
	setTotalCartItemsQty,
} from "./actions";

const initialState: Cart.State = {
	items: [],
	totalWeight: 0,
	totalWeightPerStore: [],
	latestProductAdded: null,
	notifyLastProductAdded: false,
	subTotalPrice: 0,
	subTotalPriceNoInStore: 0,
	inStoreItems: false,
	totalCartItems: 0,
	totalPoints: 0,
	totalTax: 0,
};

const createActions = (set: SetState<Cart.Store>, get: GetState<Cart.Store>, api: StoreApi<Cart.Store>) =>
	({
		calcSubtotal: () => calcSubtotal(set, get, api),
		clearLatestItem: () => clearLatestItem(set),
		setTotalCartItemsQty: () => setTotalCartItemsQty(set, get),
		addItem: (product, notify, quantity) => addItem(set, get, product, notify, quantity),
		decreaseItemQty: (item) => decreaseItemQty(set, get, item),
		removeItem: (item) => removeItem(set, get, item),
		clearCart: () => clearCart(set),
		setCartItems: (items) => setCartItems(set, get, items),
	} as Cart.Actions);

const useCartGlobalStore = create<Cart.Store>(
	devtools(
		persist(
			(set, get, api) => ({
				...initialState,
				...createActions(set as SetState<Cart.Store>, get as GetState<Cart.Store>, api as StoreApi<Cart.Store>),
			}),
			{ name: "CART", version: 2 }
		),
		{
			name: "CART",
		}
	)
);

export default useCartGlobalStore;
