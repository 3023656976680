import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, {
  Dispatch,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { LOCALES } from "@/config/constants";
import { Lscq_GetCategoriesNumbersQuery } from "@/graphql/__generated__/server-side";
import useAuth from "@/hooks/use-auth";
import { trpc } from "@/lib/api/trpc/utils/trpc";
import useAppStore from "@/store/use-app";
import {
  Box,
  BoxProps,
  chakra,
  CloseButton,
  CSSObject,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Fade,
  Flex,
  FlexProps,
  Heading,
  Image,
  Select,
  Text,
  useMediaQuery,
  useQuery,
  VStack,
} from "@chakra-ui/react";

import { ChakraNextLink, ChakraNextLinkButton } from "../chakra-links";
import Logo from "./partials/logo";
import SectionDivider from "./partials/section-divider";

// const UserMenu = dynamic(() => import("@/components/user-menu"), { ssr: false });

export const AuthButtons = () => {
  const small = useQuery({ below: "md" });
  const [isSmallerThan768] = useMediaQuery(small);
  const loginFormModal = useAppStore((state) => state.loginFormModal);
  const router = useRouter();
  const { t } = useTranslation("common");

  const onLinkClickHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    loginFormModal("open");
  };

  return (
    <>
      <ChakraNextLinkButton
        variant="primary"
        size={isSmallerThan768 ? "sm" : "md"}
        href={`/auth/login${
          router.asPath.includes("/auth")
            ? ""
            : "?from=" + encodeURIComponent(router.asPath)
        }`}
        // @ts-expect-error
        onClick={(e) => onLinkClickHandler(e)}
      >
        {t("login", { ns: "common" })}
      </ChakraNextLinkButton>
      <ChakraNextLinkButton
        display={{ base: "none", sm: "inline-flex" }}
        // size={buttonSize}
        // size="sm"
        size={isSmallerThan768 ? "sm" : "md"}
        href={"/auth/sign-up"}
        variant="secondary"
      >
        {t("signup", { ns: "common" })}
      </ChakraNextLinkButton>
    </>
  );
};

// export const UserLinks = () => {
// 	return (
// 		<Flex flexShrink={{ base: 2, lg: 0 }}>
// 			<Flex paddingX={1.5}>
// 				<UserMenu />
// 			</Flex>
// 			<ChakraNextLink href="/my-account/favorites" passHref>
// 				<Flex paddingX={1.5}>
// 					<Icon _hover={{ cursor: "pointer" }} color="brandOrange" fontSize="3xl" as={BiHeart} />
// 				</Flex>
// 			</ChakraNextLink>
// 		</Flex>
// 	);
// };

const VALID_ROUTES = ["/services/translations", "/services/purchase", "/auth"];

export const LocaleSelector = (props: {
  flexProps?: FlexProps;
  data?: any;
  restricRoutes?: boolean;
  restriction?: "pathname" | "asPath";
  validRoutes?: string[];
}) => {
  const {
    data,
    flexProps,
    restricRoutes = true,
    restriction = "asPath",
    validRoutes = VALID_ROUTES,
    ...rest
  } = props;
  const [value, setValue] = useState("en");
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) setValue(router?.locale!);
  }, [router]);

  if (router.isReady && validRoutes.includes(router[restriction]))
    return (
      <Flex {...flexProps}>
        <Select
          w="4rem"
          // w="20px"
          size="sm"
          // flexGrow={0}
          // flex="0 0 4rem"
          rounded="full"
          value={value}
          onChange={(e) =>
            router.push(router.asPath, undefined, { locale: e.target.value })
          }
        >
          {LOCALES[router.locale! as "en" | "es" | "pt"].map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
          {/* <option value="en">🇦🇺</option>
					<option value={"es"}>🇪🇸</option>
					<option value={"pt"}>🇵🇹</option> */}
        </Select>
      </Flex>
    );
  return null;
};

const UserQrCode = () => {
  const {
    state: { userInfo, isLoggedIn },
  } = useAuth();
  const [size, setSize] = useState(148);
  if (!isLoggedIn) return null;
  return (
    <VStack>
      <Flex justify={"center"} p={1} borderWidth={"1px"} rounded="md">
        <Image
          src={userInfo?.qrcode}
          alt="Qr code"
          width={size}
          height={size}
          onLoad={(ev) => setSize(ev.currentTarget.naturalHeight)}
        />
      </Flex>
      <Heading as="h3" fontSize={"lg"} color="gray.600">
        {userInfo?.first_name} {userInfo?.last_name}
      </Heading>
    </VStack>
  );
};

export const AppDrawer = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props;
  const [selected, setSelected] = useState<string | null>(null);

  const { data = { categories: [], services: [] }, isLoading } =
    trpc.category.getAll.useQuery(undefined, {
      trpc: { context: { skipBatch: true } },
    });

  return (
    <Drawer isOpen={open} onClose={onClose} placement="left" size="xs">
      <DrawerOverlay />
      <DrawerContent w="full">
        <DrawerHeader justifyItems={"center"} w="full">
          <VStack w="full">
            <Flex align="center" p={2} w="full" justify="space-between">
              <Logo />
              <StoreLink />
              <CloseButton onClick={onClose} />
            </Flex>
            <UserQrCode />
            <Box>{}</Box>
            <Divider />
          </VStack>
        </DrawerHeader>
        <DrawerBody>
          <CategorySection
            selected={selected}
            setSelected={setSelected}
            data={data.categories}
            onClose={onClose}
          />
          <SectionDivider />
          <ServicesSection data={data.services} />
          <SectionDivider />
          <OthersSection onClose={onClose} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const SectionTitle = ({ title }: { title: string }) => (
  <Heading
    textTransform="uppercase"
    fontSize="xs"
    fontWeight="bold"
    as="h2"
    color="gray.900"
    fontFamily="Amazon Ember Lt"
    letterSpacing={"1px"}
  >
    {title}
  </Heading>
);

export const CategorySection = (props: {
  data: Lscq_GetCategoriesNumbersQuery["categories"];
  selected: string | null;
  setSelected: Dispatch<SetStateAction<string | null>>;
  onClose: () => void;
}) => {
  const { t } = useTranslation("common");
  const sid = useAppStore((s) => s.purchase.sid);
  return (
    <VStack align="flex-start">
      <SectionTitle
        title={t("common_categories", { defaultValue: "Services" })}
      />
      <VStack pl={0.5} pt={1}>
        {props.data &&
          props.data.map((cat) => (
            <VStack key={cat.value} w="full" align="flex-start">
              <Heading
                as="h3"
                onClick={() =>
                  props.setSelected((prev) => {
                    if (prev === cat.value) {
                      return null;
                    }

                    return cat.value;
                  })
                }
                letterSpacing="1px"
                cursor="pointer"
                textTransform="capitalize"
                fontWeight="bold"
                fontSize="sm"
                textAlign="left"
              >
                {t(`common_${cat.title}` as "common_Groceries", {
                  defaultValue: cat.value,
                })}{" "}
                ({cat?.sub_categories_aggregate?.aggregate?.count})
              </Heading>
              <Fade in={props.selected === cat.value} unmountOnExit>
                <VStack w="full" align="flex-start" pl={2} as="nav">
                  {cat.sub_categories.map((sc) => (
                    <ChakraNextLink
                      key={sc.value}
                      href={`/${!sid ? "web" : sid}/category/${cat.value}/${
                        sc.value
                      }/1`}
                      textTransform="capitalize"
                    >
                      {/* <Text variant="as-link"  onClick={props.onClose}> */}
                      {sc.value} (
                      {sc?.product_subcategories_aggregate?.aggregate?.count})
                      {/* </Text> */}
                    </ChakraNextLink>
                  ))}
                </VStack>
              </Fade>
            </VStack>
          ))}
      </VStack>
    </VStack>
  );
};

export const ServicesSection = (props: {
  data: Lscq_GetCategoriesNumbersQuery["service_classification"];
}) => {
  const { t } = useTranslation("common");
  return (
    <VStack align="flex-start">
      <SectionTitle
        title={t("common_services", { defaultValue: "Our range" })}
      />
      {props?.data?.map((serv) => (
        <ChakraNextLink
          key={serv?.name}
          href={`/services/${serv?.name?.toLowerCase()}`}
          textTransform="capitalize"
          fontWeight="bold"
          letterSpacing="1px"
          fontSize="sm"
        >
          {t(`common_${serv?.name}` as "common_Tax", {
            defaultValue: serv?.name,
          })}
        </ChakraNextLink>
      ))}
    </VStack>
  );
};

export const OthersSection = (props: { onClose: () => void }) => {
  // const isLoggedIn = useAuth((state) => state.isLoggedIn);
  const {
    state: { isLoggedIn },
  } = useAuth();
  const setAnyKey = useAppStore((state) => state.setAnyKey);
  const textColor = "gray.800";
  const { t } = useTranslation("common");
  return (
    <VStack align="flex-start" className="__sidebar_others_section">
      <SectionTitle title={t("appdrawer_others")} />
      <VStack
        pl={0.5}
        pt={4}
        align="flex-start"
        w="full"
        as="nav"
        pos="relative"
      >
        <Text
          variant="as-link"
          color={textColor}
          fontWeight="thin"
          onClick={() => {
            props.onClose();
            setTimeout(() => {
              setAnyKey("focusSubscribeForm", true);
            }, 100);
          }}
        >
          {t("subscribe_to_newsletter")}
        </Text>
        <ChakraNextLink
          href="/selling-contact"
          onClick={props.onClose}
          fontWeight="thin"
          color={textColor}
        >
          {t("sell_on_latinshop")}
        </ChakraNextLink>
        <ChakraNextLink
          href={{
            pathname: "/contact-us",
            query: { scope: "contact", title: "Contact us" },
          }}
        >
          {t("contact_us")}
        </ChakraNextLink>
        {/* <ApplyStatusBadge
          content="BETA"
          expiryDate="2022-10-31"
          styles={{
            pos: "static",
            py: 1,
            px: 2,
            rounded: "full",
            fontWeight: "bold",
          }}
        >
          <ChakraNextLink href="/new-auth/sign-up">
            {t("new_signup", { defaultValue: "Passwordless sign up" })}
          </ChakraNextLink>
        </ApplyStatusBadge>
        <ApplyStatusBadge
          content="BETA"
          expiryDate="2022-10-31"
          styles={{
            pos: "static",
            py: 1,
            px: 2,
            rounded: "full",
            fontWeight: "bold",
          }}
        >
          <ChakraNextLink href="/new-auth/login">
            {t("new_login", { defaultValue: "Passwordless login" })}
          </ChakraNextLink>
        </ApplyStatusBadge> */}
        <ChakraNextLink href="/auth/login">
          {t("login", { defaultValue: "Login" })}
        </ChakraNextLink>
        <ChakraNextLink href="/auth/sign-up">
          {t("signup", { defaultValue: "Sign-up" })}
        </ChakraNextLink>
        {isLoggedIn && (
          <ChakraNextLink
            href={{
              pathname: "/my-account/[section]",
              query: { section: "orders" },
            }}
            prefetch={false}
            onClick={props.onClose}
            fontWeight="thin"
            color={textColor}
          >
            {t("your_orders")}
          </ChakraNextLink>
        )}
        {isLoggedIn && (
          <ChakraNextLink
            href={{
              pathname: "/my-account/[section]",
              query: { section: "favorites" },
            }}
            prefetch={false}
            onClick={props.onClose}
            fontWeight="thin"
            color={textColor}
          >
            {t("your_favorites")}
          </ChakraNextLink>
        )}
        {isLoggedIn && (
          <chakra.a
            target={"_blank"}
            href="/qr-code-information"
            fontWeight="thin"
          >
            {t("appdrawer_member_code")}
          </chakra.a>
        )}
      </VStack>
    </VStack>
  );
};

// TODO move sections into a generic link tree component auto exapandable.
// Similar to the dashboard tree

type TNode = {
  href?: string;
  children?: TNode;
};

type TSection = {
  title: string;
  nodes: TNode[];
};

export const ApplyStatusBadge = (props: {
  content: string;
  children: ReactNode;
  styles?: CSSObject;
  expiryDate?: string;
  className?: string;
}) => {
  const defaultStyles: CSSObject = {
    bg: "brandOrange2.500",
    color: "white",
    fontSize: "10px",
    pos: "absolute",
    top: -1,
    // fontWeight: "bold",
    px: 1,
    borderRadius: "md",
    ml: 1,
  };
  if (props.expiryDate && new Date() > new Date(props?.expiryDate))
    return <>{props.children}</>;
  return (
    <Box pos="relative">
      {React.Children.map(props.children, (child, i) => {
        if (React.isValidElement(child) && i === 0)
          return React.cloneElement(child, {
            ...child.props,
            _after: {
              content: '"' + props.content + '"',
              ...defaultStyles,
              ...props.styles,
            },
          });
        return child;
      })}
    </Box>
  );
};

export const StoreLink = (props: { boxProps?: BoxProps }) => {
  const { boxProps, ...rest } = props;
  return (
    <Box {...boxProps}>
      <ChakraNextLink href="/">
        <Box
          _hover={{
            cursor: "pointer",
          }}
          display={{
            base: "none",
            xs: "inline-flex",
          }}
          alignItems="baseline"
          flexDirection="row"
          pr={1}
        >
          <Text
            fontSize={{
              base: "xs",
              smm: "md",
            }}
            fontWeight="bold"
            color="brandOrange"
            textAlign="center"
          >
            LATINSHOP
          </Text>
          <Text
            fontWeight="extrabold"
            fontSize={{
              base: "x-small",
              smm: "xs",
            }}
          >
            .com.au
          </Text>
        </Box>
      </ChakraNextLink>
    </Box>
  );
};
