import { useRouter } from "next/router";
import { useCallback, useEffect, useRef } from "react";

import {
  COOKIE_INFO_PREFIX,
  INFO_BANNER_CLASS_LOCATION_NAME,
  INFO_BANNER_CLOSE_BTN_ID,
  MAIN_MARQUEE_BTN_COLOR_ATTR,
} from "@/config/constants";
import useAuth from "@/hooks/use-auth";
import { trpc } from "@/lib/api/trpc/utils/trpc";
import { Flex, Slide, useMediaQuery, useQuery } from "@chakra-ui/react";

const cookieTime =
  process.env.NEXT_PUBLIC_STAGE === "production" ? 1 : 15 / 60 / 24;

const InfoTopBanner = () => {
  const isMdQuery = useQuery({ above: "md" });
  const [isMdScreen] = useMediaQuery(isMdQuery, { ssr: true, fallback: true });
  const {
    state: { userInfo },
  } = useAuth();
  const router = useRouter();

  const { data, refetch } = trpc.user.infoNews.useQuery(
    {
      locale: (router.locale as "en" | "es" | "pt") ?? "en",
      path: router.asPath,
    },
    {
      trpc: { context: { skipBatch: true } },
      enabled: router.isReady,
    }
  );

  const bannerRef = useRef<HTMLDivElement | null>(null);
  const bottomBannerRef = useRef<HTMLDivElement | null>(null);

  const onCloseHandler = useCallback(() => {
    setCookie(`${COOKIE_INFO_PREFIX}${data?.news?.id}`, "closed", cookieTime);
    refetch();
  }, [data?.news]);

  const anchorClickHandler = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const href = (e.target as HTMLAnchorElement).href;
    if (!href.startsWith("http")) {
      router.push(href);
    }
  }, []);

  useEffect(() => {
    if (bannerRef.current && bottomBannerRef.current) {
      const marqueElement = bannerRef.current.getElementsByClassName(
        "__main"
      )[0] as HTMLElement;
      const marqueElement2 = bottomBannerRef.current.getElementsByClassName(
        "__main"
      )[0] as HTMLElement;

      if (data?.isPreview) {
        const previewElement = document.createElement("p");
        // const previewElement2 = document.createElement("p");
        previewElement.innerText = "PREVIEW";
        previewElement.style.position = "absolute";
        previewElement.style.fontSize = "0.8rem";
        previewElement.style.fontWeight = "extrabold";
        previewElement.style.top = "botton";
        previewElement.style.right = "0";
        previewElement.style.background = "transparent";
        previewElement.style.color = "var(--chakra-colors-red-500)";
        previewElement.style.paddingRight = "2.5rem";
        previewElement.style.zIndex = "100";
        marqueElement.appendChild(previewElement);
        marqueElement2.appendChild(previewElement);
      }
      if (!marqueElement || !marqueElement2) return;
      marqueElement.setAttribute("data-lang", router?.locale ?? "en");
      marqueElement2.setAttribute("data-lang", router?.locale ?? "en");
      const paragraphElements = marqueElement?.getElementsByTagName("p");
      const paragraphElements2 = marqueElement2?.getElementsByTagName("p");
      const closeBtnColor = marqueElement?.getAttribute(
        MAIN_MARQUEE_BTN_COLOR_ATTR
      );
      const backgroundColor = marqueElement?.style.background;
      bannerRef.current.style.background = backgroundColor;
      bottomBannerRef.current.style.background = backgroundColor;
      const closeBtn = bannerRef.current.querySelector(
        `#${INFO_BANNER_CLOSE_BTN_ID}`
      ) as HTMLElement;
      const closeBtn2 = bottomBannerRef.current.querySelector(
        `#${INFO_BANNER_CLOSE_BTN_ID}`
      ) as HTMLElement;
      const anchorElements = bannerRef.current.querySelectorAll("a");
      const anchorElements2 = bottomBannerRef.current.querySelectorAll("a");

      if (userInfo)
        for (const paragraph of paragraphElements) {
          paragraph.setAttribute("data-last-name", userInfo?.last_name ?? "");
          paragraph.setAttribute("data-first-name", userInfo?.first_name ?? "");
          paragraph.setAttribute("data-name", userInfo?.name ?? "");
        }
      for (const paragraph of paragraphElements2) {
        paragraph.setAttribute("data-last-name", userInfo?.last_name ?? "");
        paragraph.setAttribute("data-first-name", userInfo?.first_name ?? "");
        paragraph.setAttribute("data-name", userInfo?.name ?? "");
      }

      anchorElements.forEach((anchor) => {
        anchor.addEventListener("click", anchorClickHandler);
      });
      anchorElements2.forEach((anchor) => {
        anchor.addEventListener("click", anchorClickHandler);
      });
      closeBtn?.addEventListener("click", onCloseHandler);
      closeBtn2?.addEventListener("click", onCloseHandler);
    }
  }, [data, router, isMdScreen]);

  // if (!data?.news?.content) return null;

  // if (isMdScreen && data?.news?.content)
  return (
    <>
      <Flex
        suppressHydrationWarning
        w="full"
        id="test"
        ref={bannerRef}
        display={isMdScreen ? "flex" : "none"}
        className={INFO_BANNER_CLASS_LOCATION_NAME}
        // pos="relative"
        flexDir={"column"}
      >
        <Flex
          w="full"
          className=""
          dangerouslySetInnerHTML={{ __html: data?.news?.content ?? "" }}
        ></Flex>
      </Flex>
      <Slide
        direction="bottom"
        in={!!data?.news?.content && !isMdScreen}
        data-slider="info-bottom-banner"
        suppressHydrationWarning
      >
        <Flex
          w="full"
          id="test"
          zIndex={-10}
          ref={bottomBannerRef}
          className={INFO_BANNER_CLASS_LOCATION_NAME}
          flexDir={"column"}
        >
          <Flex
            w="full"
            roundedTop={"md"}
            h="full"
            flex="1"
            dangerouslySetInnerHTML={{ __html: data?.news?.content ?? "" }}
          ></Flex>
        </Flex>
      </Slide>
    </>
  );
};

InfoTopBanner.displayName = "InfoTopBanner";

export default InfoTopBanner;

function setCookie(name: string, value: string = "", days: number) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
