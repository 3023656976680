import { m as motion } from "framer-motion";
import { useEffect, useState } from "react";

import useApp from "@/store/use-app";
import useCartGlobalStore from "@/store/use-cart";
import { Cart } from "@/types/cart-state";
import { DragHandleIcon } from "@chakra-ui/icons";
import { CloseButton, Collapse, Flex, FlexProps, HStack, Image, Text } from "@chakra-ui/react";

import { CartQtyControls, PriceDisplay } from "./cart.page/cart-content";
import { ChakraNextLink } from "./chakra-links";

type TCartToast = {
	title: string;
	message: string;
	item: Cart.Item;
	id: string;
	duration?: number;
};

const animationVariants = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		// y: 0,
		// x: 0,
		scale: 1,
		transition: {
			duration: 0.4,
			ease: [0.4, 0, 0.2, 1],
		},
	},
	exit: {
		opacity: 0,
		scale: 0.85,
		transition: {
			duration: 0.2,
			ease: [0.4, 0, 1, 1],
		},
	},
};

export const CartToast = ({
	title,
	message,
	id,
	item,
	duration = 6000,
}: // closeCb,
TCartToast) => {
	const [cartQtyControlOpen, setCartQtyControlOpen] = useState(false);
	const liveItem = useCartGlobalStore((state) => state.items.find((it) => it.product_id === item.product_id));
	const deleteNotification = useApp((state) => state.deleteNotification);

	let timer: NodeJS.Timeout;
	const closeNotification = () => {
		deleteNotification(id);
	};

	const closeHandler = () => {
		if (timer) clearTimeout(timer);
		closeNotification();
	};

	useEffect(() => {
		if (duration) timer = setTimeout(() => closeNotification(), duration);
		return () => {
			if (timer) clearTimeout(timer);
		};
	}, []);
	return (
		<Flex
			direction="column"
			rounded="lg"
			id={id}
			px={4}
			mx={0.5}
			pt={4}
			key={id}
			as={motion.div}
			borderStyle="solid"
			borderWidth="2px"
			position="relative"
			alignItems="center"
			layout
			// layoutId="cart-toast"
			variants={animationVariants}
			animate="animate"
			exit="exit"
			initial="initial"
			boxShadow="xl"
			maxW="25rem"
			bg="white"
			pointerEvents={"all"}
		>
			<Flex w="full">
				<Image src={item.product.image} alt="" boxSize="3.5rem" roundedTop="lg" />
				<Flex direction="column" px={3} w="100%">
					<Text color="blue.900" fontWeight="bold" fontSize="lg">
						{title}
					</Text>
					<Flex justifyContent="space-between" width="100%">
						<Text
							color="blue.900"
							fontWeight="normal"
							fontSize="md"
							pr={2}
							noOfLines={2}
							maxW="20rem"
							textOverflow={"ellipsis"}
							wordBreak="break-word"
						>
							{message}
						</Text>

						<ChakraNextLink
							href="/cart"
							onClick={() => {
								if (timer) clearTimeout(timer);
								closeNotification();
							}}
						>
							View cart
						</ChakraNextLink>
					</Flex>
				</Flex>
				<CloseButton onClick={closeHandler} position="absolute" top={2} right={2}></CloseButton>
			</Flex>
			<Flex w="100%">
				<Collapse in={cartQtyControlOpen} animateOpacity>
					<HStack p={3} justifyContent="space-around" w="100%" ml="2.6rem" spacing={2}>
						<CartQtyControls data={item} />
						<PriceDisplay prefix="$" textProps={{ fontWeight: "bold", color: "gray.700" }}>
							{((liveItem && liveItem.quantity ? liveItem.quantity : 0) * item.product.price).toFixed(2)}
						</PriceDisplay>
					</HStack>
				</Collapse>
			</Flex>
			<Flex
				borderTopStyle="solid"
				borderTopWidth="2px"
				_hover={{ cursor: "pointer" }}
				onClick={() => {
					// if (removeToastTimeout) removeToastTimeout();
					if (timer) clearTimeout(timer);
					setCartQtyControlOpen(true);
				}}
				mt={2}
				py={1}
				justifyContent="center"
				borderTopColor="gray.200"
				w="full"
			>
				<DragHandleIcon fontSize="sm" style={{ transform: "rotate(90deg)" }} />
			</Flex>
		</Flex>
	);
};

export const MotionFlex = motion<FlexProps>(Flex);
