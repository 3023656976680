globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"ad8b0cf88b606baefb187de9bd2cbc15b74c6adc"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// import * as client from "react-dom/client";

import * as Sentry from "@sentry/nextjs";

const environment = process.env.NODE_ENV;
const stage = process.env.STAGE;
const releaseTag =
  process.env.NEXT_PUBLIC_GIT_VERSION_TAG || "untagged-release";
const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;

// Uncomment this if we want a more detailed error message of componentStack, hydration missmatch etc.
// const oldClientHydrateRoot = client.hydrateRoot;
// // @ts-ignore
// client.hydrateRoot = new Proxy(oldClientHydrateRoot, {
// 	apply: (wrappingTarget, thisArg, args) => {
// 		const oldOnRecoverableError = args[2].onRecoverableError;

// 		args[2].onRecoverableError = new Proxy(oldOnRecoverableError, {
// 			apply: (inner_wrappingTarget, inner_thisArg, inner_args) => {
// 				const error = inner_args[0];
// 				const errorInfo = inner_args[1];

// 				const errorBoundaryError = new Error(error.message);
// 				errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
// 				errorBoundaryError.stack = errorInfo.componentStack;

// 				// Using the `LinkedErrors` integration to link the errors together.
// 				error.cause = errorBoundaryError;
// 				// @ts-ignore
// 				Sentry.captureException(error, { contexts: { react: { componentStack: errorInfo?.componentStack } } });
// 			},
// 		});
// 		// @ts-ignore
// 		return wrappingTarget.apply(thisArg, args);
// 	},
// });

Sentry.init({
  dsn,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: environment === "production" ? stage : "localhost-dev",
  release: `${releaseTag}-[${process.env.NEXT_PUBLIC_GIT_COMMIT_HASH}]`,
  tunnel: "/api/tunnel",
  enabled: environment === "production",
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
