import { useRouter } from "next/router";
import qs from "qs";

const useQs = () => {
	const router = useRouter();
	const pathname = router.pathname;
	if (!router.isReady) return { query: {}, pathname: "/" };
	const [path, query] = router.asPath.split("?");
	return { pathname: path, query: qs.parse(query) as Record<string, string> };
};

export default useQs;
