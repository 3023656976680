export const useDisableConsoleLog = () => {
	const stage = process.env.NEXT_PUBLIC_STAGE;
	const node_env = process.env.NODE_ENV;
	// useEffect(() => {
	// 	try {
	// 		logger.log("useEffect ~ typeof window.console", typeof window.console);
	// 		if (typeof window.console === "object" && stage === "develop" && node_env === "production") {
	// 			// @ts-ignore
	// 			window.console = {};
	// 			window.console.log = function () {};
	// 			window.console.debug = function () {};
	// 			window.console.info = function () {};
	// 			window.console.warn = function () {};
	// 			window.console.error = function () {};
	// 		}
	// 	} catch (err) {
	// 		if (stage === "develop") {
	// 			logger.log("Failed to disable the console.log");
	// 		}
	// 	}
	// }, []);
};
