import { domAnimation, LazyMotion } from "framer-motion";
import { Router } from "next/router";
import { ReactNode } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import AuthProvider from "@/providers/auth-provider";
import FlagFeatureProvider from "@/providers/feature-provider";
import RouteChangingProvider from "@/providers/route-changing-provider";
import theme from "@/theme";
import { ChakraProvider } from "@chakra-ui/react";
import { ErrorBoundary } from "@sentry/nextjs";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import MaintenanceProvider from "@/providers/maintenance";

export default function Providers({
  children,
  router,
}: {
  children: ReactNode;
  router: Router;
}) {
  return (
    <ChakraProvider theme={theme}>
      <MaintenanceProvider>
        <GoogleReCaptchaProvider
          scriptProps={{
            async: false,
            defer: false,
            appendTo: "head",
            nonce: undefined,
          }}
          reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY as string}
        >
          <ReactQueryDevtools initialIsOpen={false} />
          <ErrorBoundary>
            <RouteChangingProvider>
              <AuthProvider router={router}>
                <FlagFeatureProvider>
                  <LazyMotion features={domAnimation}>{children}</LazyMotion>
                </FlagFeatureProvider>
              </AuthProvider>
            </RouteChangingProvider>
          </ErrorBoundary>
        </GoogleReCaptchaProvider>
      </MaintenanceProvider>
    </ChakraProvider>
  );
}
