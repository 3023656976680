import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";

// import SubscribeForm from "./subscribe-formt";
import useAuth from "@/hooks/use-auth";
import useAppStore from "@/store/use-app";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FlexProps,
  Heading,
  HeadingProps,
  Icon,
  Link,
  List,
  ListItem,
  SimpleGrid,
  SimpleGridProps,
  Stack,
  StackDivider,
  Text,
  TextProps,
} from "@chakra-ui/react";

import { ChakraNextLink } from "./chakra-links";
import LastUpdated from "@/components/last-updated";
import { ClientOnly } from "@/components/client-only";
import { getCookie } from "@/utils/helpers";

const LazySubscribeForm = dynamic(() => import("@/components/subscribe-form"), {
  ssr: false,
  loading: () => null,
});

export const Footer = (props: {
  flexProps?: FlexProps;
  last_update?: string;
  next_update?: string;
}) => {
  const { t } = useTranslation("common");
  return (
    <Flex
      as="footer"
      bg="white"
      role="contentinfo"
      justifyContent="center"
      mx="auto"
      direction={"column"}
      overflow="hidden"
      w="full"
      px={{ base: "4", md: "8" }}
      {...props.flexProps}
    >
      <Stack spacing="2" divider={<StackDivider />}>
        <Stack
          direction={{ base: "column", lg: "row" }}
          //   spacing={{ base: "10", lg: "10" }}
        >
          <Box flex="1">
            <Flex alignItems="baseline" color="brandOrange">
              <Text
                fontSize={{ base: "xs", smm: "md" }}
                fontWeight="bold"
                textAlign="left"
              >
                LATINSHOP
              </Text>
              <Text
                fontWeight="extrabold"
                fontSize={{ base: "x-small", smm: "xs" }}
              >
                .com.au
              </Text>
            </Flex>
          </Box>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={{ base: "10", md: "10" }}
          >
            <LinkGrid spacing={{ base: "10", md: "20", lg: "28" }} flex="1" />
            <LazySubscribeForm width={{ base: "full", md: "sm" }} />
          </Stack>
        </Stack>
        <Stack
          direction={{ base: "column-reverse", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Copyright />
          <ClientOnly>
            {props.last_update && (
              <LastUpdated
                date={props.last_update}
                prefix={`${t("last_update", {
                  defaultValue: "Last updated",
                })}: `}
              />
            )}
            {props.next_update && (
              <LastUpdated
                prefix={`${t("next_update", {
                  defaultValue: "Next update",
                })}: `}
                date={props.next_update}
                textProps={{
                  display: getCookie("show_update") ? "block" : "none",
                }}
              />
            )}
          </ClientOnly>
          <SocialMediaLinks />
        </Stack>
      </Stack>
    </Flex>
  );
};

export const FooterHeading = (props: HeadingProps) => (
  <Heading
    as="h4"
    color={"gray.600"}
    fontSize="sm"
    fontWeight="semibold"
    textTransform="uppercase"
    letterSpacing="wider"
    {...props}
  />
);

export const LinkGrid = (props: SimpleGridProps) => {
  const loginFormModal = useAppStore((state) => state.loginFormModal);
  const isLoggedIn = useAuth()?.state?.isLoggedIn;
  const router = useRouter();

  const { t } = useTranslation("common");

  const onLinkClickHandler: React.MouseEventHandler<HTMLAnchorElement> = (
    e
  ) => {
    if (!isLoggedIn) {
      e.preventDefault();
      e.stopPropagation();
      loginFormModal("open");
    }
  };

  return (
    <SimpleGrid
      columns={{ base: 1, sm: 2 }}
      {...props}
      sx={{ li: { py: 0.5 } }}
    >
      <List minW="130px">
        <ListItem>
          <ChakraNextLink
            href={
              isLoggedIn
                ? {
                    pathname: "/my-account/[section]",
                    query: { section: "orders" },
                  }
                : "/"
            }
            prefetch={false}
            onClick={onLinkClickHandler}
          >
            {t("your_orders")}
          </ChakraNextLink>
        </ListItem>
        <ListItem>
          <ChakraNextLink
            href={
              isLoggedIn
                ? {
                    pathname: "/my-account/[section]",
                    query: { section: "favorites" },
                  }
                : "/"
            }
            prefetch={false}
            onClick={onLinkClickHandler}
          >
            <Text variant="as-link">{t("your_favorites")}</Text>
          </ChakraNextLink>
        </ListItem>
        <ListItem>
          <ChakraNextLink href="/auth/login">
            {t("login", { defaultValue: "Login" })}
          </ChakraNextLink>
        </ListItem>
        <ListItem>
          <ChakraNextLink href="/auth/sign-up">
            {t("signup", { defaultValue: "Sign-up" })}
          </ChakraNextLink>
        </ListItem>
      </List>

      <List minW="130px">
        <ListItem>
          <ChakraNextLink
            variant="as-link"
            href={
              isLoggedIn
                ? {
                    pathname: "/my-account/[section]",
                    query: { section: "information" },
                  }
                : "/"
            }
            onClick={onLinkClickHandler}
            prefetch={false}
          >
            {t("your_account")}
          </ChakraNextLink>
        </ListItem>
        <ListItem>
          <ChakraNextLink href="/selling-contact">
            {t("sell_on_latinshop")}
          </ChakraNextLink>
        </ListItem>
        <ListItem>
          <ChakraNextLink
            href={{
              pathname: "/contact-us",
              query: { scope: "contact", title: "contact_us" },
            }}
          >
            {t("contact_us")}
          </ChakraNextLink>
        </ListItem>
        <ListItem>
          <ChakraNextLink
            href={"/auth/close-session"}
            shallow={false}
            display="flex"
            alignItems="center"
            gap={1}
          >
            {t("common_user_menu_logout")}
            <ExternalLinkIcon />
          </ChakraNextLink>
        </ListItem>
      </List>
    </SimpleGrid>
  );
};

export const SocialMediaLinks = (props: FlexProps) => (
  <Flex
    color="gray.600"
    {...props}
    sx={{
      a: {
        fontSize: "20px",
        w: "40px",
        h: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        rounded: "md",
      },
      "a:hover": {
        background: "gray.100",
      },
    }}
    gap={2}
  >
    <Link href="https://bit.ly/latinshopfacebook" target="_blank">
      <Icon aria-label="Facebook" as={FaFacebook} />
    </Link>
    <Link target="_blank" href="https://bit.ly/latinshopinstagram">
      <Icon aria-label="Instagram" as={FaInstagram} />
    </Link>
    <Link href="https://wa.me/61402368926?text=latinshop.com">
      <Icon aria-label="Twitter" as={FaWhatsapp} />
    </Link>
  </Flex>
);

export const Copyright = (props: TextProps) => {
  const { t } = useTranslation("common");
  return (
    <Text fontSize="sm" {...props}>
      &copy; {new Date().getFullYear()} {t("latinshop_copyright")}
    </Text>
  );
};
