import Script from "next/script";

export default function AnyChatWidget() {
  if (process.env.NODE_ENV === "development") return null;
  return (
    <>
      <Script
        strategy="afterInteractive"
        id="anychat-widget"
        // @ts-ignore Script is not extending properly ScriptHTMLAttributes
        src={`https://api.anychat.one/widget/f918cf52-e811-31a4-a62f-fa0ea7b66a8e?r=${encodeURIComponent(
          typeof window !== "undefined" ? window.location.href : ""
        )}`}
      />
    </>
  );
}
