import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";

import { AppDrawer, AuthButtons, StoreLink } from "@/components/appbar/partials";
import { CartMenu } from "@/components/cart-menu";
import { RenderIf } from "@/components/render-if";
import { ISearchCtrlRef } from "@/components/search.input";
import WithStatusBadge from "@/components/with-status-badge";
import { MODE_BANNER_ALLOWED_PATHS } from "@/config/constants";
import useAuth from "@/hooks/use-auth";
import { useHydratedStore } from "@/store/use-app/useApp";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
	Box,
	Flex,
	HStack,
	IconButton,
	useBreakpointValue,
	useDisclosure,
	useMediaQuery,
	useQuery,
} from "@chakra-ui/react";
import { sleep } from "common";

import CorpLogo from "./logo";

const SearchInput2 = dynamic(() => import("@/components/search.input2"), {
	ssr: false,
	loading: () => null,
});

const UserMenu = dynamic(() => import("@/components/user-menu"), { ssr: false, loading: () => null });
const QrCodeMenu = dynamic(() => import("@/components/appbar/qr-code-menu"), { ssr: false, loading: () => null });

const statusBadge = process.env.NEXT_PUBLIC_STATUS_BADGE || "Develop";

const Controls = () => {
	const {
		state: { loading: verifying, isLoggedIn },
	} = useAuth();

	const { t } = useTranslation("common");

	const router = useRouter();

	const isMdQuery = useQuery({ below: "md" });

	const [isUnderMd] = useMediaQuery([isMdQuery]);

	const drawerCtrl = useDisclosure();

	const [showMobileInput, setShowMobileInput] = useState(false);

	useEffect(() => {
		if (!isUnderMd) setShowMobileInput(false);
	}, [isUnderMd]);

	return (
		<Flex
			direction="row"
			overflow="hidden"
			display="inline-flex"
			zIndex="200"
			bg="mainbg"
			justifyContent="space-between"
			w="full"
			borderBottomColor="gray.200"
			borderBottomWidth="1px"
			as="nav"
			color="tomato"
			align={"center"}
			py={2}
			minH="32px"
		>
			<Flex display={!showMobileInput ? "flex" : "none"} id="left-side" order="1">
				<AppDrawer open={drawerCtrl.isOpen} onClose={drawerCtrl.onClose} />
				<IconButton
					ml={1}
					_focus={{ boxShadow: "none" }}
					onClick={drawerCtrl.onToggle}
					aria-label="Menu"
					bg="white"
					fontSize="3xl"
					color="tomato"
					icon={<HamburgerIcon />}
				/>

				<Box flexShrink={1} display="flex" pos="relative">
					<HStack alignItems="center" _hover={{ cursor: "pointer" }}>
						<CorpLogo
							flexProps={{
								justify: "center",
								align: "center",
								flexShrink: 0,
								sx: {
									img: {
										w: "28px",
									},
								},
							}}
						/>

						<StoreLink
							boxProps={{
								display: { base: "none", smm2: "block" },
							}}
						/>
					</HStack>
				</Box>
			</Flex>
			<SearchInput2 setShowMobileInput={setShowMobileInput} showMobileInput={showMobileInput} />
			<Flex order="10" id="right-side" display={!showMobileInput ? "flex" : "none"}>
				<HStack justifyContent="flex-end" pr={3} h="full" order="10">
					<RenderIf condition={!verifying && !isLoggedIn} clientOnly>
						<AuthButtons />
					</RenderIf>
					<Flex flexShrink={{ base: 2, lg: 0 }}>
						<Flex paddingX={1.5}>
							<UserMenu />
						</Flex>
						<RenderIf condition={isLoggedIn}>
							<QrCodeMenu />
						</RenderIf>
					</Flex>
					{!verifying && <CartMenu />}
				</HStack>
			</Flex>
		</Flex>
	);
};

export default Controls;
