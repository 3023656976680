import { useTranslation } from "next-i18next";
import React, { useState } from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { FaLock } from "react-icons/fa";

import { AppComp } from "@/types/components";
import {
  chakra,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";

import type { CustomTypeOptions } from "react-i18next";

const PasswordInput = ({
  label,
  placeholder = "",
  formRegister,
  error,
  disabled = false,
  tabIndex = 0,
  setFocus,
  id,
  inputProps,
}: AppComp.PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation("common");
  return (
    <FormControl>
      <FormLabel
        w="full"
        fontWeight="bold"
        htmlFor={id}
        mb={0}
        color="gray.600"
        _hover={{ cursor: "pointer" }}
        fontSize="16px"
      >
        {label}
        <chakra.span
          fontSize={12}
          fontWeight="bold"
          color="tomato"
          lineHeight={1}
          display={error?.message ? "inline-block" : "none"}
        >
          &nbsp;{"* "}
          {t(error?.message as keyof CustomTypeOptions["resources"]["common"], {
            defaultValue: error?.message ?? "",
          })}
        </chakra.span>
      </FormLabel>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          color="gray.300"
          // fontSize="1.2em"
        >
          <Icon as={FaLock} color="gray.500" />
        </InputLeftElement>
        <Input
          placeholder={placeholder}
          paddingLeft={45}
          border="1px solid"
          fontSize="18px"
          id={id}
          borderColor="gray.400"
          variant="outline"
          bg="white"
          disabled={disabled}
          isInvalid={!!error?.message}
          autoCorrect="off"
          type={!showPassword ? "password" : "text"}
          layerStyle="selected"
          {...inputProps}
          {...formRegister}
        />
        <InputRightElement color="gray.300" fontSize="1.2em">
          <IconButton
            tabIndex={-1}
            aria-label="ce"
            h="1.75rem"
            size="sm"
            onClick={() => {
              setShowPassword((prev) => !prev);
            }}
            icon={
              showPassword ? (
                <BsEyeSlashFill color="gray" />
              ) : (
                <BsEyeFill color="gray" />
              )
            }
          ></IconButton>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

export default PasswordInput;
