import { ComponentProps, JSXElementConstructor, ReactNode, useEffect, useState } from "react";

type IRenderIfProps = {
	condition?: boolean | string | number | null;
	children: ReactNode;
	other?: ReactNode;
	name?: string;
	clientOnly?: boolean;
	as?: JSXElementConstructor<ComponentProps<any>> | keyof JSX.IntrinsicElements;
	[x: string]: any;
};

export const RenderIf = ({
	condition = true,
	children,
	other,
	name = "",
	clientOnly,
	as: AsComp,
	// @ts-ignore
	...rest
}: IRenderIfProps) => {
	const [mount, setMount] = useState(false);
	useEffect(() => {
		setMount(true);
	}, []);

	let Wrapper = ({ children }: any) => <>{children}</>;
	// eslint-disable-next-line react/display-name
	if (AsComp) Wrapper = ({ children }: any) => <AsComp {...rest}>{children}</AsComp>;
	if (clientOnly && !mount) return null;
	if (condition) return <Wrapper>{children}</Wrapper>;
	if (other) return <Wrapper>{other}</Wrapper>;
	return null;
};
