import * as Yup from "yup";
import { z } from "zod";

import { AusStates } from "@/types/shared";
import { yupResolver } from "@hookform/resolvers/yup";
import { zodResolver } from "@hookform/resolvers/zod";

const whiteSpaceRx = /([\s-])/g;
const lowercaseRx = /[a-z]/;
const uppercaseRx = /[A-Z]/;
const symbolRx = /\W+/;
const numberRx = /[\d]/;

const signInValidationSchema = Yup.object().shape({
	email: Yup.string().required("email_is_required").email("auth_email_isinvalid"),
	password: Yup.string().required("auth_password_required"),
});

const newSignInValidationSchema = Yup.object().shape({
	email: Yup.string().required("email_is_required").email("auth_email_isinvalid"),
});

const signUpValidationSchema = Yup.object().shape({
	email: Yup.string().required("email_is_required").email("auth_email_isinvalid"),
	first_name: Yup.string().required("auth_first_name_required"),
	last_name: Yup.string().required("auth_last_name_required"),
	phone_number: Yup.string()
		.required("phone_number_required")
		.matches(/^\d+$/, "auth_phone_onlynumbers")
		.matches(/^(04|05|4|5)[0-9]{8}$/, "auth_phone_not_valid"),
	password: Yup.string()
		.min(8, "auth_password_min_characters")
		.matches(lowercaseRx, "auth_invalid_password_must_have_lowercase")
		.matches(uppercaseRx, "auth_invalid_password_must_have_uppercase")
		.matches(numberRx, "auth_invalid_password_must_have_numeric")
		.required("auth_password_required"),
	confirm_password: Yup.string()
		.oneOf([Yup.ref("password"), null], "auth_password_nomatch")
		.required("auth_confirm_password_required"),
	tos: Yup.boolean().oneOf([true], "auth_accept_tos"),
});

// const signUpValidationSchema = z.object({
// 	email: z
// 		.string({ required_error: "email_is_required" })
// 		.trim()
// 		.min(1, { message: "email_is_required" })
// 		.email({ message: "auth_email_isinvalid" }),
// 	first_name: z.string({ required_error: "auth_first_name_required" }).trim().min(1),
// 	last_name: z.string({ required_error: "auth_last_name_required" }).trim().min(1),
// 	password: z
// 		.string({ required_error: "auth_password_required" })
// 		.min(8, "auth_password_min_characters")
// 		.regex(lowercaseRx, { message: "auth_invalid_password_must_have_lowercase" })
// 		.regex(uppercaseRx, { message: "auth_invalid_password_must_have_uppercase" })
// 		.regex(numberRx, { message: "auth_invalid_password_must_have_numeric" }),
// 	confirm_password: z.string({ required_error: "auth_confirm_password_required" }),

// 	phone_number: z
// 		.string({ required_error: "phone_number_required" })
// 		.trim()
// 		.min(1)
// 		.regex(/^\d+$/, { message: "auth_phone_onlynumbers" })
// 		.regex(/^(04|05|4|5)[0-9]{8}$/, { message: "auth_phone_not_valid" }),
// 	tos: z.boolean({ required_error: "auth_accept_tos" }),
// });

const newSignUpValidationSchema = z.object({
	email: z
		.string({ required_error: "email_is_required" })
		.trim()
		.min(1, { message: "email_is_required" })
		.email({ message: "auth_email_isinvalid" }),
	first_name: z
		.string({ required_error: "auth_first_name_required" })
		.trim()
		.min(1, { message: "auth_first_name_required" }),
	last_name: z
		.string({ required_error: "auth_last_name_required" })
		.trim()
		.min(1, { message: "auth_last_name_required" }),
	phone_number: z
		.string({ required_error: "phone_number_required" })
		.trim()
		.min(1, { message: "phone_number_required" })
		.regex(/^\d+$/, { message: "auth_phone_onlynumbers" })
		.regex(/^(04|05|4|5)[0-9]{8}$/, { message: "auth_phone_not_valid" }),
	tos: z.literal(true, { required_error: "auth_accept_tos" }),
});

const confirmSignUpValidationSchema = Yup.object().shape({
	email: Yup.string().required("email_is_required").email("auth_email_isinvalid"),
	code: Yup.string().required("Please enter a code is required"),
});

const passwordResetRequestValidationSchema = Yup.object().shape({
	email: Yup.string().required("email_is_required").email("auth_email_isinvalid"),
});

const passwordResetValidationSchema = Yup.object().shape({
	email: Yup.string().required(),
	code: Yup.string().required(),
	password: Yup.string()
		.min(8, "Password must be at least 8 characters")
		.matches(lowercaseRx, "auth_invalid_password_must_have_lowercase")
		.matches(uppercaseRx, "auth_invalid_password_must_have_uppercase")
		// .matches(symbolRx, "auth_invalid_password_must_have_symbol")
		.matches(numberRx, "auth_invalid_password_must_have_numeric")
		.required("Password is required"),
	confirm_password: Yup.string()
		.oneOf([Yup.ref("password"), null], "auth_password_no_match_confirm")
		.required("auth_password_confirm_password"),
});

const sellerContactForm = Yup.object().shape({
	email: Yup.string().email("Please enter a valid email").required("Please enter your email"),
	name: Yup.string().required("Enter your contact name"),
	message: Yup.string().max(400, "Message cannot exceed 400 characters").required("Enter a contact message"),
	// token: Yup.string().required("Token is required"),
});

const collectAddressForm = Yup.object().shape({
	line1: Yup.string().required("Please provide a street address"),
	line2: Yup.string(),
	city: Yup.string().required("Please provide a suburb"),
	state: Yup.mixed()
		.oneOf([...AusStates], "Please select your state")
		.required("Please select your state"),
	postal_code: Yup.string()
		.min(4, "Postcode must be 4 digits")
		.max(4, "Postcode must be 4 digits")
		.required("This is required"),
	// token: Yup.string().required("Token is required"),
});

const changePasswordValidationSchema = Yup.object().shape({
	current_password: Yup.string().required("auth_current_password"),
	password: Yup.string()
		.min(8, "auth_password_min_characters")
		.matches(lowercaseRx, "auth_invalid_password_must_have_lowercase")
		.matches(uppercaseRx, "auth_invalid_password_must_have_uppercase")
		// .matches(symbolRx, "auth_invalid_password_must_have_symbol")
		.matches(numberRx, "auth_invalid_password_must_have_numeric")
		.required("auth_password_is_required"),

	confirm_password: Yup.string()
		.oneOf([Yup.ref("password"), null], "auth_password_no_match_confirm")
		.required("Confirm Password is required"),
});

const invalidCharsRegEx = /^[a-zA-Z\/\d\s\-,.()]*$/;
const onlyNumericRegeEx = /^\d+$/;
const onlyAlphaNumRegEx = /^[a-zA-Z\s\d\-_]*$/;
export const aussiePostcodeRegEx = /^(0[289][0-9]{2})|([1-9][0-9]{3})$/;

const collectAddressForm2 = Yup.object().shape({
	name: Yup.string()
		.required("collect_address_form_required")
		.max(40, "$0 characters or less allowed")
		.test(
			"no-only-numbers",
			"collect_address_form_a_suburb_cannot_have_only_numbers",
			(v) => !onlyNumericRegeEx.test(v as string)
		)
		.test("no-invalid-chars", "collect_address_form_invalid_character", (v) => invalidCharsRegEx.test(v as string)),
	address_line1: Yup.string()
		.required("collect_address_form_required")
		.max(40, "collect_address_form_only_40_charactters_or_less_allowed")
		.test(
			"no-only-numbers",
			"collect_address_form_an_address_cannot_have_only_numbers",
			(v) => !onlyNumericRegeEx.test(v as string)
		)
		.test("no-invalid-chars", "collect_address_form_invalid_character", (v) => invalidCharsRegEx.test(v as string)),
	address_line2: Yup.string()
		.max(40, "collect_address_form_only_40_charactters_or_less_allowed")
		.test(
			"is-only-numbers",
			"collect_address_form_an_address_cannot_have_only_numbers",
			(v) => !onlyNumericRegeEx.test(v as string)
		)
		.test("no-invalid-chars", "collect_address_form_invalid_characterr", (v) =>
			invalidCharsRegEx.test(v as string)
		),
	suburb: Yup.string()
		.required("collect_address_form_required")
		.test(
			"no-only-numbers",
			"collect_address_form_a_suburb_cannot_have_only_numbers",
			(v) => !onlyNumericRegeEx.test(v as string)
		)
		.test("no-invalid-chars", "collect_address_form_invalid_character", (v) => invalidCharsRegEx.test(v as string)),
	postcode: Yup.string()
		.required("collect_address_form_required")
		.test("no-only-numbers", "collect_address_form_no_a_valid_australian_postcode", (v) =>
			aussiePostcodeRegEx.test(v as string)
		),
	state_name: Yup.string().oneOf([...AusStates], "Required"),
});

const collectInfoFormTranslation = Yup.object().shape({
	name: Yup.string()
		.required("collect_address_form_required")
		.max(40, "collect_address_form_only_40_charactters_or_less_allowed")
		.test(
			"no-only-numbers",
			"collect_address_form_a_suburb_cannot_have_only_numbers",
			(v) => !onlyNumericRegeEx.test(v as string)
		)
		.test("no-invalid-chars", "collect_address_form_invalid_character", (v) => invalidCharsRegEx.test(v as string)),
	email: Yup.string().email("collect_address_form_invalid_email").required("collect_address_form_required"),
	phone: Yup.string().required("collect_address_form_invalid_email"),
	address_line1: Yup.string()
		.required("collect_address_form_required")
		.max(40, "collect_address_form_only_40_charactters_or_less_allowed")
		.test(
			"no-only-numbers",
			"collect_address_form_an_address_cannot_have_only_numbers",
			(v) => !onlyNumericRegeEx.test(v as string)
		)
		.test("no-invalid-chars", "collect_address_form_invalid_character", (v) => invalidCharsRegEx.test(v as string)),
	address_line2: Yup.string()
		.max(40, "collect_address_form_only_40_charactters_or_less_allowed")
		.test(
			"is-only-numbers",
			"collect_address_form_an_address_cannot_have_only_numbers",
			(v) => !onlyNumericRegeEx.test(v as string)
		)
		.test("no-invalid-chars", "collect_address_form_invalid_characterr", (v) =>
			invalidCharsRegEx.test(v as string)
		),
	suburb: Yup.string()
		.required("collect_address_form_required")
		.test(
			"no-only-numbers",
			"collect_address_form_a_suburb_cannot_have_only_numbers",
			(v) => !onlyNumericRegeEx.test(v as string)
		)
		.test("no-invalid-chars", "collect_address_form_invalid_character", (v) => invalidCharsRegEx.test(v as string)),
	postcode: Yup.string()
		.required("collect_address_form_required")
		.test("no-only-numbers", "collect_address_form_no_a_valid_australian_postcode", (v) =>
			aussiePostcodeRegEx.test(v as string)
		),
	state_name: Yup.string().oneOf([...AusStates], "Required"),
});

const SUPPORTED_FORMATS = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
const FILE_MAX_SIZE = 50000000;
const UPLOAD_FORM_NS = "services_translation";

enum Enum_Upload_Error {
	UNSUPPORTED_MIME_TYPE = "document_upload_form_error_mimetype",
	FILE_SIZE = "document_upload_form_error_filesize",
	AT_LEAST_ONE_FILE = "document_upload_form_error_atleastone",
	ATTACH_DOC = "document_upload_form_error_attachdoc",
	CUSTOM_NAME = "document_upload_form_error_customname",
	CUSTOMER_NOTES = "document_upload_form_error_customernotes",
}

const translationDocSchema = Yup.object().shape({
	documents: Yup.array(
		Yup.object().shape({
			file: Yup.mixed()
				.test("fileFormat", Enum_Upload_Error.UNSUPPORTED_MIME_TYPE, (file: File) => {
					if (!file) return true;
					return SUPPORTED_FORMATS.includes(file.type);
				})
				.test("fileSize", Enum_Upload_Error.FILE_SIZE, (file: File, ctx) => {
					if (!file) return true;
					return file.size <= FILE_MAX_SIZE;
				}),
		})
	).test("Invalid files", Enum_Upload_Error.AT_LEAST_ONE_FILE, (value, ctx) => {
		return (value as { file: File }[]).some((v) => v.file);
	}),
	custom_name: Yup.string().max(30, Enum_Upload_Error.CUSTOM_NAME),
	customer_notes: Yup.string().max(400, Enum_Upload_Error.CUSTOMER_NOTES),
});

const changeUserInformationSchema = Yup.object().shape({
	name: Yup.string()
		.required("Please enter your name")
		.matches(/^([a-zA-Z\s\.]*)$/, "Only alphabetic characters allowed"),
	email: Yup.string().required("Email cannot be edited"),
	phone_number: Yup.string()
		.required("Phone is required")
		.matches(/^((\+61)(4|5)[0-9]{8}|(04|05|4|5)[0-9]{8}$)$/, "Please enter a valid mobile number"),
	country: Yup.string().required("Country is required"),
	// .matches(/^(04|05|4|5)[0-9]{8}$/, "Phone number is not valid mobile australian number"),
});

export const signInFormOptions = {
	resolver: yupResolver(signInValidationSchema),
};

export const newSignInFormOptions = {
	resolver: yupResolver(newSignInValidationSchema),
};

export const newSignUpFormOptions = {
	resolver: zodResolver(newSignUpValidationSchema),
};

export const signUpFormOptions = {
	resolver: yupResolver(signUpValidationSchema),
};

export const confirmSignUpOptions = {
	resolver: yupResolver(confirmSignUpValidationSchema),
};

export const passwordResetRequestFormOptions = {
	resolver: yupResolver(passwordResetRequestValidationSchema),
};

export const passwordResetFormOptions = {
	resolver: yupResolver(passwordResetValidationSchema),
};

export const sellerContactFormOpotions = {
	resolver: yupResolver(sellerContactForm),
};

export const collectAddressFormOptions = {
	resolver: yupResolver(collectAddressForm),
};

export const collectAddressFormOptions2 = {
	resolver: yupResolver(collectAddressForm2),
};

export const collectInfoFormTranslationOptions = {
	resolver: yupResolver(collectInfoFormTranslation),
};

export const translationDocOptions = {
	resolver: yupResolver(translationDocSchema),
};

export const changePasswordOptions = {
	resolver: yupResolver(changePasswordValidationSchema),
};

export const changeUserInformationOptions = {
	resolver: yupResolver(changeUserInformationSchema),
};
