import { useEffect } from "react";

import useAuth from "@/hooks/use-auth";
import useAppStore from "@/store/use-app";
import { trpc } from "@/lib/api/trpc/utils/trpc";

export const useSyncStockNotify = () => {
  const setAnyKey = useAppStore((state) => state.setAnyKey);
  const {
    state: { isLoggedIn, loading },
  } = useAuth();

  const {
    data,
    isLoading,
    error: favsError,
  } = trpc.user.getInfo.useQuery(undefined, { enabled: isLoggedIn });

  useEffect(() => {
    if (data?.notify && isLoggedIn && !loading) {
      setAnyKey("stockNotify.loaded", true);
      // @ts-ignore
      setAnyKey("stockNotify.items", data.notify);
    }
    if (!isLoggedIn && !loading) {
      setAnyKey("stockNotify.loaded", true);
      // @ts-ignore
      setAnyKey("stockNotify.items", []);
    }
  }, [data, isLoggedIn, loading]);
};
