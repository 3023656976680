import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaCheck } from "react-icons/fa";
import { GrMail } from "react-icons/gr";

import TextInput from "@/components/text-input";
import useAuth from "@/hooks/use-auth";
import { signInFormOptions } from "@/lib/validation_schemas/forms";
import { Forms } from "@/types/forms";
import { Button, chakra, Flex, HStack, Text, VStack } from "@chakra-ui/react";

import { ChakraNextLink } from "../chakra-links";
import PasswordInput from "../password.input";

const MAILRX = /.+\@.+\..+/;

export const LoginForm = (props: {
  onSuccessCb?: () => void;
  showBackLink?: boolean;
}) => {
  const { t } = useTranslation("common");
  const { showBackLink = true } = props;
  const {
    loginUser,
    clearErrors,
    state: { isLoggedIn, loading, error },
  } = useAuth();

  const defaultForgotPasswordLink = "/auth/forgot-password";

  const [routeChangeStart, setRouteChangeStart] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    setFocus,
    trigger,
    getValues,
    watch,
    setValue,
  } = useForm<Forms.Auth.SignInForm>({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    defaultValues: {},
    ...signInFormOptions,
  });
  const watchEmail = watch("email");
  const { errors } = formState;
  const router = useRouter();

  const onSubmit: SubmitHandler<Forms.Auth.SignInForm> = async (data) => {
    await loginUser(data.email, data.password);
  };

  useEffect(() => {
    if (isLoggedIn && !error && router.isReady) {
      if (props.onSuccessCb) {
        return props.onSuccessCb();
      }
      if (router?.query?.from) router.push(router.query.from as string);
      else router.push("/");
    }
  }, [isLoggedIn, error, router]);

  useEffect(() => {
    setTimeout(() => setFocus("email"), 2);
    return () => clearErrors();
  }, []);

  const disableInputs =
    formState.isSubmitting || loading || routeChangeStart || isLoggedIn;

  return (
    <VStack as="form" onSubmit={handleSubmit(onSubmit)} w="full">
      <TextInput
        label={t("common_signup_email", { defaultValue: "Email" })}
        placeholder={t("common_login_email_placeholder", {
          defaultValue: "Type your email",
        })}
        type="email"
        disabled={disableInputs}
        id={"email"}
        leftElement={GrMail}
        formRegister={{
          ...register("email"),
        }}
        error={errors.email}
      />
      <PasswordInput
        label={t("common_signup_password", { defaultValue: "Password" })}
        placeholder={t("common_login_password_placeholder", {
          defaultValue: "Type your password",
        })}
        formRegister={{
          ...register("password"),
        }}
        id="password"
        disabled={disableInputs}
        error={errors.password}
      />
      <HStack w="full">
        {error ? (
          <Text color="tomato" fontWeight="bold">
            {error}
          </Text>
        ) : null}
        <ChakraNextLink
          _hover={{
            cursor: "pointer",
            textDecoration: "underline",
          }}
          color="link"
          paddingLeft={1}
          justifySelf="end"
          flex="1 1"
          w="full"
          textAlign={"end"}
          tabIndex={-1}
          fontSize="16px"
          fontWeight={"normal"}
          ml={-1}
          href={`${
            defaultForgotPasswordLink +
            (MAILRX.test(watchEmail)
              ? `?email=${encodeURIComponent(watchEmail)}`
              : "")
          }`}
        >
          {t("common_signup_forgot_pass", { defaultValue: "Forgot password?" })}
        </ChakraNextLink>
      </HStack>

      <Button
        aria-label="test"
        disabled={disableInputs}
        variant="primary"
        w="full"
        type="submit"
        isLoading={disableInputs}
        loadingText={t("common_signup_login_btn_loading", {
          defaultValue: "Verifying...",
        })}
        rightIcon={<>{isLoggedIn ? <FaCheck color="green" /> : null}</>}
      >
        <Flex align="center" gap={2}>
          {t("common_signup_login_btn", { defaultValue: "Login" })}
          <chakra.svg
            w="6"
            h="6"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </chakra.svg>
        </Flex>
      </Button>

      <Flex
        sx={{ "a:last-child": { pl: 2 } }}
        flexDir="column"
        gap={2}
        align="center"
      >
        <ChakraNextLink
          href="/auth/sign-up"
          display="inline-flex"
          color="link"
          // className="inline-flex items-center font-bold text-blue-500 hover:text-blue-700 text-xs text-center"
        >
          <span>
            <svg
              className="h-6 w-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
            </svg>
          </span>
          <span className="ml-2">
            {t("common_signup_login_no_account", {
              defaultValue: "No account",
            })}
          </span>
        </ChakraNextLink>
        {showBackLink ? (
          <ChakraNextLink
            href="/"
            justifySelf={"flex-end"}
            color="link"
            id="back.main"
          >
            {t("common_signup_back", { defaultValue: "Back to main" })}
          </ChakraNextLink>
        ) : null}
      </Flex>
    </VStack>
  );
};

LoginForm.displayName = "LoginForm";
