import useAppStore from "@/store/use-app";
import { Cart } from "@/types/cart-state";
import { calcTax } from "common";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import round from "lodash/round";
import sumBy from "lodash/sumBy";
import { GetState, SetState, StoreApi } from "zustand";

export const calcSubtotal = (set: SetState<Cart.Store>, get: GetState<Cart.Store>, api: StoreApi<Cart.Store>) => {
	const cartItems = get().items;
	const subTotalPrice = cartItems.map((el) => el.quantity * el.product.price).reduce((acc, val) => acc + val, 0);
	const totalWeight = round(
		cartItems.map((el) => el.product.weight * el.quantity).reduce((acc, weight) => weight + acc, 0),
		2
	);
	const totalPoints = cartItems.reduce((acc, val) => val.quantity * val.product.points + acc, 0);
	const totalTax = round(
		calcTax(
			cartItems.reduce((acc, val) => (val.product.charge_tax ? val.quantity * val.product.price : 0) + acc, 0)
		),
		2
	);
	const inStoreItems = cartItems.some((el) => el.product.in_store_only);

	const totalWeightPerStore = map(groupBy(cartItems, "product.store.id"), (obj: Cart.Item[], key: string) => {
		return {
			storeId: parseInt(key),
			storeName: obj[0].product.store.name,
			totalWeight: round(
				obj.map((item) => item.product.weight * item.quantity).reduce((acc, v) => v + acc, 0),
				2
			),
		};
	});
	if (cartItems.length === 0) {
		sessionStorage.setItem("userCart", JSON.stringify([]));
	}

	set(
		(state) => ({
			...state,
			subTotalPrice,
			totalWeight,
			totalWeightPerStore,
			inStoreItems,
			totalPoints,
			totalTax,
		}),
		false,
		//@ts-ignore
		"calcSubtotal"
	);

	// api.setState({ subTotalPrice: subTotal }, true);
};

export const clearLatestItem = (set: SetState<Cart.Store>) => {
	set((state) => ({ latestProductAdded: null }));
};

export const setTotalCartItemsQty = (set: SetState<Cart.Store>, get: GetState<Cart.Store>) => {
	const qty = get()
		.items.map((el) => el.quantity)
		.reduce((acc, val) => acc + val, 0);
	set(
		(state) => ({ totalCartItems: qty }),
		false,
		//@ts-ignore
		"setTotalCartItemsQty"
	);
	// Recalculate subtotal
	get().calcSubtotal();
};

export const decreaseItemQty = (
	set: SetState<Cart.Store>,
	get: GetState<Cart.Store>,
	product: Cart.Item["product"]
) => {
	var cartItems = get().items;
	let cartItemIndex = cartItems.findIndex((el) => el.inventory_id === product.inventory_id);
	if (cartItemIndex === -1) return;
	var newCartItems = cartItems.map((el, i) => {
		if (i === cartItemIndex) {
			return { ...el, quantity: el.quantity - 1 };
		}
		return { ...el };
	});
	// Remove from the array elements that have qty reach zero
	const filteredItems = newCartItems.filter((el) => el.quantity > 0);
	set(
		(state) => ({ items: [...filteredItems] }),
		false,
		//@ts-ignore
		"decreaseItemQty"
	);
	get().setTotalCartItemsQty();
};

export const addItem = (
	set: SetState<Cart.Store>,
	get: GetState<Cart.Store>,
	product: Cart.Item["product"] | null,
	notify: boolean = false,
	quantity: number = 1
) => {
	if (!product) return;
	var cartItems = get().items;
	const adjust = cartItems.find((el) => el.product_id === product.product_id)?.adjust ?? null;
	cartItems.push({
		quantity: quantity,
		product: product,
		product_id: product.product_id,
		inventory_id: product.inventory_id,
		adjust,
	});
	const newItemsById = groupBy(cartItems, "inventory_id");
	const newItems = map(newItemsById, (obj: Cart.Item[], key: string) => {
		return {
			inventory_id: key,
			product_id: obj[0].product_id,
			quantity: sumBy(obj, "quantity"),
			product: obj[0].product,
			adjust: obj[0].adjust,
		};
	});
	set(
		(state) => ({
			items: [...newItems],
			latestProductAdded: product,
			notifyLastProductAdded: notify,
		}),
		false,
		//@ts-ignore
		"addItem"
	);
	get().setTotalCartItemsQty();
	if (notify) useAppStore.getState().addNotification({ data: newItemsById[product.inventory_id][0] });
	// logger.log("newItemsById[product.id]", newItemsById[product.id][0]);
};

export const removeItem = (set: SetState<Cart.Store>, get: GetState<Cart.Store>, item: Cart.Item) => {
	const filteredItems = get().items.filter((el) => el.product.inventory_id !== item.inventory_id);
	//@ts-ignore
	set((state) => ({ items: [...filteredItems] }), false, "removeItem");
	get().setTotalCartItemsQty();
};

export const clearCart = (set: SetState<Cart.Store>) => {
	set(
		(state) => ({
			items: [],
			totalCartItems: 0,
			subTotalPrice: 0,
		}),
		false,
		//@ts-ignore
		"clearCart"
	);
};

export const setCartItems = (set: SetState<Cart.Store>, get: GetState<Cart.Store>, items: Cart.Item[]) => {
	set(
		(state) => ({
			items: [...items],
		}),
		false,
		//@ts-ignore
		"setCartItems"
	);
	get().setTotalCartItemsQty();
};
