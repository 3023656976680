import { Text, TextProps, chakra } from "@chakra-ui/react";
import format from "date-fns/format";
import { useTranslation } from "next-i18next";

export default function LastUpdated(props: {
  date: string;
  prefix?: string;
  textProps?: TextProps;
}) {
  const { t } = useTranslation("common");
  const formatedDate = format(new Date(props.date), "MMM dd, yyyy HH:mm");
  return (
    <Text as="h5" fontSize={"sm"} {...props.textProps}>
      {props?.prefix}
      <chakra.span fontWeight={"bold"}>{formatedDate}</chakra.span>
    </Text>
  );
}
