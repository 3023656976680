import Link, { LinkProps } from "next/link";

import useQs from "@/hooks/use-qs";
import {
  Button,
  ButtonProps,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";

type ChakraLinkAndNextProps = Omit<ChakraLinkProps, "href"> &
  LinkProps & { withQuery?: boolean };
type ChakraAndNextProps = ButtonProps & LinkProps;

export const ChakraNextLinkButton = ({
  href,
  children,
  prefetch,
  scroll,
  ...props
}: ChakraAndNextProps) => {
  return (
    <Link href={href} passHref prefetch={prefetch} scroll={scroll}>
      <Button {...props}>{children}</Button>
    </Link>
  );
};

export const ChakraNextLink = ({
  href,
  children,
  prefetch,
  passHref,
  shallow,
  scroll,
  withQuery = false,
  ...props
}: ChakraLinkAndNextProps) => {
  const { query } = useQs();

  if (withQuery && typeof href === "string")
    return (
      // <Link href={{ pathname: href, query }} passHref shallow={shallow} scroll={scroll}>
      <ChakraLink
        as={Link}
        _focus={{ boxShadow: "none" }}
        href={href as string}
        passHref
        scroll={scroll}
        shallow={shallow}
        prefetch={prefetch}
        {...props}
      >
        {children}
      </ChakraLink>
      // </Link>
    );
  return (
    // <Link href={href} passHref shallow={shallow} scroll={scroll}>
    <ChakraLink
      as={Link}
      _focus={{ boxShadow: "none" }}
      href={href as string}
      passHref
      scroll={scroll}
      shallow={shallow}
      prefetch={prefetch}
      {...props}
    >
      {children}
    </ChakraLink>
    // </Link>
  );
};
