import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

// import DevelopBanner from '@/components/develop-banner';
// import DevelopBanner from '@/components/develop-banner';
import { MODE_BANNER_ALLOWED_PATHS } from '@/config/constants';
import { Flex } from '@chakra-ui/react';

import ModeBanner from '../purchase-mode.modal/mode-banner';
import RouteLoadingOverlay from '../route-change-overlay';

const DevelopBanner = dynamic(() => import("../develop-banner"), { ssr: false });

const CartCheckoutLayout = (props: { children: ReactNode }) => {
	const router = useRouter();

	return (
		<>
			<Flex
				w="full"
				flexDir={"column"}
				minH={"100vh"}
				h="full"
				bgGradient="radial( gray.300, gray.50)"
				align="center"
				overflow={"auto"}
			>
				<DevelopBanner small={true} />
				<ModeBanner
					flexProps={{
						borderBottomWidth: "1px",
						borderBottomColor: "gray.300",
						display: MODE_BANNER_ALLOWED_PATHS.includes(router.pathname) ? "flex" : "none",
					}}
				/>
				<RouteLoadingOverlay exceptions={[/\/my-account/]} />

				<Flex
					direction={"column"}
					w="full"
					maxW="container.lg"
					px={{ base: 1, md: "1" }}
					overflow={"auto"}
					align="center"
				>
					{props.children}
				</Flex>
			</Flex>
		</>
	);
};

export default CartCheckoutLayout;
