export const colors = {
	black: "#16161D",
	brandOrange: "#fe6d00",
	// mainLink: ""
	brandOrangeGrad: "linear(to-l, #fe6d00, #FF0080)",
	brandBackground: "#edf2f7",
	softGrayBg: "#f8f7f7",
	// softGrayBg: "aliceblue",
	// softGrayBg: "white",
	brandStripe: "#635bff",
	darkBackground: "#374F5D",
	darkBlueText: "#4E4B72",
	addCartGreen: "#0cc665",

	formError: "#f25e60",
	specialGreen: {
		50: "#edfbe4",
		100: "#d4eec1",
		200: "#bae09c",
		300: "#9fd477",
		400: "#85c851",
		500: "#6cae37",
		600: "#53872a",
		700: "#3b611d",
		800: "#223b0e",
		900: "#071500",
	},
	addCartGreen2: {
		"50": "#F1F9EC",
		"100": "#D9EEC9",
		"200": "#C1E3A6",
		"300": "#A9D883",
		"400": "#90CD60",
		"500": "#78C23D",
		"600": "#609B31",
		"700": "#487425",
		"800": "#304D19",
		"900": "#18270C",
	},
	brandOrange2: {
		"50": "#FFF0E5",
		"100": "#FFD6B8",
		"200": "#FFBC8A",
		"300": "#FFA25C",
		"400": "#FF882E",
		"500": "#FF6D00",
		"600": "#CC5800",
		"700": "#994200",
		"800": "#662C00",
		"900": "#331600",
	},
	stripe: {
		50: "#EEEFFB",
		100: "#DEE0F7",
		200: "#C1C5F1",
		300: "#A0A6E9",
		400: "#838BE2",
		500: "#636EDB",
		600: "#303DCF",
		700: "#232D99",
		800: "#181F68",
		900: "#0B0F32",
	},
};

// export  colors;
