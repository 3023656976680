import { LayoutGroup, motion } from "framer-motion";
import { useRouter } from "next/router";
import { CSSProperties, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { CartToast } from "@/components/cart-toast";
import useApp from "@/store/use-app/useApp";
import { VStack } from "@chakra-ui/react";

const NotificationPortal = () => {
	const [documentState, setDocumentState] = useState<Document | null>(null);
	const allNotifications = useApp((state) => state.notifications);
	const cleanAllNotifications = useApp((state) => state.cleanAllNotifications);
	const router = useRouter();

	useEffect(() => {
		router.events.on("routeChangeStart", cleanAllNotifications);
		return () => {
			router.events.off("routeChangeStart", cleanAllNotifications);
		};
	}, []);

	useEffect(() => {
		if (document) setDocumentState(document);
	}, []);

	if (documentState) {
		return ReactDOM.createPortal(
			<div id="notification-portal" style={portalStyle}>
				<LayoutGroup id="cart-toast">
					{Object.entries(allNotifications).map(([_k, notification]) => {
						return (
							<CartToast
								id={notification.id}
								key={notification.id}
								item={notification.data}
								title="Item added"
								message={notification.data.product.name}
								duration={6000}
							/>
						);
					})}
				</LayoutGroup>
				{/* </AnimatePresence> */}
				{/* </VStack> */}
			</div>,

			documentState.body
		);
	}

	return null;
};

const MotionStack = motion(VStack);

const portalStyle: CSSProperties = {
	position: "fixed",
	display: "flex",
	flexDirection: "column",
	top: "0",
	left: "50%",
	gap: "1rem",
	transform: "translate(-50%, 0)",
	// background: "var(--chakra-colors-red-200)",
	// opacity: 0.5,
	minWidth: "300px",
	maxWidth: "640px",
	height: "100vh",
	zIndex: 1000,
	pointerEvents: "none",
};

export default NotificationPortal;
