/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: process.env.NEXT_PUBLIC_COGNITO_POOL_REGION,
	aws_cognito_region: process.env.NEXT_PUBLIC_COGNITO_POOL_REGION,
	aws_user_pools_id: process.env.NEXT_PUBLIC_COGNITO_POOL_ID,
	aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
	oauth: {},
};

export default awsmobile;

// https://latinshop-com.auth.ap-southeast-2.amazoncognito.com/?data=eyJ1c2VyTmFtZSI6IjYzYWYzNzYxLTZkNTItNDg3My1iNGZmLTc1ZmFiNGE2ZWQwYyIsInJlZGlyZWN0VXJsIjoiaHR0cDovL2xvY2FsaG9zdDozMDAwL2F1dGgvdmVyaWZ5P3VzZXJuYW1lPTYzYWYzNzYxLTZkNTItNDg3My1iNGZmLTc1ZmFiNGE2ZWQwYyIsInJlZ2lvbiI6ImFwLXNvdXRoZWFzdC0yIiwiY2xpZW50SWQiOiIya2phb3JkZzExNXN2aTl1NGpsNGZvYjYwMiJ9&code=222384
