import produce, { Draft } from 'immer';
import { useEffect, useState } from 'react';
import create, { GetState, Mutate, SetState, State, StateCreator, StoreApi } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { AppState, ICartNotification, NestedKeyLeaves } from '@/types/app-state';

import {
  addNotification, addOrRemoveStockNotify, cleanAllNotifications, deleteNotification,
  loginFormModal, productCardModal, purchaseModeModal, sendSubtleNotification, setAnyKey,
  setFavorites, setProxyState, setSearching, toggleDevelopBanner
} from './actions';

const stage = process.env.NEXT_PUBLIC_STAGE as "develop" | "production";
const build = process.env.NODE_ENV;
const dontRenderBanner = build === "production" && stage === "production";

const initialState: AppState.State = {
	searching: false,
	favorites: [],
	stockNotify: {
		loaded: false,
		items: [],
	},
	focusSubscribeForm: false,
	showDevelopBanner: !dontRenderBanner,
	notifications: {},
	subtleNotification: null,
	LOGIN_FORM_MODAL: { open: false, callback: null },
	PURCHASE_MODE_MODAL: { open: false, callback: null },
	PRODUCT_CARD_MODAL: { open: false, callback: null, data: null },
	purchase: {
		mode: null,
		warehouse: "web",
		sid: "web",
		name: "DELIVERY",
	},
};

const immer =
	<
		T extends State,
		CustomSetState extends SetState<T>,
		CustomGetState extends GetState<T>,
		CustomStoreApi extends StoreApi<T>
	>(
		config: StateCreator<
			T,
			(partial: ((draft: Draft<T>) => void) | T, replace: boolean, prefix: string) => void,
			CustomGetState,
			CustomStoreApi
		>
	): StateCreator<T, CustomSetState, CustomGetState, CustomStoreApi> =>
	(set, get, api) =>
		config(
			(partial, replace, prefix) => {
				const nextState =
					typeof partial === "function" ? produce(partial as (state: Draft<T>) => T) : (partial as T);
				return set(
					nextState,
					replace,
					// @ts-ignore
					prefix
				);
			},
			get,
			api
		);

const createActions = (set: SetState<AppState.Store>, get: GetState<AppState.Store>) =>
	({
		setProxyState: (fn: (draft: Draft<AppState.State>) => void, prefix?: string) => setProxyState(set, fn, prefix),
		// addFavorite: (id) => addFavorite(set, id),
		// removeFavorite: (id) => removeFavorite(set, id),
		// clearFavorites: () => clearFavorites(set),
		setFavorites: (favs) => setFavorites(set, favs),
		setSearching: (val) => setSearching(set, val),
		setAnyKey: (key: NestedKeyLeaves, val: any) => setAnyKey(set, key, val),
		toggleDevelopBanner: () => toggleDevelopBanner(set),
		addNotification: (notification: ICartNotification) => addNotification(set, notification),
		deleteNotification: (id: string) => deleteNotification(set, id),
		sendSubtleNotification: ({
			image,
			title = "Saved to",
			caption = "Favorites",
			type = "favorite",
		}: {
			image: string;
			title?: string;
			caption?: string;
			type?: "favorite" | "stock";
		}) => sendSubtleNotification(set, { image, title, caption, type }),
		loginFormModal: (action: "open" | "close" | "toggle", callback: (() => void) | null) =>
			loginFormModal(set, get, action, callback),
		purchaseModeModal: (action: "open" | "close" | "toggle", callback: (() => void) | null, msg: string) =>
			purchaseModeModal(set, get, action, callback, msg),
		productCardModal: (action: "open" | "close" | "toggle", data?: any | null, callback?: () => void) =>
			productCardModal(set, get, action, callback, data),
		addOrRemoveStockNotify: (inventory_id: string) => addOrRemoveStockNotify(set, get, inventory_id),
		cleanAllNotifications: () => cleanAllNotifications(set),
	} as AppState.Actions);

const useAppStore = create<
	AppState.Store,
	SetState<AppState.Store>,
	GetState<AppState.Store>,
	Mutate<StoreApi<AppState.Store>, [["zustand/persist", Partial<AppState.Store>], ["zustand/devtools", never]]>
>(
	devtools(
		persist(
			immer((set, get) => ({
				...initialState,
				...createActions(set as SetState<AppState.Store>, get as GetState<AppState.Store>),
			})),
			{ version: 5, name: "ls_APP" }
		),
		{ name: "APP" }
	)
);
// This is to avoid alerts to persist in localstorage. Because some alerts can have JSX and be symbols. There are no serializable
if (typeof window !== "undefined") {
	useAppStore.persist.setOptions({
		partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key]) => ["purchase"].includes(key))),
	});
}

export default useAppStore;

// Use this store whenever the state you are requiring causes hydration issues, either use this hook or import the component using next/dynamic
export const useHydratedStore = <T extends keyof AppState.State>(
	selector: (state: AppState.State) => AppState.Store[T]
): AppState.Store[T] => {
	const [state, setState] = useState(selector(initialState));
	const zustandState = useAppStore((persistedState) => selector(persistedState));

	useEffect(() => {
		setState(zustandState);
	}, [zustandState]);

	return state;
};
