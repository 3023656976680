import dynamic from "next/dynamic";

import Controls from "@/components/appbar/partials/controls";
import InfoBanner from "@/components/info-banner";
import ModeBanner from "@/components/purchase-mode.modal/mode-banner";
import { RenderIf } from "@/components/render-if";
// import QrCodeMenu from "@/components/appbar/qr-code-menu";
import { Flex } from "@chakra-ui/react";

const DevelopBanner = dynamic(() => import("@/components/develop-banner"), { ssr: false, loading: () => null });
const AppBar = (props: unknown) => {
	return (
		<>
			<Flex flexDir={"column"} w="full" bg="red.200" pos="sticky" zIndex={200} top={0}>
				<DevelopBanner />
				<RenderIf clientOnly>
					<InfoBanner />
				</RenderIf>
				<ModeBanner />
				<Controls />
			</Flex>
		</>
	);
};

AppBar.displayName = "AppBar";
// export AppBar;

export default AppBar;
