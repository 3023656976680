import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import useAuth from '@/hooks/use-auth';
import useAppStore from '@/store/use-app';

import { GenericModal } from '../generic-modal';
import { LoginForm } from './login.form';

const LoginModal = () => {
	const { open: isOpen, callback } = useAppStore((state) => state.LOGIN_FORM_MODAL);
	const loginFormModal = useAppStore((state) => state.loginFormModal);
	const {
		state: { loading },
	} = useAuth();
	const formRef = useRef<HTMLDivElement | null>(null);
	const router = useRouter();
	const onCloseHandler = () => {
		loginFormModal("close");
	};

	const onSuccessHandlerCb = () => {
		loginFormModal("close");
		if (callback) {
			callback();
		}
	};

	useEffect(() => {
		router.events.on("routeChangeStart", onCloseHandler);
		return () => router.events.off("routeChangeStart", onCloseHandler);
	}, []);

	return (
		<GenericModal
			body={<LoginForm onSuccessCb={onSuccessHandlerCb} showBackLink={false} />}
			isOpen={isOpen}
			title={"Sign-in"}
			onClose={onCloseHandler}
			modalProps={{ size: "md", closeOnOverlayClick: !loading }}
			modalContentProps={{ mx: 1 }}
			modalCloseButtonProps={{ isDisabled: loading }}
			ref={formRef}
		/>
	);
};

export default LoginModal;
