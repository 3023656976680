import { Router } from "next/router";
import QRCode from "qrcode";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";

import { trpc } from "@/lib/api/trpc/utils/trpc";
import { reactQueryClient } from "@/lib/react-query-client";
import useAppStore from "@/store/use-app";
import { CognitoUser } from "@/types/shared";
import {
  deleteCognitoCookies,
  getCognitoErrorTransKey,
  getCurrentBaseUrl,
  logger,
} from "@/utils/helpers";
import { Auth, SignUpParams } from "@aws-amplify/auth";
import { chakra } from "@chakra-ui/react";

const clientMetadata = {
  cb_domain: getCurrentBaseUrl(),
};

const maxAttemptsErrorMessage =
  "DefineAuthChallenge failed with error Maximum attempts reached.";

export interface IState {
  loading: boolean;
  userInfo: {
    email: string;
    name: string;
    first_name: string;
    last_name: string;
    userId: string;
    qrcode: string;
    points: number | null;
    phone: string;
  } | null;
  error: null | string;
  isLoggedIn: boolean;
  registration: {
    status: "IDLE" | "PROCESSING" | "SUCCESS" | "FAIL";
    userId: string | null;
  };
  login: {
    status: "IDLE" | "CHALLENGE" | "CHALLENGE_SUCCESS" | "_CHALLENGE_FAIL";
    error: null | string | JSX.Element;
    email?: null | string;
    cognitoUser?: null | CognitoUser;
    count: number;
  };
  registrationError: string | null;
}

const defaultState: IState = {
  loading: true,
  userInfo: null,
  error: null,
  isLoggedIn: false,
  registration: { status: "IDLE", userId: null },
  registrationError: null,
  login: {
    status: "IDLE",
    error: null,
    email: null,
    cognitoUser: null,
    count: 0,
  },
};

export interface IAuthContext {
  state: IState;
  logout: () => void;
  loginUser: (email: string, password?: string) => Promise<void>;
  register: (
    params: SignUpParams,
    withPassword?: "true" | "false",
    token?: string
  ) => void;
  refreshAuth: (bypassCache?: boolean) => void;
  clearErrors: () => void;
  clearRegistration: () => void;
  requestChallenge: (email: string) => void;
  anwserChallenge: (answer: string) => void;
  clearInputChallengeError: () => void;
  resetChallengeToDefault: () => void;
}

export const AuthContext = createContext<IAuthContext>(
  undefined as unknown as IAuthContext
);

enum DispatchActions {
  LOGIN = "LOGIN",
  SET_POINTS = "SET_POINTS",
  SET_INFO = "SET_INFO",
  SET_NULL = "SET_NULL",
  LOGOUT = "LOGOUT",
  CLEAR_ERROR = "CLEAR_ERROR",
  SET_ERROR = "SET_ERROR",
  REGISTER = "REGISTER",
  CLEAR_REGISTRATION = "CLEAR_REGISTRATION",
  REQUEST_CHALLENGE = "REQUEST_CHALLENGE",
  CLEAR_REQUEST_CHALLENGE_ERROR_INPUT = "CLEAR_REQUEST_CHALLENGE_ERROR_INPUT",
  RESET_CHALLENGE_DEFAULT = "RESET_CHALLENGE_DEFAULT",
}
type TAction = IActionWithPayloadObject | IActionWithPrimitivePayload;

interface IActionWithPayloadObject {
  t:
    | DispatchActions.CLEAR_ERROR
    | DispatchActions.SET_ERROR
    | DispatchActions.SET_INFO
    | DispatchActions.SET_NULL
    | DispatchActions.LOGIN
    | DispatchActions.LOGOUT
    | DispatchActions.REGISTER
    | DispatchActions.CLEAR_REGISTRATION
    | DispatchActions.REQUEST_CHALLENGE
    | DispatchActions.RESET_CHALLENGE_DEFAULT
    | DispatchActions.CLEAR_REQUEST_CHALLENGE_ERROR_INPUT;
  p?: Partial<IState>;
}

interface IActionWithPrimitivePayload {
  t: DispatchActions.SET_POINTS;
  p: number;
}

const reducer = (state: IState, action: TAction): IState => {
  switch (action.t) {
    case DispatchActions.LOGIN:
      return { ...state, loading: true, error: null };
    case DispatchActions.SET_INFO:
      return {
        ...state,
        ...action.p,
        error: null,
        loading: false,
        isLoggedIn: true,
      };
    case DispatchActions.LOGOUT:
    case DispatchActions.SET_NULL:
      return {
        ...state,
        isLoggedIn: false,
        userInfo: null,
        error: null,
        loading: false,
      };
    case DispatchActions.SET_ERROR:
      // An error must be a string, if is rendered in JSX and is not a string react with throw an error "Objects are not valid as a React child"
      return {
        ...state,
        ...(typeof action.p?.error !== "string"
          ? { error: JSON.stringify(action.p?.error) }
          : { error: action.p?.error }),
        userInfo: null,
        loading: false,
        isLoggedIn: false,
      };
    case DispatchActions.REGISTER:
      return { ...state, ...action.p };
    case DispatchActions.CLEAR_ERROR:
      return { ...state, error: null };
    case DispatchActions.CLEAR_REQUEST_CHALLENGE_ERROR_INPUT:
      return { ...state, login: { ...state.login, error: null } };
    case DispatchActions.REQUEST_CHALLENGE:
      return {
        ...state,
        login: { ...state.login, ...(action.p?.login ?? {}) },
        loading: action.p?.loading ?? false,
      };
    case DispatchActions.RESET_CHALLENGE_DEFAULT:
      return {
        ...state,
        login: {
          status: "IDLE",
          error: null,
          cognitoUser: null,
          email: null,
          count: 0,
        },
      };
    case DispatchActions.CLEAR_REGISTRATION:
      return { ...state, registration: { status: "IDLE", userId: null } };
    case DispatchActions.SET_POINTS:
      if (state.userInfo)
        return { ...state, userInfo: { ...state.userInfo, points: action.p } };
      return state;
    default:
      throw Error(`Authcontext::Unkwown action  ${JSON.stringify(action)}`);
  }
};

interface IAuthProviderProps {
  children: ReactNode;
  router: Router;
}

export const AuthProvider = ({ children, router }: IAuthProviderProps) => {
  const [state, d] = useReducer(reducer, defaultState);
  const countState = useRef<number>(0);
  const { isLoggedIn } = state;

  const setProxyState = useAppStore((s) => s.setProxyState);
  // const { data, refetch } = trpc.useQuery(["user.getInfo"], {
  const { data, refetch } = trpc.user.getInfo.useQuery(undefined, {
    enabled: isLoggedIn,
    onSuccess(data) {
      if (data) {
        d({ t: DispatchActions.SET_POINTS, p: data.points });
        setProxyState((state) => {
          state.favorites = data.favorites;
          // * Leave the state not defined.
          // state.purchase.mode = data.purchase.mode;
          // state.purchase.warehouse = data.purchase.warehouse;
        });
      }
    },
  });
  const loginUser = async (email: string, password?: string) => {
    d({ t: DispatchActions.LOGIN });

    Auth.signIn(email, password, { method: "PASSWORD" })
      .then(async (res) => {
        const qrcode = await QRCode.toDataURL(`LXPX:${res?.attributes?.sub}`, {
          color: { light: "#fff", dark: "#66757f" },
          margin: 0,
        });
        refetch();
        d({
          t: DispatchActions.SET_INFO,
          p: {
            userInfo: {
              email: res.attributes?.email,
              userId: res.attributes?.sub,
              last_name: res.attributes?.family_name,
              first_name: res.attributes?.name,
              phone: res.attributes?.phone_number,
              name: `${res?.attributes?.name ?? ""} ${
                res?.attributes?.family_name ?? ""
              }`,
              qrcode,
              points: null,
            },
          },
        });
        document.cookie = `user_id=${res?.attributes?.sub};secure=true;samesite=strict;path=/`;
      })
      .catch((e) => {
        logger.log("loginUser ~ e", e);
        d({
          t: DispatchActions.SET_ERROR,
          p: { error: e?.message || e?.code },
        });
      });
  };

  const requestChallenge = (email: string) => {
    d({ t: DispatchActions.LOGIN });

    Auth.signIn(email, undefined, { method: "CHALLENGE" })
      .then((res) =>
        d({
          t: DispatchActions.REQUEST_CHALLENGE,
          p: {
            login: {
              status: "CHALLENGE",
              cognitoUser: res,
              error: null,
              email,
              count: 0,
            },
            loading: false,
          },
        })
      )
      .catch((err) => {
        if (err.message === "Incorrect username or password.") {
          {
            d({
              t: DispatchActions.REQUEST_CHALLENGE,
              p: {
                login: {
                  status: "CHALLENGE",
                  cognitoUser: null,
                  error: null,
                  email,
                  count: 0,
                },
                loading: false,
              },
            });
          }
        } else {
          d({
            t: DispatchActions.REQUEST_CHALLENGE,
            p: {
              login: {
                status: "CHALLENGE",
                cognitoUser: null,
                error: err?.message,
                email,
                count: 0,
              },
              loading: false,
            },
          });
        }
      });
  };

  const anwserChallenge = useCallback(
    (answer: string) => {
      countState.current++;
      d({ t: DispatchActions.LOGIN });
      if (!state?.login?.cognitoUser) {
        return d({
          t: DispatchActions.REQUEST_CHALLENGE,
          p: {
            login: {
              status: "CHALLENGE",
              cognitoUser: null,
              error: "Incorrect access code",
              email: state.login.email,
              count: countState.current,
            },
            loading: false,
          },
        });
      }
      Auth.sendCustomChallengeAnswer(state.login.cognitoUser, answer)
        .then(async (res) => {
          try {
            const resp = await Auth.currentSession();
            refreshAuth();
            d({
              t: DispatchActions.REQUEST_CHALLENGE,
              p: {
                login: {
                  status: "CHALLENGE_SUCCESS",
                  cognitoUser: null,
                  error: null,
                  email: state?.login?.cognitoUser?.attributes?.email ?? null,
                  count: 0,
                },
                loading: false,
              },
            });
          } catch (err) {
            d({
              t: DispatchActions.REQUEST_CHALLENGE,
              p: {
                login: {
                  status: "CHALLENGE",
                  error: "Incorrect access code",
                  count: countState.current,
                },
                loading: false,
              },
            });
          }
        })
        .catch((err) => {
          if (err.message === maxAttemptsErrorMessage) {
            return d({
              t: DispatchActions.REQUEST_CHALLENGE,
              p: {
                login: {
                  status: "CHALLENGE",
                  cognitoUser: null,
                  error: (
                    <>
                      Reached the maximum attempts.
                      <chakra.span
                        color="link"
                        cursor={"pointer"}
                        onClick={(e) =>
                          d({ t: DispatchActions.RESET_CHALLENGE_DEFAULT })
                        }
                      >
                        Request the access code again.
                      </chakra.span>
                    </>
                  ),
                  email: state.login.email,
                  count: countState.current,
                },
                loading: false,
              },
            });
          }

          d({
            t: DispatchActions.REQUEST_CHALLENGE,
            p: {
              login: {
                status: "_CHALLENGE_FAIL",
                cognitoUser: state.login.cognitoUser,
                error: err.message,
                email: state.login.cognitoUser?.attributes?.email ?? null,
                count: countState.current,
              },
              loading: false,
            },
          });
        });
    },
    [state.login]
  );

  const logout = async () => {
    await reactQueryClient.invalidateQueries(["user.getInfo"]);
    Auth.signOut()
      .then((res) => {
        d({ t: DispatchActions.LOGOUT });
        clearCognitoLocalStorage();
      })
      .catch((err) => {
        logger.log("logout ~ err", err);
        clearCognitoLocalStorage();
        d({ t: DispatchActions.LOGOUT });
      })
      .finally(() => {
        deleteCognitoCookies();
      });
  };

  const register = (
    params: SignUpParams,
    withPassword: "true" | "false" = "true",
    token?: string
  ) => {
    d({
      t: DispatchActions.REGISTER,
      p: {
        registration: { status: "PROCESSING", userId: null },
        registrationError: null,
      },
    });
    Auth.signUp({
      ...params,
      clientMetadata: {
        ...clientMetadata,
        withPassword,
        ...(!!token ? { token } : {}),
      },
    })
      .then((res) => {
        d({
          t: DispatchActions.REGISTER,
          p: { registration: { status: "SUCCESS", userId: res.userSub } },
        });
      })
      .catch((e) => {
        d({
          t: DispatchActions.REGISTER,
          p: {
            registration: { status: "FAIL", userId: null },
            registrationError: getCognitoErrorTransKey(e.message) as string,
          },
        });
      });
  };

  const clearRegistration = () => {
    d({ t: DispatchActions.CLEAR_REGISTRATION });
  };

  useEffect(() => {
    refreshAuth();
  }, []);

  const refreshAuth = async (bypassCache: boolean = false) => {
    d({ t: DispatchActions.LOGIN });
    Auth.currentSession()
      .then(async (res) => {
        const qrcode = await QRCode.toDataURL(
          `LXPX:${res.getIdToken().payload.sub}`,
          {
            color: { light: "#fff", dark: "#66757f" },
            margin: 0,
          }
        );
        d({
          t: DispatchActions.SET_INFO,
          p: {
            userInfo: {
              email: res.getIdToken().payload?.email,
              userId: res.getIdToken().payload?.sub,
              last_name: res.getIdToken()?.payload?.family_name,
              first_name: res.getIdToken()?.payload?.name,
              phone: res.getIdToken()?.payload?.phone_number,
              name: `${res.getIdToken().payload?.name} ${
                res.getIdToken().payload?.family_name
              }`,
              qrcode,
              points: null,
            },
          },
        });
        setAuthCookies();
      })
      .catch((e) => {
        logger.log("Auth.currentSessio ~ err", e);
        // On error we null the session in the context. This is to settle the loading state to false mainly. Login and sign up button will be visible. then
        // Could not revalidate credentials, user logout or session expired(30days). Error cannot be set in the context as is irrelevant to the user and
        // is only used for the login form.
        d({ t: DispatchActions.SET_NULL });
        // if (e === "The user is not authenticated") d({ t: DispatchActions.SET_NULL });
        // if (e === "No current user") d({ t: DispatchActions.SET_NULL });
        // This error is returned when the user is not authenticated using the Auth.currentSession() method.
        // Errors in this action should not propagate to the user. especially to the login form
        // if (e === "Refresh Token has expired") d({ t: DispatchActions.SET_NULL });
      });
  };

  const clearErrors = () => {
    d({ t: DispatchActions.CLEAR_ERROR });
  };

  const clearInputChallengeError = () => {
    d({ t: DispatchActions.CLEAR_REQUEST_CHALLENGE_ERROR_INPUT });
  };

  const resetChallengeToDefault = () => {
    d({ t: DispatchActions.RESET_CHALLENGE_DEFAULT });
  };

  let authInterval: NodeJS.Timer;

  useEffect(() => {
    if (!authInterval && isLoggedIn)
      authInterval = setInterval(() => {
        refreshAuth();
      }, 30 * 60_000);
    if (authInterval && !isLoggedIn) {
      clearInterval(authInterval);
    }
    return () => {
      if (authInterval) clearInterval(authInterval);
    };
  }, [isLoggedIn]);

  const memoizedVals = useMemo(
    () => ({
      state,
      loginUser,
      logout,
      register,
      refreshAuth,
      clearErrors,
      clearRegistration,
      requestChallenge,
      anwserChallenge,
      clearInputChallengeError,
      resetChallengeToDefault,
    }),
    [state, loginUser, logout, refreshAuth]
  );
  return (
    <AuthContext.Provider value={memoizedVals}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;

// ** Cognito only sets cookies client side on signIn method just for the session. Afterwards it refreshes token using localstorage. If the browser
// ** is closed cookies are lost. Not even a refresh brings them back. So we need to set them manually.
// ** This is important as we need this cookies for /api/trpc routes authentication

function setAuthCookies() {
  const cookieList = ["LastAuthUser", "refreshToken", "accessToken", "idToken"];
  let expiration = new Date();
  expiration.setFullYear(expiration.getFullYear() + 1);
  for (let key in window.localStorage) {
    if (window.localStorage.hasOwnProperty(key)) {
      let tokenNamearray = key.split(".");
      let tokenName = tokenNamearray.pop() ?? "";
      if (cookieList.indexOf(tokenName) !== -1) {
        document.cookie = `${key}=${window.localStorage[key]}; secure=true;samesite=strict;path=/`;
      }
      if (tokenName === "LastAuthUser") {
        document.cookie = `user_id=${window.localStorage[key]};secure=true;samesite=strict;path=/`;
      }
    }
  }
}

function clearCognitoLocalStorage() {
  for (let key in window.localStorage) {
    if (window.localStorage.hasOwnProperty(key)) {
      let tokenNamearray = key.split(".");
      let tokenName = tokenNamearray?.shift() ?? "";
      if (tokenName === "CognitoIdentityServiceProvider") {
        window.localStorage.removeItem(key);
      }
    }
  }
}
