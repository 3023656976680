import useFetch from "@/hooks/use-fetch";
import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";

export default function MaintenanceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { value } = useFetch<{ isMaintenance: boolean }>("/api/maintenance");
  const router = useRouter();
  useEffect(() => {
    if (
      value?.isMaintenance &&
      router.pathname !== "/" &&
      router.pathname !== "/_maintenance"
    ) {
      router.push("/");
    }
  }, [value, router]);

  return <>{children}</>;
}
